import { Button, Grid, MenuItem, TextField } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { addUserByAdmin } from "../../../../Service/ApiService";
import AuthenticationService from "../../../../Service/AuthenticationService";
import { crna_type } from "../../../Dropdown";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

interface UserInformationsFormData {
  email: string;
  password: string;
  checkPassword: string;
  name: string;
  firstName: string;
  phoneNumber: string;
  indicatif: string;
  center: string;
}

const AddUserForm = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Formik
        initialValues={
          {
            email: "",
            name: "",
            firstName: "",
            phoneNumber: "",
            center: "",
            password: "",
            checkPassword: "",
            indicatif: "+ ",
          } as UserInformationsFormData
        }
        onSubmit={(values: UserInformationsFormData, actions) => {
          addUserByAdmin(
            values.name,
            values.firstName,
            values.indicatif + values.phoneNumber,
            values.center,
            values.email,
            AuthenticationService.token,
            AuthenticationService.email
          )
            .then(() => {
              enqueueSnackbar(t("subAccount.successAdd"), {
                variant: "success",
              });
              props.userAdded();
            })
            .catch((error) => {
              console.error(error);
              enqueueSnackbar(t("error.errorHasOccured"), {
                variant: "error",
              });
            });
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email()
            .required(t("form.enterValidEmail") || ""),
          firstName: Yup.string().required(t("form.enterValidFirstName") || ""),
          name: Yup.string().required(t("form.enterValidName") || ""),
          phoneNumber: Yup.string(),
          company: Yup.string(),
          favoriteFlightType: Yup.string(),
        })}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          handleBlur,
          handleChange,
        }) => {
          return (
            <Form noValidate>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                <Grid
                  item
                  xs={8}
                  style={{
                    textAlign: "center",
                    marginBottom: "15px",
                    marginTop: "15px",
                  }}
                >
                  <TextField
                    required
                    label="Email"
                    fullWidth
                    value={values.email}
                    name="email"
                    helperText={
                      errors.email && touched.email ? errors.email : ""
                    }
                    error={errors.email && touched.email ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{
                    textAlign: "center",
                    marginBottom: "15px",
                    marginTop: "15px",
                  }}
                >
                  <TextField
                    required
                    label="Name"
                    value={values.name}
                    name="name"
                    fullWidth
                    helperText={errors.name && touched.name ? errors.name : ""}
                    error={errors.name && touched.name ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{
                    textAlign: "center",
                    marginBottom: "15px",
                    marginTop: "15px",
                  }}
                >
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    label="FirstName"
                    value={values.firstName}
                    name="firstName"
                    helperText={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : ""
                    }
                    error={errors.firstName && touched.firstName ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{ textAlign: "center", marginBottom: "15px" }}
                >
                  <Grid container>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="indicatif"
                        label="Indicatif"
                        name="indicatif"
                        style={{ color: "white" }}
                        value={values.indicatif}
                        helperText={
                          errors.indicatif && touched.indicatif
                            ? errors.indicatif
                            : ""
                        }
                        error={
                          errors.indicatif && touched.indicatif ? true : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid xs={7}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="phoneNumber"
                        label="Phone number"
                        name="phoneNumber"
                        style={{ color: "white" }}
                        value={values.phoneNumber}
                        helperText={
                          errors.phoneNumber && touched.phoneNumber
                            ? errors.phoneNumber
                            : ""
                        }
                        error={
                          errors.phoneNumber && touched.phoneNumber
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{ textAlign: "center", marginBottom: "15px" }}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    select
                    id="center"
                    label="Center"
                    name="center"
                    style={{ color: "white" }}
                    value={values.center}
                    helperText={
                      errors.center && touched.center ? errors.center : ""
                    }
                    error={errors.center && touched.center ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem key={""} value={""}></MenuItem>
                    {crna_type.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={11}></Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{ marginTop: "15px" }}
                >
                  {t("form.addUser")}
                </Button>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddUserForm;
