import { Button, Grid, Typography } from "@mui/material";
import SkydivingSummary from "./SummaryOfNewRequest/SkydivingSummary";
import CalibrationSummary from "./SummaryOfNewRequest/CalibrationSummary";
import MediaCoverageSummary from "./SummaryOfNewRequest/MediaCoverageSummary";
import PhotoMissionSummary from "./SummaryOfNewRequest/PhotoMissionSummary";
import ScientificFlightSummary from "./SummaryOfNewRequest/ScientificFlightSummary";
import TestFlightSummary from "./SummaryOfNewRequest/TestFlightSummary";
import VariousSummary from "./SummaryOfNewRequest/VariousSummary";
import { useTranslation } from "react-i18next";

const SummaryOfNewRequest = (props: any) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item xs={12} style={{ marginBottom: "40px", textAlign: "center" }}>
        <Typography variant="h5">{t("request.summary.title")}</Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={8}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: "12px" }}
            >
              {t("request.summary.applicantName") +
                " : " +
                props.globalInformations.applicantName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: "12px" }}
            >
              {t("request.summary.referenceNumber") +
                " : " +
                props.globalInformations.referenceNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: "12px" }}
            >
              {t("request.summary.aircraftType") +
                " : " +
                props.globalInformations.aircraftType}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: "12px" }}
            >
              {t("request.summary.callSign") +
                " : " +
                props.globalInformations.callsign}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: "12px" }}
            >
              {t("request.summary.requiredLevel") +
                " : " +
                props.globalInformations.requiredLevel}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: "12px" }}
            >
              {t("request.summary.priorityLevel") +
                " : " +
                props.globalInformations.priorityLevel}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: "12px" }}
            >
              {t("request.summary.flightRules") +
                " : " +
                props.globalInformations.flightRules}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: "12px" }}
            >
              {t("request.summary.centers") + " : "}
              <ul>
                {props.globalInformations.center.map((center: string) => {
                  return <li>{center}</li>;
                })}
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          style={{ textAlign: "center", margin: "20px" }}
        >
          <u>{props.globalInformations.flightType}</u>
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={8}>
          <Grid
            item
            xs={12}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            {props.globalInformations.flightType === "Calibration" && (
              <CalibrationSummary
                origin={props.origin}
                specificInformations={props.specificInformations}
              />
            )}
            {props.globalInformations.flightType === "Media coverage" && (
              <MediaCoverageSummary
                origin={props.origin}
                specificInformations={props.specificInformations}
              />
            )}
            {props.globalInformations.flightType === "Photo mission" && (
              <PhotoMissionSummary
                origin={props.origin}
                specificInformations={props.specificInformations}
              />
            )}
            {props.globalInformations.flightType === "Test flight" && (
              <TestFlightSummary
                origin={props.origin}
                specificInformations={props.specificInformations}
              />
            )}
            {props.globalInformations.flightType === "Scientific flight" && (
              <ScientificFlightSummary
                origin={props.origin}
                specificInformations={props.specificInformations}
              />
            )}
            {props.globalInformations.flightType === "Skydiving" && (
              <SkydivingSummary
                specificInformations={props.specificInformations}
                origin={props.origin}
              />
            )}
            {props.globalInformations.flightType === "Various" && (
              <VariousSummary
                specificInformations={props.specificInformations}
                origin={props.origin}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5} style={{ textAlign: "center" }}>
        <Button
          variant="outlined"
          onClick={() => {
            props.handleBack();
          }}
        >
          {t("global.back")}
        </Button>
      </Grid>
      <Grid item xs={5} style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          onClick={() => {
            props.validSummaryOfRequest();
          }}
        >
          {t("global.confirm")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SummaryOfNewRequest;
