import React, { useEffect } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import parse from "html-react-parser";

const ProtecExportMediaCoverage = (props: any) => {
  useEffect(() => {
    console.log(
      props.requestData.protec.informations.instructionOnSector.length
    );
  });
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      style={{
        height: "100vh",
      }}
    >
      <Grid
        item
        xs={7}
        style={{
          backgroundColor: "red",
          height: "8vh",
          border: "solid black 1px",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography align="center" color="white">
          Référence mission
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          style={{
            margin: "5px",
            height: "8vh",
          }}
        >
          <Grid item xs={7}>
            <Divider color="black" />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            Date
          </Grid>
          <Grid item xs={7}>
            <Divider color="black" />
          </Grid>
          <Grid
            item
            xs={7}
            style={{ backgroundColor: "#CACACA", textAlign: "center" }}
          >
            CDS/FMP/Secteurs
          </Grid>
          <Grid item xs={7}>
            <Divider color="black" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ height: "80vh" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid
            item
            xs={11}
            style={{
              border: "solid black 1px",
              overflow: "hidden",
              color: "red",
              height: "5vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>
              Niveau : {props.requestData.specificInformations.requiredLevel}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            style={{
              height: "20vh",
              textAlign: "left",
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{
                marginRight: "5px",
                marginTop: "5px",
                marginBottom: "5px",
                height: "18vh",
                border: "solid black 1px",
                overflow: "hidden",
              }}
            >
              <Grid item xs={12} style={{ margin: "15px" }}>
                <Typography>
                  <u>Description :</u> {props.description}
                </Typography>
                <Typography>
                  <u>Société :</u>{" "}
                  {props.requestData.globalInformations.applicantName}
                </Typography>
                <Typography>
                  <u>Règles de vol :</u>{" "}
                  {props.requestData.globalInformations.flightRules}
                </Typography>
                <Typography>
                  <u>Callsign : </u>
                  {props.requestData.globalInformations.callsign}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              color: "red",
              minHeight: "10vh",
              maxHeight: "26vh",
              textAlign: "left",
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{
                marginLeft: "5px",
                marginTop: "5px",
                minHeight: "5vh",
                maxHeight: "24vh",
                marginBottom: "5px",
                border: "solid black 1px",
                overflow: "hidden",
              }}
            >
              <Grid xs={12} style={{ margin: "15px" }}>
                <Typography>
                  <u>Consignes CDS :</u>
                </Typography>
                <Typography color="black">
                  {parse(props.requestData.protec.informations.cdsInstruction)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={5}
            style={{
              height: "20vh",
              textAlign: "left",
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{
                marginRight: "5px",
                marginTop: "5px",
                marginBottom: "5px",
                height: "18vh",
                border: "solid black 1px",
                overflow: "hidden",
              }}
            >
              <Grid xs={12} style={{ margin: "15px" }}>
                <Typography>
                  <u>ADEP :</u>{" "}
                  {props.requestData.specificInformations.departureAerodrome}
                </Typography>
                <Typography>
                  <u>ADES :</u>{" "}
                  {props.requestData.specificInformations.arrivalAerodrome}
                </Typography>
                <Typography>
                  <u>Route déposée pour LFEE : </u>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              minHeight: "5vh",
              maxHeight: "27vh",
              textAlign: "left",
            }}
          >
            <Grid
              container
              style={{
                marginLeft: "5px",
                marginTop: "5px",
                marginBottom: "5px",
                minHeight: "5vh",
                maxHeight: "25vh",
                border: "solid black 1px",
              }}
            >
              <Grid xs={12} style={{ margin: "15px" }}>
                <Typography color="red">
                  <u>Consignes sur secteur :</u>
                </Typography>
                <Typography
                  color="black"
                  style={{
                    fontSize:
                      props.requestData.protec.informations.instructionOnSector
                        .length > 500
                        ? "0.60em"
                        : "0.80em",
                    overflow: "hidden",
                    minHeight: "5vh",
                    maxHeight: "22vh",
                  }}
                >
                  {parse(
                    props.requestData.protec.informations.instructionOnSector
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={5}
            style={{
              minHeight: "5vh",
              maxHeight: "26vh",
              textAlign: "left",
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              style={{
                marginTop: "5px",
                marginRight: "5px",
                minHeight: "5vh",
                maxHeight: "24vh",
                border: "solid black 1px",
                overflow: "hidden",
                backgroundColor: "#FF5F5D",
              }}
            >
              <Grid item xs={12} style={{ margin: "15px" }}>
                <Typography>
                  Numéro RPO : 06 85 83 15 21 <br /> Numéro Sub CTL en cas de
                  problème (horaire de bureau) :
                  <br />
                </Typography>
                <Typography color="black">
                  {parse(props.requestData.protec.informations.callingNumber)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              minHeight: "5vh",
              maxHeight: "26vh",
              textAlign: "left",
            }}
          >
            <Grid
              container
              style={{
                marginLeft: "5px",
                marginTop: "5px",
                minHeight: "5vh",
                maxHeight: "24vh",
                border: "solid black 1px",
                overflow: "hidden",
              }}
            >
              <Grid item xs={12} style={{ margin: "15px" }}>
                <Typography>
                  <u>Organismes consultés :</u>
                </Typography>
                <Typography color="black">
                  {parse(
                    props.requestData.protec.informations.organizationConsulted
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          margin: "5px",
          textAlign: "center",
        }}
      >
        <Typography>Carte au verso !!</Typography>
        <UndoIcon style={{ rotate: "-90deg" }} />
      </Grid>
    </Grid>
  );
};

export default ProtecExportMediaCoverage;
