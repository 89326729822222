import { Grid, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

const SkydivingSummary = (props: any) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item xs={12}>
        <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
          {t("request.summary.departureAerodrome") +
            " : " +
            props.specificInformations.departureAerodrome}
        </Typography>
      </Grid>
      {props.origin.indexOf("Project") === -1 && (
        <Grid item xs={12}>
          <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
            {t("request.summary.requestedDay") + " : "}
          </Typography>
          <ul>
            {props.specificInformations.requestedDays.map(
              (requestedDay: DateTime, index: number) => {
                return (
                  <li>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                      >
                        <Grid item xs={12}>
                          <Typography
                            style={{ textAlign: "left", marginBottom: "12px" }}
                          >
                            {requestedDay.toFormat("dd-MM-yyyy")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            style={{ textAlign: "left", marginBottom: "12px" }}
                          >
                            {t("request.summary.numberOfDrops") +
                              " : " +
                              props.specificInformations.numberOfDrops[index]}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            style={{ textAlign: "left", marginBottom: "12px" }}
                          >
                            {t("request.summary.time") +
                              " : " +
                              props.specificInformations.schedule[
                                index
                              ][0].toFormat("HH:mm") +
                              "UTC - " +
                              props.specificInformations.schedule[
                                index
                              ][1].toFormat("HH:mm") +
                              "UTC"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </li>
                );
              }
            )}
          </ul>
        </Grid>
      )}
      {props.specificInformations.comments && (
        <Grid item xs={12}>
          <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
            {t("request.summary.comments") +
              " : " +
              props.specificInformations.comments}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default SkydivingSummary;
