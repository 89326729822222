import { TextField, Grid, IconButton } from "@mui/material";
import { Formik, Form } from "formik";
import SendIcon from "@mui/icons-material/Send";
import * as Yup from "yup";
import { newMessage } from "../../../Service/ApiService";
import { DateTime } from "luxon";
import AuthenticationService from "../../../Service/AuthenticationService";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

interface TchatFormData {
  content: string;
}

const TchatForm = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={
        {
          content: "",
        } as TchatFormData
      }
      onSubmit={(values: TchatFormData, actions) => {
        const userEmail = AuthenticationService.email;
        const token = AuthenticationService.token;
        const role = AuthenticationService.role;
        const company = AuthenticationService.company;
        const sendingTime = DateTime.now().toFormat("dd-MM-yyyy HH:mm");
        newMessage(
          props.requestId,
          values.content,
          sendingTime,
          token,
          userEmail,
          role === "Administrator" ? company : userEmail,
          role === "Administrator" ? "user" : props.center,
          props.project,
          AuthenticationService.lastName +
            " " +
            AuthenticationService.firstName,
          AuthenticationService.role
        )
          .then((data) => {
            if (data === "Success") {
              props.updateTchat(props.requestId);
              actions.resetForm();
            }
          })
          .catch((error) => {
            enqueueSnackbar(t("error.errorHasOccured"), {
              variant: "error",
            });
            console.error(error);
          });
      }}
      validationSchema={Yup.object().shape({
        content: Yup.string(),
      })}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => {
        return (
          <Form noValidate>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={10}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  multiline
                  rows={3}
                  id="content"
                  label="Content"
                  name="content"
                  style={{ color: "white" }}
                  value={values.content}
                  helperText={
                    errors.content && touched.content ? errors.content : ""
                  }
                  error={errors.content && touched.content ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={1}>
                <IconButton color="primary" type="submit">
                  <SendIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TchatForm;
