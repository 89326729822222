import { getSectorFile, getSectorLevelFile } from "../../Service/ApiService";
import AuthenticationService from "../../Service/AuthenticationService";
import * as turf from "@turf/turf";
import { isBetweenPlancherAndPlafond } from "./map";

export default async function determineCenter(tt: any, requiredLevels: number[]) {
  const multiCall = [];
  const newCenters: any = [];

  multiCall.push(
    getSectorLevelFile(AuthenticationService.email, AuthenticationService.token)
  );
  multiCall.push(
    getSectorFile(AuthenticationService.email, AuthenticationService.token)
  );
  const centers: any = [];

  return Promise.all(multiCall)
    .then((data) => {
      const sectorsLevel = data[0];
      const sectorsArea = data[1];

      sectorsArea.sectors.elementary.forEach((sector: any) => {
        const feature1 = turf.feature(sector.backgroundArea);
        const feature2: any = turf.featureCollection(tt.features);
        const intersection = turf.lineIntersect(feature2, feature1);

        if (intersection.features.length !== 0) {
          const res = sectorsLevel.filter(
            (sectorLevel: any) => sectorLevel.sector === sector.name &&  isBetweenPlancherAndPlafond(requiredLevels,parseInt(sectorLevel.plancher), parseInt(sectorLevel.plafond))
          );        
            if (res.length !== 0 && centers.indexOf(sector.center) === -1) {              
              console.log("laaaaa")
              centers.push(sector.center);
            }               
        }
      });

      centers.forEach((center: string) => {
        switch (center) {
          case "LFEE":
            newCenters.push("CRNA-E");
            break;
          case "LFFF":
            newCenters.push("CRNA-N");
            break;
          case "LFRR":
            newCenters.push("CRNA-O");
            break;
          case "LFBB":
            newCenters.push("CRNA-SO");
            break;
          case "LFMM":
            newCenters.push("CRNA-SE");
            break;
        }
      });

      return newCenters;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
}
