import { Button, Grid, Box, Step, Stepper, StepLabel } from "@mui/material";
import React, { useState } from "react";
import SelectKMLFile from "./NewRequest/SelectKMLFIle";
import MapIcon from "@mui/icons-material/Map";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import GlobalInformations from "./NewRequest/GlobalInformations";
import SpecificInformations from "./NewRequest/SpecificInformations";
import ConfirmNewRequest from "./NewRequest/ConfirmNewRequest";
import GradingIcon from "@mui/icons-material/Grading";
import ParaglidingIcon from "@mui/icons-material/Paragliding";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import SummaryOfNewRequest from "./NewRequest/SummaryOfNewRequest";
import { styled } from "@mui/material/styles";
import { StepIconProps } from "@mui/material/StepIcon";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { addNewProject, addNewRequest } from "../../../Service/ApiService";
import { DateTime } from "luxon";
import AuthenticationService from "../../../Service/AuthenticationService";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "radial-gradient( circle farthest-corner at 10% 20%,  rgba(37,145,251,0.98) 0.1%, rgba(0,7,128,1) 99.8% )",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage: "linear-gradient(to top, #a3bded 0%, #6991c7 100%);",
  }),
}));

const steps = ["File", "Global ", "Specific ", "Confirm", "Finish"];

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <MapIcon />,
    2: <WbIncandescentIcon />,
    3: <ParaglidingIcon />,
    4: <GradingIcon />,
    5: <SportsScoreIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const NewRequestForm = (props: any) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const [globalInformations, setGlobalInformations] = React.useState({
    applicantName: AuthenticationService.company,
    aircraftType: "",
    flightRules: "",
    referenceNumber: "",
    callsign: "",
    flightType: "",
    requiredLevel: "",
    priorityLevel: 3,
    center: [],
    usersEmail: [AuthenticationService.email],
  } as any);

  const [specificInformations, setSpecificInformations] = React.useState(
    {} as any
  );

  const [kmlFileInformations, setKMLFileInformations] = React.useState({
    kmlFile: "",
    fileName: "",
    fileProcessed: {} as any,
  });

  const { enqueueSnackbar } = useSnackbar();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const globalInformationsValidation = (
    applicantName: string,
    aircraftType: string,
    flightRules: string,
    referenceNumber: string,
    callsign: string,
    flightType: string,
    center: string[],
    requiredLevel: string,
    priorityLevel: number,
    usersEmail: string[]
  ) => {
    if (flightType !== globalInformations.flightType) {
      setSpecificInformations({});
    }
    setGlobalInformations({
      applicantName: applicantName,
      aircraftType: aircraftType,
      flightRules: flightRules,
      referenceNumber: referenceNumber,
      callsign: callsign,
      flightType: flightType,
      priorityLevel: priorityLevel,
      center: center,
      requiredLevel: requiredLevel,
      usersEmail: usersEmail,
    });
    handleComplete();
  };

  const specificInformationsValidation = (newInformations: any) => {
    setSpecificInformations(newInformations);
    handleComplete();
  };

  const validSummaryOfRequest = async () => {
    if (props.origin === "Project") {
      await addNewProject(
        globalInformations,
        specificInformations,
        kmlFileInformations.kmlFile,
        DateTime.now().toFormat("dd-MM-yyyy")
      )
        .then(() => {
          enqueueSnackbar(t("project.submitProject"), {
            variant: "success",
          });
        })
        .catch((error) => {
          enqueueSnackbar(t("error.errorHasOccured"), {
            variant: "error",
          });
          console.error(error);
        });
    } else {
      await addNewRequest(
        globalInformations,
        specificInformations,
        kmlFileInformations.kmlFile,
        DateTime.now().toFormat("dd-MM-yyyy")
      )
        .then(() => {
          enqueueSnackbar(t("request.submitRequest"), {
            variant: "success",
          });
        })
        .catch((error) => {
          enqueueSnackbar(t("error.errorHasOccured"), {
            variant: "error",
          });
          console.error(error);
        });
    }

    handleComplete();
  };

  const selectKMLFileChange = (
    kmlFile: any,
    kmlFileProcessed: any,
    fileName: string
  ) => {
    setLoading(true);
    setKMLFileInformations({
      kmlFile: kmlFile,
      fileName: fileName,
      fileProcessed: kmlFileProcessed,
    });
    props.changeFile(kmlFileProcessed);

    setLoading(false);
  };
  const selectKMLFileValidation = () => {
    handleComplete();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    if (newActiveStep !== 0) {
      props.updateStateOnFile(false);
    }
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    if (activeStep - 1 === 0) {
      props.updateStateOnFile(true);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const createRoads = (waypoints: string[]) => {
    props.createNewRoad(waypoints);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  return (
    <Box sx={{ width: "100%", marginBottom: "20px" }}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel StepIconComponent={ColorlibStepIcon} color="black">
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{ overflow: "auto", maxHeight: "78vh" }}>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>{t("global.reset")}</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid sx={{ mt: 2, mb: 1, py: 1 }}></Grid>
            {activeStep === 0 && (
              <SelectKMLFile
                activeStep={activeStep}
                handleBack={handleBack}
                loading={loading}
                selectKMLFileValidation={selectKMLFileValidation}
                steps={steps}
                completed={completed}
                handleComplete={handleComplete}
                completedSteps={completedSteps}
                totalSteps={totalSteps}
                kmlFileInformations={kmlFileInformations}
                selectKMLFileChange={selectKMLFileChange}
              />
            )}

            {activeStep === 1 && (
              <GlobalInformations
                activeStep={activeStep}
                handleBack={handleBack}
                globalInformationsValidation={globalInformationsValidation}
                steps={steps}
                completed={completed}
                handleComplete={handleComplete}
                completedSteps={completedSteps}
                totalSteps={totalSteps}
                globalInformations={globalInformations}
                kmlFileProcessed={kmlFileInformations.fileProcessed}
              />
            )}
            {activeStep === 2 && (
              <SpecificInformations
                origin={props.origin}
                activeStep={activeStep}
                handleBack={handleBack}
                specificInformationsValidation={specificInformationsValidation}
                steps={steps}
                completed={completed}
                handleComplete={handleComplete}
                completedSteps={completedSteps}
                totalSteps={totalSteps}
                specificInformations={specificInformations}
                typeOfSpecificInformations={globalInformations.flightType}
                createRoads={createRoads}
              />
            )}
            {activeStep === 3 && (
              <SummaryOfNewRequest
                origin={props.origin}
                activeStep={activeStep}
                handleBack={handleBack}
                steps={steps}
                completed={completed}
                handleComplete={handleComplete}
                validSummaryOfRequest={validSummaryOfRequest}
                completedSteps={completedSteps}
                totalSteps={totalSteps}
                specificInformations={specificInformations}
                globalInformations={globalInformations}
              />
            )}
            {activeStep === 4 && (
              <ConfirmNewRequest finish={props.finish} origin={props.origin} />
            )}
          </React.Fragment>
        )}
      </div>
    </Box>
  );
};

export default NewRequestForm;
