import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { VictoryPie } from "victory";

const DistributionByApplicantStatistics = (props: any) => {
  const [data, setData] = useState([]);
  return (
    <Paper style={{ height: "45vh" }} elevation={4}>
      <Grid
        container
        alignItems="center"
        alignContent="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom style={{ margin: "15px" }}>
            {t("statistics.distributionOfApplicant")}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <FormControl>
                <FormLabel>{t("statistics.applicant")}</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={(e: any) => {                   
                    setData(
                      props.data.find(
                        (applicant: any) =>
                          applicant.applicant === e.target.value
                      ).graph
                    );
                  }}
                >
                  {props.data.map((appli: any) => {
                    return (
                      <FormControlLabel
                        value={appli.applicant}
                        control={<Radio />}
                        label={appli.applicant}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <VictoryPie
            data={data}
            labels={({ datum }) => datum.y}
            padAngle={({ datum }) => datum.y}
            innerRadius={80}
            height={350}
            width={500}
          />
        </Grid>
        {/* <Grid item xs={10}>
          <VictoryLegend
            title="Legend"
            centerTitle
            orientation="horizontal"
            style={{ border: { stroke: "black" }, title: { fontSize: 20 } }}
            data={[{ name: "One" }, { name: "Two" }, { name: "Three" }]}
          />
        </Grid> */}
      </Grid>
    </Paper>
  );
};

export default DistributionByApplicantStatistics;
