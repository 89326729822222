import {
  TextField,
  Button,
  Grid,
  MenuItem,
  Stack,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { Formik, Form } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";

interface AdminValidationForm {
  timeRestrictionStart: string;
  timeRestrictionEnd: string;
  notSimultaneousWith: string;
  comments: string;
}

const ApprovedIfForm = (props: any) => {
  const { t } = useTranslation();

  const [restrictionTime, setRestrictionTime] = useState(false);
  const [notSimultaneous, setNotSimultaneous] = useState(false);

  return (
    <Formik
      initialValues={
        {
          timeRestrictionStart: "",
          timeRestrictionEnd: "",
          notSimultaneousWith: "",
          comments: "",
        } as AdminValidationForm
      }
      onSubmit={(values: AdminValidationForm, actions) => {
        const reason = {
          timeRestrictionStart: restrictionTime
            ? values.timeRestrictionStart
            : "",
          timeRestrictionEnd: restrictionTime ? values.timeRestrictionEnd : "",
          notSimultaneousWith: notSimultaneous
            ? values.notSimultaneousWith
            : "",
          comments: values.comments,
        };
        props.processRequest("acceptedIf", reason);
      }}
      validationSchema={Yup.object().shape({
        timeRestrictionStart: Yup.string(),
        timeRestrictionEnd: Yup.string(),
        notSimultaneousWith: Yup.string(),
        comments: Yup.string(),
      })}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => {
        return (
          <Form noValidate>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <FormControlLabel
                  value="Restriction time"
                  control={<Checkbox size="small" />}
                  onChange={() => setRestrictionTime(!restrictionTime)}
                  label="Restriction time"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value="Not simultaneous"
                  control={<Checkbox size="small" />}
                  onChange={() => setNotSimultaneous(!notSimultaneous)}
                  label="Not simultaneous"
                  labelPlacement="start"
                />
              </Grid>
              {restrictionTime && (
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    alignContent="center"
                    justifyContent="center"
                  >
                    <Grid item xs={6}>
                      <Stack
                        spacing={3}
                        style={{
                          marginRight: "12px",
                          marginBottom: "12px",
                          marginTop: "12px",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                          <TimePicker
                            label="Start restriction time"
                            value={values.timeRestrictionStart}
                            ampm={false}
                            onChange={(value) => {
                              setFieldValue("timeRestrictionStart", value);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack
                        spacing={3}
                        style={{
                          marginLeft: "12px",
                          marginBottom: "12px",
                          marginTop: "12px",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                          <TimePicker
                            label="End restriction time"
                            value={values.timeRestrictionEnd}
                            ampm={false}
                            onChange={(value) => {
                              setFieldValue("timeRestrictionEnd", value);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {notSimultaneous && (
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="notSimultaneousWith"
                    label="Not simultaneous with"
                    name="notSimultaneousWith"
                    select
                    style={{ color: "white", textAlign: "left" }}
                    value={values.notSimultaneousWith}
                    helperText={
                      errors.notSimultaneousWith && touched.notSimultaneousWith
                        ? errors.notSimultaneousWith
                        : ""
                    }
                    error={
                      errors.notSimultaneousWith && touched.notSimultaneousWith
                        ? true
                        : false
                    }
                    onChange={(event) => {
                      setFieldValue("notSimultaneousWith", event.target.value);
                    }}
                    onBlur={handleBlur}
                  >
                    {props.requests.map((option: any) => (
                      <MenuItem key={option.referenceNumber} value={option.id}>
                        {option.referenceNumber}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  multiline
                  rows={3}
                  id="comments"
                  label="Comments"
                  name="comments"
                  style={{ color: "white" }}
                  value={values.comments}
                  helperText={
                    errors.comments && touched.comments ? errors.comments : ""
                  }
                  error={errors.comments && touched.comments ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" type="submit" sx={{ mr: 1 }}>
                {t("global.confirm")}
              </Button>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ApprovedIfForm;
