import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const ConfirmNewRequest = (props: any) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid
        item
        xs={12}
        style={{ marginBottom: "12px", height: "5vh", textAlign: "center" }}
      >
        <Typography variant="h6">
          {props.origin.indexOf("new") !== -1 && t("request.sent")}
          {props.origin.indexOf("new") === -1 && t("request.successfullUpdate")}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          style={{ textAlign: "left", marginBottom: "12px", height: "10vh" }}
        >
          {props.origin.indexOf("new") !== -1 &&
            parse(t("request.sentContent"))}
          {props.origin.indexOf("new") === -1 &&
            parse(t("request.successfullUpdateContent"))}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          onClick={() => {
            props.finish();
          }}
        >
          {t("request.finish")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ConfirmNewRequest;
