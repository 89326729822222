import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Button,
    Typography,
    IconButton,
  } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";
  import { useTranslation } from "react-i18next";
  import CookieIcon from '@mui/icons-material/Cookie';

  const Cookie = (props: any) => {
    const { t } = useTranslation();
  
    return (
      <Dialog
        open={props.open}
        fullWidth
        maxWidth="sm"
        onClose={props.closeModal}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle textAlign="center">
          <Grid
            container
            alignContent="center"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h5" style={{ marginRight: "15px" }}>
              {t("cookie.title")}
            </Typography>
            <CookieIcon/>
            
            <Grid item style={{ position: "absolute", right: "12px" }}>
              <IconButton
                onClick={() => {
                  props.closeModal();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            {t("cookie.description")}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button variant="contained" onClick={() => {
                 localStorage.setItem("Cookie", "Yes")
                props.closeModal()
                }}>
                {t("cookie.accept")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };
  export default Cookie;
  