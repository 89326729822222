import { Dialog, DialogTitle, DialogContent, Grid } from "@mui/material";
import TabPannelSwitcheur from "../TabPannelSwitcheur";

const SummaryRequest = (props: any) => {
  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="xl"
      onClose={props.closeModal}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle textAlign="center">
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        ></Grid>
      </DialogTitle>
      <DialogContent style={{ height: "85vh" }}>
        {props.requestData.id && (
          <TabPannelSwitcheur
            requestData={props.requestData}
            origin={props.origin}
            updateProtecInformations={props.updateProtecInformations}
            closeModal={props.closeModal}
            missionType={props.requestData.globalInformations.flightType}
            selectAction={props.selectAction}
            updateOrigin={props.updateOrigin}
            openProtec={props.openProtec}
            state={props.requestData.state}
            reSubmitRequest={props.reSubmitRequest}
            updateRequest={props.updateRequest}
            validDelete={props.validDelete}
            sumbitNow={props.sumbitNow}
            selectSubmitWithModification={props.selectSubmitWithModification}
            protecInformations={props.requestData.protec}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
export default SummaryRequest;
