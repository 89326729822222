import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ScientificFlightSummary = (props: any) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item xs={12}>
        <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
          {t("request.summary.departureAerodrome") +
            " : " +
            props.specificInformations.departureAerodrome}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
          {t("request.summary.arrivalAerodrome") +
            " : " +
            props.specificInformations.arrivalAerodrome}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
          {t("request.summary.filedRoad") +
            " : " +
            props.specificInformations.filedRoad}
        </Typography>
      </Grid>
      {props.origin.indexOf("Project") === -1 && (
        <>
          <Grid item xs={12}>
            <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
              {t("request.summary.filedRoad") +
                " : " +
                props.specificInformations.requestedDay.toFormat("dd-MM-yyyy")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
              {t("request.summary.time") +
                " : " +
                props.specificInformations.startTime.toFormat("HHmm")}
              UTC - {props.specificInformations.endTime.toFormat("HHmm")}UTC
            </Typography>
          </Grid>
        </>
      )}
      {props.specificInformations.comments && (
        <Grid item xs={12}>
          <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
            {t("request.summary.comments") +
              " : " +
              props.specificInformations.comments}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ScientificFlightSummary;
