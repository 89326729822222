import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const SelectTchatCenter = (props: any) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5" style={{ marginRight: "30px" }}>
            {t("request.seleteChatCenter")}
          </Typography>
          <Grid item style={{ position: "absolute", right: "12px" }}>
            <IconButton
              onClick={() => {
                props.closeModal();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            {props.centers.map((center: string) => {
              return (
                <Grid item xs={10} key={center}>
                  <Button
                    style={{ marginTop: "10px" }}
                    variant="contained"
                    onClick={() => {
                      props.openTchat(center);
                    }}
                  >
                    {center}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
export default SelectTchatCenter;
