import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import UserInformations from "../Account/UserInformations";
import { useTranslation } from "react-i18next";
import SubAccount from "../../User/Module/SubAccount/SubAccount";
import AuthenticationService from "../../../Service/AuthenticationService";

const AccountManagement = (props: any) => {
  const { t } = useTranslation();

  const [update, setUpdate] = useState(false);

  const closeModal = () => {
    setUpdate(false);
    props.closeModal();
  };

  const finishUpdate = () => {
    setUpdate(false);
  };

  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="lg"
      onClose={closeModal}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle textAlign="center">
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5" style={{ marginRight: "15px" }}>
            {t("account.accountManagement")}
          </Typography>
          <Grid item style={{ position: "absolute", right: "12px" }}>
            <IconButton
              onClick={() => {
                closeModal();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <UserInformations
          userInformations={props.userInformations}
          update={update}
          finishUpdate={finishUpdate}
        />
        {!update && (
          <Grid container alignItems="center" alignContent="center">
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button variant="contained" onClick={() => setUpdate(true)}>
                {t("global.update")}
              </Button>
            </Grid>
          </Grid>
        )}
        {AuthenticationService.isMainAccount && (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <SubAccount userInformations={props.userInformations} />
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default AccountManagement;
