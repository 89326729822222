import * as React from "react";
import {
  useTheme,
  Theme,
  CSSObject,
  styled,
  alpha,
} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DnsIcon from "@mui/icons-material/Dns";
import Person4Icon from "@mui/icons-material/Person4";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import AuthenticationService from "../../../Service/AuthenticationService";
import {
  Badge,
  Button,
  Grid,
  MenuItem,
  Select,
  Typography,
  Zoom,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../Router/Routes";
import { getUserInformations } from "../../../Service/ApiService";
import AccountManagement from "../../Common/Modal/AccoutManagement";
import Settings from "../../Common/Modal/Settings";
import SourceIcon from "@mui/icons-material/Source";
import { useTranslation } from "react-i18next";
import { language_type } from "../../Dropdown";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import { useEffect } from "react";
import Menu, { MenuProps } from "@mui/material/Menu";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
const REACT_APP_COLOR = process.env.REACT_APP_COLOR || "#73AABF";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  paper: {
    background: "blue",
  },
  itemText: {
    color: "white",
  },
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  backgroundColor: "blue",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const NavBar = (props: any) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    setNotification(AuthenticationService.notification);
  }, [props.update]);

  const [open, setOpen] = React.useState(false);
  const [accountManagement, setAccountManagement] = React.useState(false);
  const [userInformations, setUserInformations] = React.useState({} as any);
  const [settings, setSettings] = React.useState(false);
  const [current, setCurrent] = React.useState(0);
  const [notification, setNotification] = React.useState(
    AuthenticationService.notification
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openClick = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeSettings = () => {
    setSettings(false);
  };

  const closeAccountManagement = () => {
    setAccountManagement(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: "#2D3E4E",
            color: "white",
          },
        }}
      >
        <DrawerHeader>
          {!open ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <IconButton
                color="inherit"
                onClick={handleDrawerOpen}
                style={{ textAlign: "center" }}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          ) : (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Grid item xs={10}>
                <Zoom
                  in={open}
                  style={{ transitionDelay: open ? "100ms" : "0ms" }}
                >
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                  >
                    <Grid item xs={8}>
                      <Typography
                        variant="h6"
                        style={{
                          textAlign: "center",
                          fontFamily: "'Samurai', sans-serif",
                        }}
                      >
                        SAMURAI
                      </Typography>
                    </Grid>
                  </Grid>
                </Zoom>
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction !== "rtl" && (
                    <ChevronLeftIcon className={classes.itemText} />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          )}
        </DrawerHeader>
        <Divider color="white" />
        <List>
          {[
            t("navBar.inProgress"),
            t("navBar.completed"),
            t("navBar.projects"),
          ].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  if (index === 0) {
                    setCurrent(0);
                    props.changeCurrentPage("Requests");
                  } else if (index === 1) {
                    setCurrent(1);
                    props.changeCurrentPage("CompletedRequest");
                  } else if (index === 2) {
                    setCurrent(2);
                    props.changeCurrentPage("Project");
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  {index === 0 ? (
                    <Badge
                      color="secondary"
                      badgeContent={notification.request.length}
                    >
                      <DnsIcon
                        style={{
                          color: current === 0 ? REACT_APP_COLOR : undefined,
                        }}
                      />
                    </Badge>
                  ) : index === 1 ? (
                    <AllInboxIcon
                      style={{
                        color: current === 1 ? REACT_APP_COLOR : undefined,
                      }}
                    />
                  ) : (
                    <Badge
                      color="secondary"
                      badgeContent={notification.project.length}
                    >
                      <SourceIcon
                        style={{
                          color: current === 2 ? REACT_APP_COLOR : undefined,
                        }}
                      />
                    </Badge>
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  sx={{ opacity: open ? 1 : 0 }}
                  style={{
                    color: current === index ? REACT_APP_COLOR : undefined,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider color="white" />
        <List>
          {[t("navBar.account"), t("navBar.settings")].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  if (index === 0) {
                    getUserInformations(
                      AuthenticationService.email,
                      AuthenticationService.token
                    )
                      .then((data) => {
                        setUserInformations(data);
                        setAccountManagement(true);
                      })
                      .catch((error) => console.error(error));
                  } else if (index === 1) {
                    setSettings(true);
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  {index === 0 ? (
                    <Person4Icon />
                  ) : (
                    <SettingsApplicationsIcon
                      sx={{
                        "& svg": {
                          color: "rgba(255,255,255,0.8)",
                          transition: "0.3s",
                          transform: "translateX(0) rotate(0)",
                        },
                        "&:hover": {
                          bgcolor: "unset",
                          "& svg:first-of-type": {
                            transform: "translateX(-4px) rotate(-20deg)",
                          },
                          "& svg:last-of-type": {
                            right: 0,
                            opacity: 1,
                          },
                        },
                        "&:after": {
                          content: '""',
                          position: "absolute",
                          height: "80%",
                          display: "block",
                          left: 0,
                          width: "1px",
                          bgcolor: "divider",
                        },
                      }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Select
          style={{
            color: "white",
            margin: "5px",
            textAlign: "center",
            fontSize: 16,
            height: "35px",
          }}
          inputProps={{
            IconComponent: () => null,
            sx: { padding: "0 !important" },
          }}
          renderValue={(value) => {
            const data = language_type.find((option) => option.value === value);
            if (data !== undefined) {
              return (
                <MenuItem
                  key={data.value}
                  value={data.value}
                  style={{ textAlign: "center" }}
                >
                  <img
                    src={data.flag}
                    alt={data.label}
                    style={{
                      minHeight: "15px",
                      maxHeight: "15px",
                      minWidth: "18px",
                      maxWidth: "18px",
                      textAlign: "center",
                    }}
                  />
                  {open && <ListItemText>{data.label}</ListItemText>}
                </MenuItem>
              );
            }
          }}
          value={AuthenticationService.language}
          sx={{
            input: {
              color: "white",
              textAlign: "center",
              overflow: "visible",
              width: "100%",
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
          }}
          onChange={(event) => {
            AuthenticationService.updateLanguage(event.target.value);
          }}
        >
          {language_type.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              style={{ textAlign: "center" }}
            >
              <img
                src={option.flag}
                alt={option.label}
                style={{
                  minHeight: "15px",
                  maxHeight: "15px",
                  minWidth: "18px",
                  maxWidth: "18px",
                }}
              />
              {open && <ListItemText>{option.label}</ListItemText>}
            </MenuItem>
          ))}
        </Select>

        <List style={{ position: "absolute", bottom: "0" }}>
          {[
            t("request.newRequest"),
            AuthenticationService.firstName +
              " " +
              AuthenticationService.lastName,
            t("navBar.logout"),
          ].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  if (index === 0) {
                  } else if (index === 2) {
                    AuthenticationService.logout().then((res) => {
                      if (!res) navigate(PATHS.Home);
                      else alert("Une erreur s'est produite");
                    });
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  {index === 0 ? (
                    <>
                      <Button
                        style={{
                          maxWidth: "30px",
                          maxHeight: "30px",
                          minWidth: "30px",
                          minHeight: "30px",
                        }}
                        size="small"
                        variant="contained"
                        onClick={handleClick}
                        aria-controls={
                          openClick ? "demo-customized-menu" : undefined
                        }
                        aria-expanded={openClick ? "true" : undefined}
                        aria-haspopup="true"
                        id="demo-customized-button"
                        disableElevation
                      >
                        +
                      </Button>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={openClick}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setCurrent(0);
                            props.changeCurrentPage("NewRequest");
                          }}
                          disableRipple
                        >
                          <LibraryAddIcon />
                          {t("request.newRequest")}
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }} />
                        <MenuItem
                          disableRipple
                          onClick={() => {
                            handleClose();
                            setCurrent(0);
                            props.changeCurrentPage("NewRequestFromProject");
                          }}
                        >
                          <CreateNewFolderIcon />
                          {t("project.newRequestFromProject")}
                        </MenuItem>
                      </StyledMenu>
                    </>
                  ) : index === 1 ? (
                    <AccountCircleIcon />
                  ) : (
                    <ExitToAppIcon />
                  )}
                </ListItemIcon>
                {index === 1 ? (
                  <ListItemText
                    primary={text}
                    secondary={AuthenticationService.role}
                    sx={{ opacity: open ? 1 : 0 }}
                    secondaryTypographyProps={{ style: { color: "yellow" } }}
                  />
                ) : (
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <AccountManagement
          open={accountManagement}
          closeModal={closeAccountManagement}
          userInformations={userInformations}
        />
        <Settings open={settings} closeModal={closeSettings} />
      </Drawer>
    </>
  );
};

export default NavBar;
