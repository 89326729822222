import { TextField, Button, Grid, MenuItem } from "@mui/material";
import { Formik, Form } from "formik";

import * as Yup from "yup";
import { role_type } from "../../../Dropdown";
import { useTranslation } from "react-i18next";

interface AdminValidationForm {
  role: string;
}

const UpdateRoleForm = (props: any) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={
        {
          role: props.role,
        } as AdminValidationForm
      }
      onSubmit={(values: AdminValidationForm, actions) => {
        props.updateRole(values.role);
      }}
      validationSchema={Yup.object().shape({
        role: Yup.string(),
        reason: Yup.string(),
      })}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => {
        return (
          <Form noValidate>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="role"
                  label="Role"
                  name="role"
                  select
                  style={{ color: "white", textAlign: "left" }}
                  value={values.role}
                  helperText={errors.role && touched.role ? errors.role : ""}
                  error={errors.role && touched.role ? true : false}
                  onChange={(event) => {
                    setFieldValue("role", event.target.value);
                  }}
                  onBlur={handleBlur}
                >
                  {role_type.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" type="submit" sx={{ mr: 1 }}>
                {t("global.confirm")}
              </Button>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UpdateRoleForm;
