import * as React from "react";
import Box from "@mui/material/Box";
import { clearNotification, getMessage } from "../../../Service/ApiService";
import AuthenticationService from "../../../Service/AuthenticationService";
import Tchat from "../../Common/Modal/Tchat";
import SelectTchatCenter from "../Modal/selectTchatCenter";
import GlobalDataGrid from "../../Common/GlobalDataGrid";
import SummaryRequest from "../../Common/Modal/SummaryRequest";
import UpdateRequest from "../Modal/UpdateRequest";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const AllRequestDataGrid = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [tchat, setTchat] = useState(false);
  const [tchatMessage, setTchatMessage] = useState([]);
  const [requestData, setRequestData] = useState({} as any);
  const [selectTchatCenter, setSelectTchatCenter] = useState(false);
  const [summaryRequest, setSummaryRequest] = useState(false);
  const [summaryRequestData, setSummaryRequestData] = useState({} as any);
  const [updateRequest, setUpdateRequest] = useState(false);
  const [update, setUpdate] = useState(false);
  const [origin, setOrigin] = useState(props.originOfUpdate);
  const [endLoadGetRequest, setEndLoadGetRequest] = useState(false);

  const closeTchat = () => {
    setTchat(false);
  };

  const closeSelectTchatCenter = () => {
    setSelectTchatCenter(false);
  };
  const openSelectTchat = (
    requestId: string,
    applicantName: string,
    centers: any,
    project: boolean
  ) => {
    const newCenters = centers.map((center: any) => center.center);
    setRequestData({
      requestId: requestId,
      applicantName: applicantName,
      centers: newCenters,
      project: project,
    });
    setSelectTchatCenter(true);
  };

  const openTchat = (center: string) => {
    closeSelectTchatCenter();
    const userEmail = AuthenticationService.email;
    const token = AuthenticationService.token;
    getMessage(requestData.requestId, token, userEmail, center).then((data) => {
      setRequestData({
        requestId: requestData.requestId,
        applicantName: requestData.applicantName,
        center: center,
        target: center,
        project: requestData.project,
      });
      setTchatMessage(data);
      if (props.origin !== "CompletedRequest")
        clearNotification(
          "addMessage",
          "Default",
          requestData.requestId,
          [],
          AuthenticationService.email,
          AuthenticationService.token,
          AuthenticationService.role
        )
          .then(() => {
            AuthenticationService.updateNotification().then(() => {
              props.updateNotification();
              setTchat(true);
            });
          })
          .catch((error) => {
            enqueueSnackbar(t("error.errorHasOccuredClearNotification"), {
              variant: "error",
            });
            console.error(error);
            setTchat(true);
          });
      else setTchat(true);
    });
  };

  const selectRequestData = (newRequestData: any) => {
    setSummaryRequestData(newRequestData);
    if (props.origin !== "CompletedRequest")
      clearNotification(
        "noChat",
        "Default",
        newRequestData.id,
        [],
        AuthenticationService.email,
        AuthenticationService.token,
        AuthenticationService.role
      )
        .then(() => {
          AuthenticationService.updateNotification().then(() => {
            props.updateNotification();
            setSummaryRequest(true);
            setEndLoadGetRequest(!endLoadGetRequest)
          });
        })
        .catch((error) => {
          enqueueSnackbar(t("error.errorHasOccuredClearNotification"), {
            variant: "error",
          });
          console.error(error);
          setSummaryRequest(true);
          setEndLoadGetRequest(!endLoadGetRequest)
        });
    else {
      setSummaryRequest(true)
      setEndLoadGetRequest(!endLoadGetRequest)
    }
  };
  const closeSummaryRequest = () => {
    setSummaryRequest(false);
  };

  const reSubmitRequest = () => {
    setUpdate(!update);
    setSummaryRequest(false);
  };

  const selectSubmitWithModification = () => {
    const centers: any[] = [];
    summaryRequestData.globalInformations.center.forEach((center: any) => {
      centers.push(center.center);
    });
    summaryRequestData.globalInformations.center = centers;

    if (props.origin.indexOf("Project") !== -1)
      setOrigin("newRequestFromProject");
    setSummaryRequest(false);
    setUpdateRequest(true);
  };

  const updateRequestChoice = () => {
    const centers: any[] = [];
    summaryRequestData.globalInformations.center.forEach((center: any) => {
      centers.push(center.center);
    });
    summaryRequestData.globalInformations.center = centers;
    if (props.origin.indexOf("Project") !== -1) setOrigin("Project");
    setSummaryRequest(false);
    setUpdateRequest(true);
  };

  const updateTchat = (requestId: string) => {
    const userEmail = AuthenticationService.email;
    const token = AuthenticationService.token;
    getMessage(requestId, token, userEmail, requestData.center).then((data) => {
      setTchatMessage(data);
    });
  };

  const closeUpdate = () => {
    if (props.origin.indexOf("Project") !== -1) setOrigin("Project");
    setUpdateRequest(false);
  };

  const finish = () => {
    if (props.origin.indexOf("Project") !== -1) setOrigin("Project");
    setUpdateRequest(false);
    setUpdate(!update);
  };

  return (
    <Box sx={{ height: "80vh", width: "100%" }}>
      <GlobalDataGrid
        listOfRequest={props.listOfRequest}
        selectRequestData={selectRequestData}
        openTchat={openSelectTchat}
        origin={props.origin}
        createNewRequest={props.createNewRequest}
        update={update}
        updateNotification={props.updateNotification}
        endLoadGetRequest={endLoadGetRequest}
      />
      <SummaryRequest
        open={summaryRequest}
        closeModal={closeSummaryRequest}
        requestData={summaryRequestData}
        updateRequest={updateRequestChoice}
        reSubmitRequest={reSubmitRequest}
        selectSubmitWithModification={selectSubmitWithModification}
        origin={origin}
      />
      <Tchat
        open={tchat}
        closeModal={closeTchat}
        messages={tchatMessage}
        requestData={requestData}
        updateTchat={updateTchat}
      />
      {selectTchatCenter && (
        <SelectTchatCenter
          open={selectTchatCenter}
          closeModal={closeSelectTchatCenter}
          centers={requestData.centers}
          openTchat={openTchat}
        />
      )}
      {updateRequest && (
        <UpdateRequest
          open={updateRequest}
          requestData={summaryRequestData}
          closeModal={closeUpdate}
          finish={finish}
          origin={origin}
        />
      )}
    </Box>
  );
};

export default AllRequestDataGrid;
