import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PhotoMissionSummary = (props: any) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item xs={12}>
        <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
          {t("request.summary.numberOfAxisToFly") +
            " : " +
            props.specificInformations.numberOfAxesToFly}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
          {t("request.summary.totalDurationOfMission") +
            " : " +
            props.specificInformations.totalDurationOfMission}
        </Typography>
      </Grid>

      {props.origin !== "Project" &&
        props.origin !== "processingProject" &&
        props.origin !== "stateDefinedProject" && (
          <>
            <Grid item xs={12}>
              <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
                {t("request.summary.actualDurationOfTheMission") +
                  " : " +
                  props.specificInformations.actualDurationOfMission}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
                {t("request.summary.requestedDay") +
                  " : " +
                  props.specificInformations.requestedDay.toFormat(
                    "dd-MM-yyyy"
                  )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
                {t("request.summary.time") +
                  " : " +
                  props.specificInformations.startTime.toFormat("HHmm")}
                UTC - {props.specificInformations.endTime.toFormat("HHmm")}UTC
              </Typography>
            </Grid>
          </>
        )}
      {props.specificInformations.comments && (
        <Grid item xs={12}>
          <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
            {t("request.summary.comments") +
              " : " +
              props.specificInformations.comments}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default PhotoMissionSummary;
