import { TextField, Grid, Stack } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";

const SingleDay = (props: any) => {
  const formikProps: any = useFormikContext();
  const [schedules, setSchedules] = useState([
    [DateTime.now(), DateTime.now()],
  ]);
  const [schedulesDays, setSechedulesDays] = useState([
    DateTime.now().toFormat("dd/MM"),
  ]);

  useEffect(() => {
    const start: DateTime = formikProps.values.requestedDayStart;
    if (props.schedule.length === 0) {
      formikProps.setFieldValue("schedule", [[DateTime.now(), DateTime.now()]]);
      setSchedules([[DateTime.now(), DateTime.now()]]);
      setSechedulesDays([start.toFormat("dd/MM")]);
    } else {
      setSchedules(props.schedule);
      setSechedulesDays([start.toFormat("dd/MM")]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid xs={12}>
        <Stack spacing={3} style={{ marginTop: "12px" }}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DesktopDatePicker
              label="Requested day"
              value={formikProps.values.requestedDayStart}
              inputFormat="dd/MM/yyyy"
              onChange={(value): void => {
                formikProps.setFieldValue("requestedDayStart", value);
                formikProps.setFieldValue("requestedDayEnd", value);
                setSechedulesDays([value.toFormat("dd/MM")]);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Stack>
      </Grid>
      {schedulesDays.map((item: any, index: number) => {
        return (
          <>
            <Grid item xs={12} md={5}>
              <Stack
                spacing={3}
                style={{ marginTop: "12px", marginRight: "10px" }}
              >
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <TimePicker
                    label={"Start time of " + item}
                    value={schedules[index][0]}
                    ampm={false}
                    onChange={(value: any) => {
                      schedules[index][0] = value;
                      formikProps.setFieldValue("schedule", schedules);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              <Stack spacing={3} style={{ marginTop: "12px" }}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <TimePicker
                    label={"End time of " + item}
                    value={schedules[index][1]}
                    ampm={false}
                    onChange={(value: any) => {
                      schedules[index][1] = value;
                      formikProps.setFieldValue("schedule", schedules);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
          </>
        );
      })}
    </>
  );
};

export default SingleDay;
