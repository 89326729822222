import { Grid, Typography, Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ApplicantMap from "../../Common/Map/ApplicantMap";
import UpdateRequestForm from "./UpdatRequestForm";
import toGeoJSON from "../../ShareFonction/toGeoJson";
import { makeStyles } from "@mui/styles";
import AuthenticationService from "../../../Service/AuthenticationService";
import {
  applicantMapInformations,
  applicantMapFile,
} from "../../ShareFonction/map";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "#1F2440",
  },
  error: {
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
  },
  center: {
    textAlign: "center",
  },
  wait: {
    width: "100%",
    fontSize: "calc(10px + 2vmin)",
  },
  paper: {
    backgroundColor: "rgba(255, 255, 255, .7)",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
  },
  avatar: {},
  form: {
    width: "80%",
  },
  signUp: {
    marginTop: "14px",
  },
  touch: {
    "&:hover": { cursor: "pointer" },
  },
}));

const UpdateRequestContent = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [file, setFile] = useState(props.requestData.kmlFile);
  const [stateOnFile, setStateOnFile] = useState(true);
  const [waypoints, setWaypoints] = useState([] as any);
  const [mapInformations, setMapInformations] = useState({
    line: [],
    layer: [],
    status: "",
  } as any);

  useEffect(() => {
    if (props.requestData.specificInformations.filedRoad) {
      const newWaypoints = props.requestData.specificInformations.filedRoad
        .split(";")
        .filter((chip: string) => chip.length !== 0);
      applicantMapInformations(
        applicantMapFile,
        newWaypoints,
        file,
        AuthenticationService.email,
        AuthenticationService.token
      ).then((newMapInformations) => {
        setMapInformations(newMapInformations);
        setWaypoints(newWaypoints);
      });
    } else {
      applicantMapInformations(
        applicantMapFile,
        waypoints,
        file,
        AuthenticationService.email,
        AuthenticationService.token
      ).then((newMapInformations) => {
        setMapInformations(newMapInformations);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeFile = (newFile: string) => {
    const dom = new DOMParser().parseFromString(newFile, "text/xml");
    const test = toGeoJSON.kml(dom);
    applicantMapInformations(
      applicantMapFile,
      waypoints,
      test,
      AuthenticationService.email,
      AuthenticationService.token
    ).then((newMapInformations) => {
      setMapInformations(newMapInformations);
      setFile(test);
    });
  };
  const finish = () => {
    props.finish();
  };
  const updateStateOnFile = (newState: boolean) => {
    setStateOnFile(newState);
  };
  const createNewRoad = async (newWaypoints: string[]) => {
    applicantMapInformations(
      applicantMapFile,
      newWaypoints,
      file,
      AuthenticationService.email,
      AuthenticationService.token
    ).then((newMapInformations) => {
      setMapInformations(newMapInformations);
      setWaypoints(newWaypoints);
    });
  };

  const deleteKMLFile = () => {
    const newFile = {
      type: "FeatureCollection",
      features: [],
      name: "Initial value",
    };
    applicantMapInformations(
      applicantMapFile,
      waypoints,
      newFile,
      AuthenticationService.email,
      AuthenticationService.token
    ).then((newMapInformations) => {
      setMapInformations(newMapInformations);
      setFile(newFile);
    });
  };
  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      className={classes.wait}
    >
      <Grid item xs={stateOnFile ? 8 : 6}>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={11}>
            <Card elevation={8}>
              <ApplicantMap
                file={file}
                height={props.height ? props.height : "80"}
                mapInformations={mapInformations}
              />
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={stateOnFile ? 4 : 6}>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          style={{
            height: props.height ? props.height + "vh" : "80vh",
          }}
        >
          <Card
            elevation={8}
            style={{
              margin: "10px",
            }}
          >
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              style={{ margin: "5px" }}
            >
              <Grid item xs={11} style={{ height: "8vh", margin: "5px" }}>
                {props.origin === "update" && (
                  <Typography
                    variant="h5"
                    style={{ marginBottom: "20px", textAlign: "center" }}
                  >
                    {t("request.updateRequest")}
                  </Typography>
                )}
                {props.origin === "Project" && (
                  <Typography
                    variant="h5"
                    style={{ marginBottom: "20px", textAlign: "center" }}
                  >
                    {t("project.updateProject")}
                  </Typography>
                )}
                {props.origin === "save" && (
                  <Typography
                    variant="h5"
                    style={{ marginBottom: "20px", textAlign: "center" }}
                  >
                    {t("request.sumbmitSaveRequest")}
                  </Typography>
                )}
                {props.origin === "newRequestFromProject" && (
                  <Typography
                    variant="h5"
                    style={{ marginBottom: "20px", textAlign: "center" }}
                  >
                    {t("project.newRequestFromProject")}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} lg={12}>
                <UpdateRequestForm
                  deleteKMLFile={deleteKMLFile}
                  changeFile={changeFile}
                  finish={finish}
                  requestData={props.requestData}
                  origin={props.origin}
                  updateStateOnFile={updateStateOnFile}
                  height={props.heightForm}
                  createNewRoad={createNewRoad}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default UpdateRequestContent;
