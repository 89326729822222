export const french = {
  title: {
    signIn: "Connexion",
  },
  cookie: {
    title: "Nous utilisons des cookies",
    description:
      "Ce site utilise des cookies pour vous offrir la meilleure expérience possible.",
    accept: "J'accepte",
  },
  error: {
    errorHasOccured: "Une erreur est survenue, veuillez réessayer",
    errorHasOccuredClearNotification:
      "Une erreur est survenue durant le nettoyage des notifications",
    unableToRemoveAdministratorAccount:
      "Impossible de retirer le compte administrateur",
    unableToRetrieveAccountList: "Impossible de récupérer la liste des comptes",
    unableToRetrieveMapInformation:
      "Impossible de récupérer les informations de la carte",
    emailAndPasswordAreIncorrect:
      "Merci de vérifier que votre email et votre mot de passe sont corrects ainsi que si vous avez validé votre email.",
  },
  request: {
    successfullUpdate: "Votre demande a été mise à jour",
    successfullUpdateContent:
      "Elle sera traitée dans les plus brefs délais. <br /> Vous recevrez une notification vous informant de la fin du traitement.",
    newRequest: "Nouvelle demande",
    updateRequest: "Mise à jour de la demande",
    createNewRequestFromProject:
      "Voulez vous créer votre demande depuis un projet ?",
    sumbitSaveRequest: "Soumettre la demande sauvegardée",
    sumbitWithModifications: "Soumettre avec modifications",
    submitNow: "Soumettre maintenant",
    deleteRequest: "Demande supprimée avec succès",
    sumbitSameRequest: "Soumettre la même demande",
    submitRequest: "Votre demande a été soumise.",
    submitRequestWithChanges:
      "Votre demande a été soumise avec vos changements",
    file: "Fichier",
    global: "Générale",
    specific: "Specifique",
    confirm: "Valider",
    finish: "Fin",
    flightArea: "Commencez par définir votre zone de vol",
    sent: "Votre demande a été envoyé !",
    sentContent:
      "Elle sera traitée dans les plus brefs délais. <br /> Vous recevrez une notification vous informant de la fin du traitement.",
    deleteModalTitle: "Vous êtes sur le point de supprimer votre demande",
    deleteModalContent: "Etes-vous sur de vouloir supprimer cette demande ?",
    seleteChatCenter:
      "Séléctionner le centre avec lequel vous souhaitez échanger",
    warningCloseUpdateTitle:
      "Vous êtes sur le point de fermer la fenêtre de mise à jour",
    warningCloseUpdateContent:
      "Vos modifications ne seront pas prises en compte.<br /> Voulez-vous vraiment fermer la fenêtre ? ",
    warningNoKMLFileTitle: "Aucun fichier KML n'a été saisi",
    warningNoKMLFileContent:
      "Souhaitez-vous continuez sans ajouter de fichier KML à votre demande ? <br/> Une modification de votre demande sera possible mais cela peut augmenter le temps de traitement de votre demande.",
    warningValidationUpdateTitle:
      "Vous êtes sur le point de mettre à jour votre demande",
    warningValidationUpdateContent:
      "Cette action conduira automatiquement à une nouvelle analyse de votre demande. <br /> Confirmez-vous vos changements ? ",
    warningValidationNewRequestFromProjectTitle:
      "Vous êtes sur le point de créer une nouvelle demande depuis un projet",
    warningValidationNewRequestFromProject:
      "Cette action créera une nouvelle. <br /> Confirmez-vous la création ? ",
    conditionallyAccept:
      "Vous êtes sur le point d'accepter une demande sous conditions.",
    reject: "Vous êtes sur le point de refuser une demande.",
    chat: "Chat with",
    favoriteUpdate: "Votre favoris a été mis à jour",
    summary: {
      title: "Résumé de votre demande !",
      applicantName: "Nom du demandeur",
      referenceNumber: "Numéro de référence",
      aircraftType: "Type d'appareil",
      callSign: "Indicatif",
      flightRules: "Règles de vol",
      centers: "Centres",
      requiredLevel: "Niveau demandé",
      priorityLevel: "Priorité",
      workDescription: "Descriion du travail",
      requestedDay: "Jour demandé",
      time: "Date et heure",
      comments: "Commentaires",
      departureAerodrome: "Aérodrome de départ",
      arrivalAerodrome: "Aérodrome d'arrivée",
      requestedDayStart: "Début de la période demandée",
      requestedDayEnd: "Fin de la période demandée",
      numberOfAxisToFly: "Nombre d'axes de vol",
      totalDurationOfMission: "Durée totale de la mission",
      actualDurationOfTheMission: "Durée réemme de la mission",
      numberOfDrops: "Nombre de sauts",
      filedRoad: "Route déposée",
      typeOfMission: "Type de mission",
      summaryRequest: "Résumé de la demande",
      flightType: "Type de vol",
      state: "Etat",
      action: "Action",
      globalInformations: "Informations générales",
      currentState: "Etat actuel",
    },
    noRequest: "Aucune demande",
    createNewRequestNow: "Vous pouvez créer une nouvelle demande maintenant",
    rejectButton: "Refuser",
    approveUnderConditionButton: "Approuver sous conditions",
    acceptButton: "Accepter",
  },
  project: {
    newProject: "Nouveau projet",
    updateProject: "Mise à jour du projet",
    newRequestFromProject: "Nouvelle demande depuis le projet",
    deleteProject: "Projet supprimé avec succès",
    submitProject: "Votre projet a été soumis",
    successCreationOfRequestFromProjet:
      "Création d'une nouvelle demande depuis le projet réussite",
    noProject: "Pas de projet",
    createNewProjectNow: "Vous pouvez créer un nouveau projet maintenant",
    summaryProject: "Résumé du projet",
  },
  subAccount: {
    subAccount: "Sous compte",
    createNewSubAccount: "Vous pouvez créer un nouveau compte en haut",
    successAdd: "Succès de l'ajout de l'utilisateur !",
    successDelete: "Succès de la suppression de l'utilisateur !",
  },
  protec: {
    protecInformationsTitle: "Définition des éléments de la protec",
    protecUpdate:
      "Les informations de la protec ont été mises à jour avec succès",
    informations: "Informations",
    protec: "Protec",
  },
  form: {
    passwordMatch:
      "Au minimum 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial",
    passwordMinLenght: "8 caractères minimum",
    enterValidPassword: "Entrez un mot de passe valide",
    passwordsMustMatch: "Les mots de passe doivent correspondre",
    enterValidCheckPassword: "Entrez un mot de passe de validation valide",
    enterValidEmail: "Entrez un email valide",
    enterValidRequiredLevel: "Entrez un niveau demandé valide",
    enterValidWorkDescription: "Entrez une description du travail valide",
    enterValidstartTime: "Entrez une heure de début valide",
    enterValidendTime: "Entrez une heure de fin valdie",
    enterValidDepartureAerodrome: "Entrez un aérodrome de départ valide",
    enterValidArrivalAerodrome: "Entrez un aérodrome d'arrivée valide",
    enterValidAxesToFly: "Entrez un nombre d'axes de vol valide",
    enterValidTotalDurationOfMission:
      "Entrez une durée de mission totale valide",
    enterValidActualDurationOfMission:
      "Entrez une durée réelle de mission valide",
    enterValidFiledRoad: "Entrez une route déposée valide",
    enterValidTypeOfMission: "Entrez un type de mission valide",
    enterValidDescription: "Entrez une description valide",
    enterValidAircraftType: "Entrez un type d'appareil valide",
    enterValidReferenceNumber: "Entrez un numéro de référence valide",
    enterValidCallsign: "Entrez un indicatif valide",
    enterValidFlightRules: "Entrez des règles de vol valide",
    enterValidFlightType: "Entrez un type de vol valide",
    enterValidCenter: "Entrez un centre valide",
    enterValidCompanyName: "Entrez un nom d'entreprise valide",
    enterValidFavoriteFlightType: "Entrez un type de vol favoris",
    enterValidFirstName: "Entrez un prénom valide",
    enterValidName: "Entrez un nom valide",
    minCenter: "Choisissez au moins un centre",
    addUser: "Ajouter un utilisateur",
    numberOfDays: "Nombre de jours",
    moreDays: "Plus de jours",
  },
  global: {
    confirm: "Valider",
    update: "Mettre à jour",
    delete: "Supprimer",
    reset: "Réinitialiser",
    successfullUpdate: "Mise à jour réussie",
    back: "Retour",
    next: "Suivant",
    step: "Etape",
    alreadyCompleted: "déjà complété",
    save: "Sauvegardé",
    undo: "annuler",
    home: "Accueil",
    ok: "Ok",
    noSolution: "Aucune solution n'a été trouvé avec le filtre ...",
    tryAgain: "Essayer avec d'autres paramètres",
    updateOfSuccessStatus: "Mise à jour de l'état réussie",
    successfulUpdate: "Mise à jour réussie",
  },
  navBar: {
    inProgress: "En cours / en attente",
    saved: "Sauvegardé",
    projects: "Projets",
    account: "Compte",
    settings: "Paramètres",
    newRequest: "Nouvelle demande",
    logout: "Déconnexion",
    all: "Tous",
    completed: "Terminé",
    onHold: "En attente",
    favorite: "Favoris",
    userManagement: "Gestion des utilisateurs",
    statistics: "Statistiques",
  },
  register: {
    register: "S'inscrire",
    confirmEmailTitle: "Merci d'avoir valider votre email",
    confirmEmailContent:
      "Avant que vous puissiez profiter pleinement de notre interface, un administrateur doit confirmer votre inscription. <br/> Un courriel vous sera envoyé pour confirmer l'activation définitive de votre compte.",
    resetYourPassword: "Réinitialiser votre mot de passe",
    sentEmail: "Un email vous a été envoyé",
    forgotPassword: "Mot de passe oublié",
    backToLogin: "Retour à la conexion",
    user: "Utilisateur",
    company: "Entreprise",
    choosePassword: "Choisir le mot de passe",
    confirmRegistration: "Confirmer l'inscription",
    updatePasswordSuccessTitle: "Changement du mot de passe réussie !",
    updatePasswordSuccessContent:
      "Votre nouveau mot de passe a été enregistré avec succès. <br/> Vous pouvez maintenant vous connecter.",
    confirmRegisterTitle: "Merci de vous être inscrit !",
    confirmRegisterContent:
      "Votre demande de création de compte a bien été prise en compte. <br />  Vous allez recevoir un courriel de confirmation, veuillez cliquer sur le lien pour valider votre compte. <br />  Un administrateur vous attribuera vos permissions.",
    createFirstPasswordTitleSuccess:
      "Création de votre mot de passe avec succès !",
    createFirstPasswordContentSuccess:
      "Votre mot de passe a bien été enregistré. <br /> Vous pouvez maintenant vous connecter.",
    createFirstPasswordTitle: "Création de votre premier mot de passe",
  },
  account: {
    accountManagement: "Gestion des comptes",
    newUser: "Nouveal utilisateur",
    manage: "Gérer le compte de",
    saccount: "",
    updateRole: "Mettre à jour le role de",
    validationAccount: "Validation du compte de",
    account: "",
    warningUserDontValidEmail:
      "* L'utilisateur n'a pas validé son email. Si vous acceptez l'utilisateur, son email sera automatiquement validée.",
    firstName: "Prénom",
    name: "Nom",
    companyName: "Nom de l'entreprise",
    center: "Centre",
    favoriteFlightType: "Type de vol préféré",
    phoneNumber: "Numéro de téléphone",
    emailValidation: "Validation de l'email",
    adminValidation: "Validation par l'administrateur",
    role: "Role",
    noUser: "Pas d'utilisateur",
    createNewAdmin:
      "Vous pouvez créer un nouvel administrateur au-dessus du tableau",
  },
  settings: {
    settings: "Paramètres",
    faq: "FAQ",
    generalSettings: "Paramètres généraux",
    version: "Version",
    userManual: "Manuel d'utilisateur",
    legal: "Legal",
    privacyPolicy: "Mentions légales",
    termsAndConditions: "Conditions d'utilisation",
    support: "Aide",
  },
  statistics: {
    repartitionOfAcceptancesAndRejections:
      "Répartition des acceptations et rejets entre les demandeurs",
    overallDistributionAcceptances: "Répartition global des acceptations",
    distributionOfApplicant: "% de distribution par demandeur",
    applicant: "Demandeur",
  },
  newUser: "Nouvel utilisateur",
};
