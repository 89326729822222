import { TextField, Button, Grid, Box, Typography, Stack } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface CalibrationInformationsForm {
  workDescription: string;
  requestedDay: DateTime;
  startTime: DateTime;
  endTime: DateTime;
  comments: string;
}

const CalibrationInformations = (props: any) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(props.specificInformations).length === 0) {
      setSpecificInformations({
        workDescription: "",
        requestedDay: DateTime.now(),
        startTime: DateTime.now(),
        endTime: DateTime.now(),
        comments: "",
      });
    } else {
      setSpecificInformations(props.specificInformations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [specificInformations, setSpecificInformations] = useState(
    props.specificInformations as CalibrationInformationsForm
  );
  return (
    <Formik
      initialValues={specificInformations as CalibrationInformationsForm}
      enableReinitialize
      onSubmit={(values: CalibrationInformationsForm, actions) => {
        props.specificInformationsValidation({
          workDescription: values.workDescription,
          requestedDay: values.requestedDay,
          startTime: values.startTime,
          endTime: values.endTime,
          comments: values.comments,
        });
      }}
      validationSchema={Yup.object().shape({
        workDescription: Yup.string().required(
          t("form.enterValidWorkDescription") || ""
        ),
        startTime: Yup.string().required(t("form.enterValidStartTime") || ""),
        endTime: Yup.string().required(t("form.enterValidEndTime") || ""),
        comments: Yup.string(),
      })}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => {
        return (
          <Form noValidate>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="workDescription"
                  label="Work description"
                  multiline
                  rows={3}
                  name="workDescription"
                  style={{ color: "white" }}
                  value={values.workDescription}
                  helperText={
                    errors.workDescription && touched.workDescription
                      ? errors.workDescription
                      : ""
                  }
                  error={
                    errors.workDescription && touched.workDescription
                      ? true
                      : false
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              {props.origin !== "Project" && (
                <>
                  <Grid xs={12}>
                    <Stack spacing={3} style={{ marginTop: "12px" }}>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DesktopDatePicker
                          label="Requested day"
                          value={values.requestedDay}
                          inputFormat="dd/MM/yyyy"
                          onChange={(value): void => {
                            setFieldValue("requestedDay", value);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={5} style={{ margin: "12px" }}>
                    <Stack spacing={3} style={{ marginTop: "12px" }}>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <TimePicker
                          label="Start time"
                          value={values.startTime}
                          ampm={false}
                          onChange={(value) => {
                            setFieldValue("startTime", value);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={5} style={{ margin: "12px" }}>
                    <Stack spacing={3} style={{ marginTop: "12px" }}>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <TimePicker
                          label="End time"
                          value={values.endTime}
                          ampm={false}
                          onChange={(value) => {
                            setFieldValue("endTime", value);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="comments"
                  label="Comments"
                  name="comments"
                  multiline
                  rows={4}
                  style={{ color: "white" }}
                  value={values.comments}
                  helperText={
                    errors.comments && touched.comments ? errors.comments : ""
                  }
                  error={errors.comments && touched.comments ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                disabled={props.activeStep === 0}
                onClick={props.handleBack}
                sx={{ mr: 1 }}
                variant="outlined"
              >
                {t("global.back")}
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button type="submit" sx={{ mr: 1 }} variant="contained">
                {t("global.next")}
              </Button>
            </Box>
            {props.activeStep !== props.steps.length &&
              props.completed[props.activeStep] && (
                <Typography variant="caption" sx={{ display: "inline-block" }}>
                  {t("global.step") +
                    " " +
                    (props.activeStep + 1) +
                    " " +
                    t("global.alreadyCompleted")}
                </Typography>
              )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default CalibrationInformations;
