import { Grid } from "@mui/material";

const Message = (props: any) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      style={{
        backgroundColor: props.backgroundColor,
        margin: "10px",
        borderRadius: "25px",
      }}
    >
      <Grid item xs={11} style={{ margin: "5px" }}>
        {props.message.content}
      </Grid>
    </Grid>
  );
};

export default Message;
