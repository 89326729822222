import * as React from "react";
import { listRequest } from "../../../../Service/ApiService";
import AuthenticationService from "../../../../Service/AuthenticationService";
import { Grid, Button } from "@mui/material";
import NewRequest from "../../Page/NewRequest";
import { useState } from "react";
import AllRequestDataGrid from "../AllRequestDataGrid";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const ProjectDataGrid = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const listOfProject = async () => {
    return listRequest(
      AuthenticationService.email,
      AuthenticationService.token,
      false,
      true
    )
      .then((newData) => {
        return newData;
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error(error);
      });
  };
  const [newProject, setNewProject] = useState(false);
  const [origin, setOrigin] = useState("Project");
  const [update, setUpdate] = useState(false);

  const createNewProject = () => {
    setNewProject(true);
  };

  const updateOrigin = (newOrigin: string) => {
    setOrigin(newOrigin);
  };

  const finishNewProject = () => {
    setNewProject(false);
    setUpdate(!update);
  };
  return (
    <>
      {!newProject && (
        <>
          <Grid container style={{ marginBottom: "20px" }}>
            <Grid item xs={1}></Grid>
            <Grid item xs={11} style={{ textAlign: "left" }}>
              <Button
                variant="contained"
                style={{
                  minHeight: "30px",
                  maxHeight: "30px",
                  minWidth: "30px",
                  maxWidth: "30px",
                }}
                onClick={() => {
                  setNewProject(true);
                }}
              >
                +
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={11}>
              <AllRequestDataGrid
                listOfRequest={listOfProject}
                origin="Project"
                createNewRequest={createNewProject}
                update={update}
                originOfUpdate={origin}
                updateOrigin={updateOrigin}
                updateNotification={props.updateNotification}
              />
            </Grid>
          </Grid>
        </>
      )}
      {newProject && (
        <NewRequest origin="Project" changeCurrentPage={finishNewProject} />
      )}
    </>
  );
};

export default ProjectDataGrid;
