import { Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const ShowStatusAndModification = (props: any) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      alignItems="center"
      alignContent="center"
      justifyContent="center"
    >
      <Button
        variant="contained"
        size="small"
        style={{ marginRight: "10px" }}
        onClick={() => {
          props.updateOrigin(
            props.origin === "stateDefined" ? "processing" : "processingProject"
          );
        }}
      >
        {t("global.update")}
      </Button>
    </Grid>
  );
};

export default ShowStatusAndModification;
