import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import { updateUserRole } from "../../../../Service/ApiService";
import AuthenticationService from "../../../../Service/AuthenticationService";
import UpdateRoleForm from "../../Module/accountManagement/UpdateRoleForm";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const UpdateRole = (props: any) => {
  const { t } = useTranslation();

  const updateRole = (role: string) => {
    updateUserRole(
      role,
      props.user.email,
      AuthenticationService.token,
      AuthenticationService.email
    ).then((data) => {
      if (data === "Success") {
        props.updateListUser();
        props.closeModal();
      }
    });
  };
  return (
    <>
      <DialogTitle>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5" style={{ marginRight: "20px" }}>
            {t("account.updateRole") +
              " " +
              props.user.firstName +
              " " +
              props.user.name}
          </Typography>
          <Grid
            item
            style={{ position: "absolute", right: "12px", top: "12px" }}
          >
            <IconButton
              onClick={() => {
                props.closeModal();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          <UpdateRoleForm updateRole={updateRole} role={props.user.role} />
        </DialogContentText>
      </DialogContent>
    </>
  );
};
export default UpdateRole;
