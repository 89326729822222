import {
  TextField,
  Button,
  Grid,
  Box,
  Typography,
  MenuItem,
  InputLabel,
  Backdrop,
  Slider,
  CircularProgress,
  Chip,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  flight_type,
  crna_type,
  priorityLevel_type,
  flightRules_type,
} from "../../../Dropdown";
import { useTranslation } from "react-i18next";
import determineCenter from "../../../ShareFonction/determineCenter";
import { useEffect, useState } from "react";
import { listAllUserOfCompany } from "../../../../Service/ApiService";
import AuthenticationService from "../../../../Service/AuthenticationService";
import { useSnackbar } from "notistack";

interface GlobalInformationsForm {
  applicantName: string;
  aircraftType: string;
  flightRules: string;
  referenceNumber: string;
  callsign: string;
  requiredLevel: string;
  priorityLevel: number;
  flightType: string;
  center: string[];
  usersEmail: string[];
}

const GlobalInformations = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    listAllUserOfCompany(
      AuthenticationService.email,
      AuthenticationService.token,
      AuthenticationService.company
    )
      .then((userSubAccount: any) => {
        const mainAccount = userSubAccount.filter(
          (account: any) => account.isMainAccount
        );
        if (
          !props.globalInformations.usersEmail.includes(mainAccount[0].email)
        ) {
          props.globalInformations.usersEmail.push(mainAccount[0].email);
        }

        setUserSubAccount(userSubAccount);
      })
      .catch(() => {
        enqueueSnackbar(t("error.unableToRetrieveAccountList"), {
          variant: "error",
        });
      });

    if (props.globalInformations.requiredLevel) {
      const regex = /^[0-9-]*$/;
      if (regex.test(props.globalInformations.requiredLevel)) {
        const input = props.globalInformations.requiredLevel;
        const inputSplit = input
          .split("-")
          .filter((chip: any) => chip.length !== 0);

        const newRequiredLevelChip = inputSplit.map((value: any) => {
          return { requiredLevel: value, color: "success" };
        });
        setRequiredlevel(newRequiredLevelChip);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = useState(false);
  const [userSubAccount, setUserSubAccount] = useState([] as any);
  const [requiredLevel, setRequiredlevel] = useState([] as any);

  return (
    <>
      <Formik
        initialValues={props.globalInformations as GlobalInformationsForm}
        onSubmit={(values: GlobalInformationsForm, actions) => {
          props.globalInformationsValidation(
            values.applicantName,
            values.aircraftType,
            values.flightRules,
            values.referenceNumber,
            values.callsign,
            values.flightType,
            values.center,
            values.requiredLevel,
            values.priorityLevel,
            values.usersEmail
          );
        }}
        validationSchema={Yup.object().shape({
          aircraftType: Yup.string().required(
            t("form.enterValidAircraftType") || ""
          ),
          referenceNumber: Yup.string().required(
            t("form.enterValidReferenceNumber") || ""
          ),
          callsign: Yup.string().required(t("form.enterValidCallsign") || ""),
          requiredLevel: Yup.string(),
          flightRules: Yup.string().required(
            t("form.enterValidFlightRules") || ""
          ),
          flightType: Yup.string().required(
            t("form.enterValidFlightType") || ""
          ),
          center: Yup.array()
            .min(1, t("form.minCenter") || "")
            .required(t("form.enterValidCenter") || ""),
          usersEmail: Yup.array()
            .min(1, t("form.minCenter") || "")
            .required(t("form.enterValidCenter") || ""),
        })}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          handleBlur,
          handleChange,
        }) => {
          return (
            <Form noValidate>
              <Grid
                container
                alignContent="center"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={10} md={5} style={{ margin: "5px" }}>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    {requiredLevel.map((chip: any, index: number) => (
                      <Chip
                        key={index}
                        label={chip.requiredLevel}
                        color={chip.color}
                        variant="outlined"
                        style={{ visibility: "hidden" }}
                      />
                    ))}
                  </Grid>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="referenceNumber"
                    label="Reference number"
                    name="referenceNumber"
                    style={{ color: "white" }}
                    value={values.referenceNumber}
                    helperText={
                      errors.referenceNumber && touched.referenceNumber
                        ? errors.referenceNumber
                        : " "
                    }
                    error={
                      errors.referenceNumber && touched.referenceNumber
                        ? true
                        : false
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={10} md={5} style={{ margin: "5px" }}>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    {requiredLevel.map((chip: any, index: number) => (
                      <Chip
                        key={index}
                        label={chip.requiredLevel}
                        color={chip.color}
                        variant="outlined"
                      />
                    ))}
                  </Grid>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="requiredLevel"
                    label="Required level"
                    name="requiredLevel"
                    style={{ color: "white" }}
                    value={values.requiredLevel}
                    helperText={
                      errors.requiredLevel && touched.requiredLevel
                        ? errors.requiredLevel
                        : " "
                    }
                    error={
                      errors.requiredLevel && touched.requiredLevel
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const regex = /^[0-9-]*$/;
                      if (regex.test(e.target.value)) {
                        const input = e.target.value.toUpperCase();
                        const inputSplit = input
                          .split("-")
                          .filter((chip) => chip.length !== 0);
                        const newRequiredLevelChip = inputSplit.map(
                          (value: any) => {
                            return { requiredLevel: value, color: "success" };
                          }
                        );
                        setRequiredlevel(newRequiredLevelChip);
                        setFieldValue("requiredLevel", e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      const regex = /^[0-9-]*$/;
                      if (
                        props.kmlFileProcessed &&
                        regex.test(e.target.value)
                      ) {
                        const input = e.target.value.toUpperCase();
                        const inputSplit = input
                          .split("-")
                          .filter((chip) => chip.length !== 0)
                          .map((inputValue: string) => parseInt(inputValue));
                        setLoading(true);
                        determineCenter(
                          props.kmlFileProcessed,
                          inputSplit
                        ).then((newCenters) => {
                          setFieldValue("center", newCenters);
                          setLoading(false);
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={10} md={5} style={{ margin: "5px" }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="aircraftType"
                    label="Aircraft type"
                    name="aircraftType"
                    style={{ color: "white", textAlign: "left" }}
                    value={values.aircraftType}
                    helperText={
                      errors.aircraftType && touched.aircraftType
                        ? errors.aircraftType
                        : " "
                    }
                    error={
                      errors.aircraftType && touched.aircraftType ? true : false
                    }
                    onChange={(event) => {
                      setFieldValue("aircraftType", event.target.value);
                    }}
                    onBlur={handleBlur}
                  ></TextField>
                </Grid>
                <Grid item xs={10} md={5} style={{ margin: "5px" }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="callsign"
                    label="Callsign"
                    name="callsign"
                    style={{ color: "white" }}
                    value={values.callsign}
                    helperText={
                      errors.callsign && touched.callsign
                        ? errors.callsign
                        : " "
                    }
                    error={errors.callsign && touched.callsign ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={10} md={5} style={{ margin: "5px" }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="flightRules"
                    label="Flight Rules"
                    name="flightRules"
                    select
                    style={{ color: "white", textAlign: "left" }}
                    value={values.flightRules}
                    helperText={
                      errors.flightRules && touched.flightRules
                        ? errors.flightRules
                        : " "
                    }
                    error={
                      errors.flightRules && touched.flightRules ? true : false
                    }
                    onChange={(event) => {
                      setFieldValue("flightRules", event.target.value);
                    }}
                    onBlur={handleBlur}
                  >
                    {flightRules_type.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={10} md={5} style={{ margin: "5px" }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="flightType"
                    label="Flight type"
                    name="flightType"
                    select
                    style={{ color: "white", textAlign: "left" }}
                    value={values.flightType}
                    helperText={
                      errors.flightType && touched.flightType
                        ? errors.flightType
                        : " "
                    }
                    error={
                      errors.flightType && touched.flightType ? true : false
                    }
                    onChange={(event) => {
                      setFieldValue("flightType", event.target.value);
                    }}
                    onBlur={handleBlur}
                  >
                    {flight_type.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={10}>
                  <InputLabel>{t("request.summary.priorityLevel")}</InputLabel>
                  <Slider
                    defaultValue={values.priorityLevel}
                    step={1}
                    max={5}
                    min={1}
                    marks={priorityLevel_type}
                    valueLabelDisplay="auto"
                    onChange={(value: any) => {
                      if (value.target)
                        setFieldValue("priorityLevel", value.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={10}
                  md={5}
                  style={{ textAlign: "center", margin: "15px" }}
                >
                  <TextField
                    select
                    name="center"
                    id="center"
                    variant="outlined"
                    fullWidth
                    label="Center"
                    SelectProps={{
                      multiple: true,
                      value: values.center,
                      onChange: (e) => {
                        setFieldValue("center", e.target.value);
                      },
                    }}
                  >
                    {crna_type.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={10} md={5} style={{ textAlign: "center" }}>
                  <TextField
                    select
                    name="usersEmail"
                    id="usersEmail"
                    variant="outlined"
                    fullWidth
                    label={t("subAccount.subAccount")}
                    SelectProps={{
                      multiple: true,
                      value: values.usersEmail,
                      onChange: (e) => {
                        setFieldValue("usersEmail", e.target.value);
                      },
                    }}
                  >
                    {userSubAccount.map((user: any) => (
                      <MenuItem
                        key={user.email}
                        value={user.email}
                        selected={user.isMainAccount}
                        disabled={user.isMainAccount}
                      >
                        {user.firstName + " " + user.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  disabled={props.activeStep === 0}
                  onClick={props.handleBack}
                  sx={{ mr: 1 }}
                  variant="outlined"
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button variant="contained" type="submit" sx={{ mr: 1 }}>
                  Next
                </Button>
              </Box>
              {props.activeStep !== props.steps.length &&
                props.completed[props.activeStep] && (
                  <Typography
                    variant="caption"
                    sx={{ display: "inline-block" }}
                  >
                    Step {props.activeStep + 1} already completed
                  </Typography>
                )}
            </Form>
          );
        }}
      </Formik>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme: { zIndex: { drawer: number } }) =>
            theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" disableShrink />
      </Backdrop>
    </>
  );
};

export default GlobalInformations;
