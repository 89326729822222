import { useState } from "react";
import { listUser } from "../../../../Service/ApiService";
import AuthenticationService from "../../../../Service/AuthenticationService";
import { Button, Grid } from "@mui/material";
import UserDataGrid from "./UserDataGrid";
import AddUser from "../../Modal/ManageUser/AddUser";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const UserManagementDataGrid = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const listOfRequest = async () => {
    return listUser(AuthenticationService.email, AuthenticationService.token)
      .then((newData: any) => {
        return newData;
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const [addUser, setAddUser] = useState(false);
  const [update, setUpdate] = useState(false);

  const updateUserList = () => {
    setUpdate(!update);
  };

  const closeAddUser = () => {
    setAddUser(false);
  };

  const successfullAditionUser = () => {
    updateUserList();
    setAddUser(false);
  };

  return (
    <>
      <Grid container style={{ marginBottom: "20px" }}>
        <Grid item xs={12} style={{ textAlign: "left" }}>
          <Button
            variant="contained"
            style={{
              minHeight: "30px",
              maxHeight: "30px",
              minWidth: "30px",
              maxWidth: "30px",
            }}
            onClick={() => {
              setAddUser(true);
            }}
          >
            +
          </Button>
        </Grid>
      </Grid>
      <UserDataGrid
        listOfRequest={listOfRequest}
        updateUserList={updateUserList}
      />
      <AddUser
        open={addUser}
        closeModal={closeAddUser}
        successfullAditionUser={successfullAditionUser}
      />
    </>
  );
};

export default UserManagementDataGrid;
