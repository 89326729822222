import { Grid, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

const VariousSummary = (props: any) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item xs={12}>
        <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
          {t("request.summary.typeOfMission") +
            " : " +
            props.specificInformations.typeOfMission}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
          {t("request.summary.workDescription") +
            " : " +
            props.specificInformations.description}
        </Typography>
      </Grid>
      {props.origin.indexOf("Project") === -1 && (
        <>
          {props.specificInformations.requestedDays.map(
            (requestedDay: DateTime, index: number) => {
              return (
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                  >
                    <Grid item xs={12}>
                      <Typography
                        style={{ textAlign: "left", marginBottom: "12px" }}
                      >
                        {t("request.summary.requestedDay") +
                          " : " +
                          requestedDay.toFormat("dd-MM-yyyy")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        style={{ textAlign: "left", marginBottom: "12px" }}
                      >
                        {t("request.summary.time") +
                          " : " +
                          props.specificInformations.schedule[
                            index
                          ][0].toFormat("HH:mm") +
                          " UTC - " +
                          props.specificInformations.schedule[
                            index
                          ][1].toFormat("HH:mm") +
                          " UTC"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
          )}
        </>
      )}

      {props.specificInformations.comments && (
        <Grid item xs={12}>
          <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
            {t("request.summary.comments") +
              " : " +
              props.specificInformations.comments}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default VariousSummary;
