export const flight_type = [
  {
    value: "Photo mission",
    label: "Photo mission",
  },
  {
    value: "Test flight",
    label: "Test flight",
  },
  {
    value: "Calibration",
    label: "Calibration",
  },
  {
    value: "Skydiving",
    label: "Skydiving",
  },
  {
    value: "Media coverage",
    label: "Media coverage",
  },

  {
    value: "Scientific flight",
    label: "Scientific flight",
  },

  {
    value: "Various",
    label: "Various",
  },
];

export const language_type = [
  {
    value: "en",
    flag: "https://flagpedia.net/data/flags/w580/gb.webp",
    label: "ENG",
  },
  {
    value: "fr",
    flag: "https://flagpedia.net/data/flags/w580/fr.png",
    label: "FRA",
  },
];

export const company_type = [
  {
    value: "Control center",
    label: "Control center",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const equipement_type = [
  {
    value: "balloon",
    label: "balloon",
  },
  {
    value: "aicraft",
    label: "aicraft",
  },
  {
    value: "tt",
    label: "tt",
  },
];

export const flightRules_type = [
  {
    value: "IFR",
    label: "IFR",
  },
  {
    value: "VFR",
    label: "VFR",
  },
  {
    value: "Y",
    label: "Y",
  },
  {
    value: "X",
    label: "X",
  },
];

export const crna_type = [
  {
    value: "CRNA-E",
    label: "CRNA-E",
  },
  {
    value: "CRNA-N",
    label: "CRNA-N",
  },
  {
    value: "CRNA-SO",
    label: "CRNA-SO",
  },
  {
    value: "CRNA-SE",
    label: "CRNA-SE",
  },
  {
    value: "CRNA-O",
    label: "CRNA-O",
  },
];

export const role_type = [
  {
    value: "Default",
    label: "Default",
  },
  {
    value: "Administrator",
    label: "Administrator",
  },
];

export const priorityLevel_type = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];
