import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const WarningCloseUpdateModal = (props: any) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5" style={{ marginRight: "30px" }}>
            {t("request.warningCloseUpdateTitle")}
          </Typography>
          <Grid item style={{ position: "absolute", right: "12px" }}>
            <IconButton
              onClick={() => {
                props.undo();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          {parse(t("request.warningCloseUpdateContent"))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              props.abort();
            }}
          >
            {t("global.confirm")}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default WarningCloseUpdateModal;
