import {
  Button,
  Grid,
  Box,
  Typography,
  IconButton,
  Stack,
  CircularProgress,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React from "react";
import WarningNoSelectKMLFile from "../../Modal/WarningNoSelectKMLFile";
import { useTranslation } from "react-i18next";
import toGeoJSON from "../../../ShareFonction/toGeoJson";

const SelectKMLFile = (props: any) => {
  const { t } = useTranslation();

  const [file, setFile] = React.useState(props.kmlFileInformations.file as any);
  const [fileName, setFileName] = React.useState(
    props.kmlFileInformations.fileName as any
  );
  const [warningNoSelectKML, setWarningNoSelectKML] = React.useState(false);

  const closeWarningNoSelectKML = () => {
    setWarningNoSelectKML(false);
  };

  const changeFile = (newFile: any) => {
    const file = newFile.target.files[0];
    const fileReader: any = new FileReader();
    const newFileName = newFile.target.files[0].name;
    fileReader.readAsText(file);

    fileReader.onload = () => {
      const dom: any = new DOMParser().parseFromString(
        fileReader.result,
        "text/xml"
      );

      const converted = toGeoJSON.kml(dom);
      setFile(fileReader.result);
      setFileName(newFileName);
      props.selectKMLFileChange(fileReader.result, converted, newFileName);
    };
  };
  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} style={{ height: "10vh" }}>
        <Typography> {t("request.flightArea")}</Typography>
      </Grid>
      {!props.loading && (
        <>
          <Grid item xs={12}>
            <Stack alignItems="center" spacing={2}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept=".kml"
                  type="file"
                  onChange={(newFile) => {
                    changeFile(newFile);
                  }}
                />
                <CloudUploadIcon style={{ height: "10vh", width: "100%" }} />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {fileName && <Typography>{fileName}</Typography>}
          </Grid>
        </>
      )}
      {props.loading && (
        <div style={{ height: "15vh" }}>
          <CircularProgress />
        </div>
      )}
      <Grid item xs={12}>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            disabled={props.activeStep === 0}
            onClick={props.handleBack}
            sx={{ mr: 1 }}
            variant="outlined"
          >
            {t("global.back")}
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            type="submit"
            sx={{ mr: 1 }}
            disabled={props.loading}
            onClick={() => {
              if (file !== null && file !== undefined) {
                props.selectKMLFileValidation();
              } else {
                setWarningNoSelectKML(true);
              }
            }}
            variant="contained"
          >
            {t("global.next")}
          </Button>
        </Box>
      </Grid>
      {props.activeStep !== props.steps.length &&
        props.completed[props.activeStep] && (
          <Typography variant="caption" sx={{ display: "inline-block" }}>
            {t("global.step") +
              " " +
              (props.activeStep + 1) +
              " " +
              t("global.alreadyCompleted")}
          </Typography>
        )}
      <WarningNoSelectKMLFile
        open={warningNoSelectKML}
        closeModal={closeWarningNoSelectKML}
        undo={closeWarningNoSelectKML}
        confirm={props.selectKMLFileValidation}
      />
    </Grid>
  );
};

export default SelectKMLFile;
