import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const WarningNoSelectKMLFile = (props: any) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle>{t("request.warningNoKMLFileTitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          {parse(t("request.warningNoKMLFileContent"))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              props.undo();
            }}
            style={{ marginRight: "10px" }}
          >
            {t("global.undo")}
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              props.confirm();
            }}
          >
            {t("global.confirm")}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default WarningNoSelectKMLFile;
