import { Button, Grid, Box, Step, Stepper, StepLabel } from "@mui/material";
import React from "react";
import MapIcon from "@mui/icons-material/Map";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import GlobalInformations from "./NewRequest/GlobalInformations";
import SpecificInformations from "./NewRequest/SpecificInformations";
import GradingIcon from "@mui/icons-material/Grading";
import ParaglidingIcon from "@mui/icons-material/Paragliding";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import { styled } from "@mui/material/styles";
import { StepIconProps } from "@mui/material/StepIcon";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
  addNewRequestFromProject,
  updateRequest,
} from "../../../Service/ApiService";
import { DateTime } from "luxon";
import SummaryOfUpdatedRequest from "./UpdateRequest/SummaryOfUpdatedRequest";
import SelectKMLFile from "./UpdateRequest/SelectKMLFile";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ConfirmNewRequest from "./NewRequest/ConfirmNewRequest";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "radial-gradient( circle farthest-corner at 10% 20%,  rgba(37,145,251,0.98) 0.1%, rgba(0,7,128,1) 99.8% )",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage: "linear-gradient(to top, #a3bded 0%, #6991c7 100%);",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <MapIcon />,
    2: <WbIncandescentIcon />,
    3: <ParaglidingIcon />,
    4: <GradingIcon />,
    5: <SportsScoreIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const UpdateRequestForm = (props: any) => {
  const { t } = useTranslation();

  const steps = [
    t("request.file"),
    t("request.global"),
    t("request.specific"),
    t("request.confirm"),
    t("request.finish"),
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const [globalInformations, setGlobalInformations] = React.useState(
    props.requestData.globalInformations
  );

  const [specificInformations, setSpecificInformations] = React.useState(
    props.requestData.specificInformations
  );

  const [kmlFileInformations, setKMLFileInformations] = React.useState({
    kmlFile: props.requestData.referenceToKMLFile,
    fileName: props.requestData.referenceToKMLFile ? "Load in database" : null,
  });

  const { enqueueSnackbar } = useSnackbar();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const globalInformationsValidation = (
    applicantName: string,
    aircraftType: string,
    flightRules: string,
    referenceNumber: string,
    callsign: string,
    flightType: string,
    center: string,
    requiredLevel: string,
    priorityLevel: number,
    usersEmail: string[]
  ) => {
    if (flightType !== globalInformations.flightType) {
      setSpecificInformations({});
    }
    setGlobalInformations({
      applicantName: applicantName,
      aircraftType: aircraftType,
      flightRules: flightRules,
      referenceNumber: referenceNumber,
      callsign: callsign,
      flightType: flightType,
      center: center,
      requiredLevel: requiredLevel,
      priorityLevel: priorityLevel,
      usersEmail: usersEmail,
    });
    handleComplete();
  };

  const specificInformationsValidation = (newInformations: any) => {
    setSpecificInformations(newInformations);
    handleComplete();
  };

  const validSummaryOfRequest = async () => {
    if (props.origin === "update") {
      await updateRequest(
        globalInformations,
        specificInformations,
        kmlFileInformations.kmlFile,
        DateTime.now().toFormat("dd-MM-yyyy"),
        props.requestData.id,
        false
      )
        .then(() => {
          enqueueSnackbar(t("global.successfullUpdate"), {
            variant: "success",
          });
          handleComplete();
        })
        .catch((error) => {
          enqueueSnackbar(t("error.errorHasOccured"), {
            variant: "error",
          });
          console.error(error);
        });
    } else if (props.origin === "Project") {
      await updateRequest(
        globalInformations,
        specificInformations,
        kmlFileInformations.kmlFile,
        DateTime.now().toFormat("dd-MM-yyyy"),
        props.requestData.id,
        true
      )
        .then(() => {
          enqueueSnackbar(t("global.successfullUpdate"), {
            variant: "success",
          });
          handleComplete();
        })
        .catch((error) => {
          enqueueSnackbar(t("error.errorHasOccured"), {
            variant: "error",
          });
          console.error(error);
        });
    } else if (props.origin === "newRequestFromProject") {
      await addNewRequestFromProject(
        globalInformations,
        specificInformations,
        kmlFileInformations.kmlFile,
        DateTime.now().toFormat("dd-MM-yyyy"),
        props.requestData.id
      )
        .then(() => {
          enqueueSnackbar(t("project.successCreationOfRequestFromProjet"), {
            variant: "success",
          });
          handleComplete();
        })
        .catch((error) => {
          enqueueSnackbar(t("error.errorHasOccured"), {
            variant: "error",
          });
          console.error(error);
        });
    }
  };

  const selectKMLFileChange = (kmlFile: any, fileName: string) => {
    setKMLFileInformations({
      kmlFile: kmlFile,
      fileName: fileName,
    });
    props.changeFile(kmlFile);
  };
  const selectKMLFileValidation = () => {
    handleComplete();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    if (newActiveStep !== 0) {
      props.updateStateOnFile(false);
    }
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    if (activeStep - 1 === 0) {
      props.updateStateOnFile(true);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const createRoads = (waypoints: string[]) => {
    props.createNewRoad(waypoints);
  };
  return (
    <Box sx={{ width: "100%", marginBottom: "20px" }}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel StepIconComponent={ColorlibStepIcon} color="black">
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box
        sx={{
          overflowY: "auto",
        }}
        style={{
          height: props.height ? props.height + "vh" : "60vh",
        }}
      >
        {allStepsCompleted() ? (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>{t("global.reset")}</Button>
            </Box>
          </React.Fragment>
        ) : (
          <div style={{ margin: "15px" }}>
            <Grid sx={{ mt: 2, mb: 1, py: 1 }}></Grid>
            {activeStep === 0 && (
              <SelectKMLFile
                activeStep={activeStep}
                handleBack={handleBack}
                selectKMLFileValidation={selectKMLFileValidation}
                steps={steps}
                completed={completed}
                handleComplete={handleComplete}
                completedSteps={completedSteps}
                totalSteps={totalSteps}
                kmlFileInformations={kmlFileInformations}
                selectKMLFileChange={selectKMLFileChange}
              />
            )}

            {activeStep === 1 && (
              <GlobalInformations
                activeStep={activeStep}
                handleBack={handleBack}
                globalInformationsValidation={globalInformationsValidation}
                steps={steps}
                completed={completed}
                handleComplete={handleComplete}
                completedSteps={completedSteps}
                totalSteps={totalSteps}
                globalInformations={globalInformations}
              />
            )}
            {activeStep === 2 && (
              <SpecificInformations
                activeStep={activeStep}
                handleBack={handleBack}
                specificInformationsValidation={specificInformationsValidation}
                steps={steps}
                completed={completed}
                handleComplete={handleComplete}
                completedSteps={completedSteps}
                totalSteps={totalSteps}
                specificInformations={specificInformations}
                typeOfSpecificInformations={globalInformations.flightType}
                origin={props.origin}
                createRoads={createRoads}
              />
            )}
            {activeStep === 3 && (
              <SummaryOfUpdatedRequest
                activeStep={activeStep}
                handleBack={handleBack}
                steps={steps}
                completed={completed}
                handleComplete={handleComplete}
                validSummaryOfRequest={validSummaryOfRequest}
                completedSteps={completedSteps}
                totalSteps={totalSteps}
                specificInformations={specificInformations}
                globalInformations={globalInformations}
                origin={props.origin}
              />
            )}
            {activeStep === 4 && (
              <ConfirmNewRequest finish={props.finish} origin={props.origin} />
            )}
          </div>
        )}
      </Box>
    </Box>
  );
};

export default UpdateRequestForm;
