import { Outlet, Navigate } from "react-router-dom";
import { PATHS } from "./Routes";
import AuthenticationService from "../Service/AuthenticationService";

const PrivateAdminRoad = () => {
  const isAuthenticated = AuthenticationService.isAuthenticated;
  const role = AuthenticationService.role;
  return isAuthenticated && role === "Administrator" ? (
    <Outlet />
  ) : (
    <Navigate to={PATHS.Home} />
  );
};

export default PrivateAdminRoad;
