import { TextField, Button, Grid } from "@mui/material";
import { Formik, FormikProps, Form } from "formik";
import * as Yup from "yup";
import { forgotPassword } from "../../Service/ApiService";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

interface LostPasswordForm {
  email: string;
}

const ForgotPasswordForm = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={(values: LostPasswordForm, actions) => {
        forgotPassword(values.email)
          .then(() => {
            props.openModalValidSendEmail();
            actions.resetForm();
          })
          .catch((error) => {
            enqueueSnackbar(t("error.errorHasOccured"));
          });
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email()
          .required(t("form.enterValidEmail") || ""),
      })}
    >
      {(props: FormikProps<LostPasswordForm>) => {
        const { values, touched, errors, handleBlur, handleChange } = props;
        return (
          <Form noValidate>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  style={{ color: "white" }}
                  value={values.email}
                  helperText={errors.email && touched.email ? errors.email : ""}
                  error={errors.email && touched.email ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={12} md={8} style={{ marginTop: "8px" }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {t("global.confirm")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ForgotPasswordForm;
