import { Grid } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UserInformationsForm from "./UserInformationsForm";

const UserInformations = (props: any) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <AccountCircleIcon style={{ fontSize: "100px" }} />
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center", marginBottom: "20px" }}>
        {props.userInformations.email}
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <UserInformationsForm
          userInformations={props.userInformations}
          update={props.update}
          finishUpdate={props.finishUpdate}
        />
      </Grid>
    </Grid>
  );
};

export default UserInformations;
