import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
} from "@mui/material";
import { Formik, FormikProps, Form } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { PATHS } from "../../Router/Routes";
import { makeStyles } from "@mui/styles";
import AuthenticationService from "../../Service/AuthenticationService";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  touch: {
    "&:hover": { cursor: "pointer" },
  },
}));

interface ConnexionForm {
  email: string;
  password: string;
  remember: boolean;
}

const Singin = (props: any) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Formik
      initialValues={{
        password: "",
        email: "",
        remember: false,
      }}
      onSubmit={(values: ConnexionForm, actions) => {
        AuthenticationService.login(
          values.email,
          values.password,
          values.remember
        ).then((auth) => {
          const role = AuthenticationService.role;
          if (auth === "OK" && role === "Default") {
            navigate(PATHS.UserHome);
          } else if (auth === "OK" && role === "Administrator") {
            navigate(PATHS.AdminHome);
          } else {
            props.updateErrorContent(
              "<Typography>" +
                t("error.emailAndPasswordAreIncorrect") +
                "</Typography>"
            );
          }
        });
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email()
          .required(t("form.enterValidEmail") || ""),
        password: Yup.string()
          .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()/])/,
            t("form.passwordMatch") || ""
          )
          .min(8, t("form.passwordMinLenght") || "")
          .required(t("form.enterValidPassword") || ""),
      })}
    >
      {(props: FormikProps<ConnexionForm>) => {
        const { values, touched, errors, handleBlur, handleChange } = props;
        return (
          <Form noValidate>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  style={{ color: "white" }}
                  value={values.email}
                  helperText={errors.email && touched.email ? errors.email : ""}
                  error={errors.email && touched.email ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={values.password}
                  helperText={
                    errors.password && touched.password
                      ? "Entrez votre mot de passe"
                      : ""
                  }
                  error={errors.password && touched.password ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={8} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="remember"
                      color="primary"
                      checked={values.remember}
                      onChange={handleChange}
                    />
                  }
                  label="Remember me"
                  name="remember"
                  disabled={
                    localStorage.getItem("Cookie") === "Yes" ? false : true
                  }
                />
              </Grid>
              <Grid xs={8} md={6} style={{ marginBottom: "8px" }}>
                <Link
                  className={classes.touch}
                  variant="body2"
                  onClick={() => {
                    navigate(PATHS.ForgotPassword);
                  }}
                >
                  Forgot password ?
                </Link>
              </Grid>
              <Grid item xs={12} md={8} style={{ marginTop: "8px" }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Sign In
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Singin;
