export const english = {
  title: {
    signIn: "Sign in",
  },
  cookie: {
    title: "We uses cookies",
    description:
      "This webiste uses cookies to enchance your browsing experience.",
    accept: "Accept",
  },
  error: {
    errorHasOccured: "An error has occured, please try again",
    errorHasOccuredClearNotification:
      "An error has occured for cleaning notification",
    unableToRemoveAdministratorAccount:
      "Unable to remove the administrator account",
    unableToRetrieveAccountList: "Unable to retrieve account list",
    unableToRetrieveMapInformation: "Unable to retrieve map information",
    emailAndPasswordAreIncorrect:
      "Please check that your email and password are correct or if you are validate your email",
  },
  request: {
    successfullUpdate: "Your request has been updated",
    successfullUpdateContent:
      "It will be processed as soon as possible. <br /> You will receive a notification to inform you when the processing is finished.",
    newRequest: "New request",
    createNewRequestFromProject:
      "Do you want to create your request from a project ?",
    updateRequest: "Update request",
    submitSaveRequest: "Submit save request",
    submitWithModifications: "Submit with modifications",
    submitNow: "Submit now",
    deleteRequest: "Request successfully delete",
    submitSameRequest: "Submit same request",
    submitRequest: "Your request has been submitted",
    submitRequestWithChanges: "Your request has been submitted with changes",
    file: "File",
    global: "Global",
    specific: "Specific",
    confirm: "Confirm",
    finish: "Finish",
    flightArea: "Start by defining your flight area",
    sent: "Your request has been sent !",
    sentContent:
      " It will be processed as soon as possible. <br /> You will receive a notification to inform you when the processing is finished.",
    deleteModalTitle: "You are about to delete your request",
    deleteModalContent: " Are you sure you want to delete this request ?",
    seleteChatCenter: "Select the center you want to chat to",
    warningCloseUpdateTitle: "You are about to close the update window",
    warningCloseUpdateContent:
      " Your changes will not be taken into account.<br /> Do you really want to close the window ? ",
    warningNoKMLFileTitle: "No KML file has been entered",
    warningNoKMLFileContent:
      " Would you like to continue without adding a KML file to your application ? <br/> A modification of your application will be possible but this may increase the processing time of your application.",
    warningValidationUpdateTitle: "You are about to update your application",
    warningValidationUpdateContent:
      "This action will automatically lead to a new analysis of your request. <br /> Did you confirm your change ? ",
    warningValidationNewRequestFromProjectTitle:
      "You are about to create new request from project",
    warningValidationNewRequestFromProject:
      "This action will automatically create new request. <br /> Did you confirm the creation ? ",
    conditionallyAccept: "You are about to conditionally accept a request.",
    reject: "You are about to decline a request.",
    chat: "Chat with",
    favoriteUpdate: "Your favorite has been update",
    summary: {
      title: "Summary of your request !",
      applicantName: "Applicant name",
      referenceNumber: "Reference number",
      aircraftType: "Aircraft type",
      callSign: "Callsign",
      flightRules: "Flight rules",
      centers: "Centers",
      requiredLevel: "Required level",
      priorityLevel: "Priority",
      workDescription: "Work description",
      requestedDay: "Requested day",
      time: "Time",
      comments: "Comments",
      departureAerodrome: "Departure aerodrome",
      arrivalAerodrome: "Arrival aerodrome",
      requestedDayStart: "Requested day start",
      requestedDayEnd: "Requested day end",
      numberOfAxisToFly: "Number Of axes to fly",
      totalDurationOfMission: "Total duration of mission",
      actualDurationOfTheMission: "Actual duration of the mission",
      numberOfDrops: "Number of drops",
      filedRoad: "Filed road",
      typeOfMission: "Type of mission",
      summaryRequest: "Summary of the request",
      flightType: "Flight type",
      state: "State",
      action: "Action",
      globalInformations: "Global informations",
      currentState: "Current state",
    },
    noRequest: "No request",
    createNewRequestNow: "You can create a new request now",
    rejectButton: "Reject",
    approveUnderConditionButton: "Approve under condition",
    acceptButton: "Accept",
  },
  project: {
    newProject: "New project",
    updateProject: "Update project",
    newRequestFromProject: "New request from project",
    deleteProject: "Project successfully delete",
    submitProject: "Your project has been submitted",
    successCreationOfRequestFromProjet:
      "Successfull creation of request from project",
    noProject: "No Project",
    createNewProjectNow: "You can create a new project now",
    summaryProject: "Summary of the project",
  },
  subAccount: {
    subAccount: "Sub account",
    createNewSubAccount: "You can create a new sub account at the top",
    successAdd: "Successful addition of the user !",
    successDelete: "Successful delete of the user !",
  },
  protec: {
    protecInformationsTitle: "Definition of the protec informations",
    protecUpdate: "Protec informations has been update successfuly",
    informations: "Informations",
    protec: "Protec",
  },
  form: {
    passwordMatch:
      "Minimum 1 upper case, 1 lower case, 1 number and 1 special character.",
    passwordMinLenght: "8 characters minimum",
    enterValidPassword: "Enter a valid password",
    passwordsMustMatch: "Passwords must match",
    enterValidCheckPassword: "Enter a valid check password",
    enterValidEmail: "Enter a valid email",
    enterValidRequiredLevel: "Enter a valid required level",
    enterValidWorkDescription: "Enter a valid work description",
    enterValidstartTime: "Enter a valid start time",
    enterValidendTime: "Enter a valid end time",
    enterValidDepartureAerodrome: "Enter a valid departure aerodrome",
    enterValidArrivalAerodrome: "Enter a valid arrival aerodrome",
    enterValidAxesToFly: "Enter a valid number of axes to fly",
    enterValidTotalDurationOfMission:
      "Enter a valid total duration of the mission",
    enterValidActualDurationOfMission:
      "Enter a valid actual duration of the mission",
    enterValidFiledRoad: "Enter a valid filed road",
    enterValidTypeOfMission: "Enter a valid type of mission",
    enterValidDescription: "Enter a valid description",
    enterValidAircraftType: "Enter a valid aircraft type",
    enterValidReferenceNumber: "Enter a valid reference number",
    enterValidCallsign: "Enter a valid callsign",
    enterValidFlightRules: "Enter a valid rules",
    enterValidFlightType: "Enter a valid flight type",
    enterValidCenter: "Enter a valid center",
    enterValidCompanyName: "Enter a valid company name",
    enterValidFavoriteFlightType: "Enter a valid favorite flight type",
    enterValidFirstName: "Enter a valid first name",
    enterValidName: "Enter a valid name",
    minCenter: "Choice at minimum 1 center",
    addUser: "Add user",
    numberOfDays: "Number of days",
    moreDays: "More days",
  },
  global: {
    confirm: "Confirm",
    update: "Update",
    delete: "Delete",
    reset: "Reset",
    successfullUpdate: "Successful update",
    back: "Back",
    next: "Next",
    step: "Step",
    alreadyCompleted: "already completed",
    save: "Save",
    undo: "Undo",
    home: "Home",
    ok: "Ok",
    noSolution: "No solution found for local filter ...",
    tryAgain: "Try with an others parameters",
    updateOfSuccessStatus: "Update of success status",
    successfulUpdate: "Successful update",
  },
  navBar: {
    inProgress: "In progress / pending",
    saved: "Saved",
    projects: "Projects",
    account: "Account",
    settings: "Settings",
    newRequest: "New request",
    logout: "Logout",
    all: "All",
    completed: "Completed",
    onHold: "On hold",
    favorite: "Favorite",
    userManagement: "User management",
    statistics: "Statistics",
  },
  register: {
    register: "Register",
    confirmEmailTitle: "Thank you for validating your email",
    confirmEmailContent:
      "Before you can fully enjoy our interface, an administrator must confirm your registration. <br/> An email will be sent to you to confirm the final activation of your account.",
    resetYourPassword: "Reset your password",
    sentEmail: "An email has been sent to you",
    forgotPassword: "Forgot password",
    backToLogin: "Back to login",
    user: "User",
    company: "Company",
    choosePassword: "Choose password",
    confirmRegistration: "Confirm registration",
    updatePasswordSuccessTitle: "Password change successful ! ",
    updatePasswordSuccessContent:
      "Your new password has been successfully saved. <br/> You can now log in with it.",
    confirmRegisterTitle: "Thank you for registering !",
    confirmRegisterContent:
      "Your request to create an account has been taken care of. <br /> You will receive a confirmation email, please click on the link to validate your account.<br /> An administrator will then assign your permissions.",
    createFirstPasswordTitleSuccess: "Create your successful password !",
    createFirstPasswordContentSuccess:
      " Your password has been registered. <br /> You can now authenticate yourself.",
    createFirstPasswordTitle: "Create your first password",
  },
  account: {
    accountManagement: "Account management",
    newUser: "New user",
    manage: "Manage",
    saccount: "'s account",
    updateRole: "Update role of",
    validationAccount: "Validation of",
    account: "account",
    warningUserDontValidEmail:
      "* The user has not validated his email. If you accept the user his email will be automatically validated",
    firstName: "First name",
    name: "Name",
    companyName: "Company name",
    center: "Center",
    favoriteFlightType: "Favorite flight type",
    phoneNumber: "Phone number",
    emailValidation: "Email validation",
    adminValidation: "Admin validation",
    role: "Role",
    noUser: "No user",
    createNewAdmin: "You can create a new administrator at the top",
  },
  settings: {
    settings: "Settings",
    faq: "FAQ",
    generalSettings: "General settings",
    version: "Version",
    userManual: "User manual",
    legal: "Legal",
    privacyPolicy: "Privacy policy",
    termsAndConditions: "Terms and conditions",
    support: "Support",
  },
  statistics: {
    repartitionOfAcceptancesAndRejections:
      "Distribution of acceptances and rejections among applicants",
    overallDistributionAcceptances: "Overall distribution of acceptances",
    distributionOfApplicant: "% distribution by applicant",
    applicant: "Applicant",
  },
  newUser: "New user",
};
