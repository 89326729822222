import { useCallback, useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, GeoJSON, LayersControl } from "react-leaflet";
import { Grid, Skeleton } from "@mui/material";
import L from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import LayerControle from "./LayerControle";
import { hashString, onEachSector, style } from "../../ShareFonction/map";

const AdminMap = (props: any) => {
  const geoJsonLine: any = useRef(null);

  const [loading, setLoading] = useState(false);
  const [kmlFile, setKMLFile] = useState(null as any);
  const [layers, setLayers] = useState([] as any);
  const [sectors, setSectors] = useState(null as any);
  const [line, setLine] = useState(null as any);

  useEffect(() => {
    setLoading(false);
    if (props.file !== null) {
      setKMLFile(props.file);
      setLayers(props.mapInformations.layer);
      setSectors(props.mapInformations.sector);
      setLine(props.mapInformations.line);
    } else if (props.mapInformations.line) {
      setLayers(props.mapInformations.layer);
      setSectors(props.mapInformations.sector);
      setLine(props.mapInformations.line);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.file, props.mapInformations]);

  const pointToLayer = useCallback((feature: any, latlng: any) => {
    if (feature.properties && feature.properties.icon) {
      return L.marker(latlng, {
        icon: L.icon({
          iconUrl: feature.properties.icon,
          iconSize: new L.Point(20, 20),
        }),
        title: feature.properties.name ? feature.properties.name : " ",
      });
      // .bindTooltip(feature.properties.name ? feature.properties.name : " ", {
      //   permanent: true,
      //   direction: "bottom",
      //   className: "transparent-tooltip",
      //   offset: [0, -8],
      // });
    }

    return L.marker(latlng, {
      icon: L.icon({
        iconUrl: markerIconPng,
        iconSize: new L.Point(15, 15),
      }),
    });
  }, []);

  return (
    <div>
      {!loading && (
        <MapContainer
          style={{ height: props.height + "vh", width: "100%" }}
          zoom={5}
          center={[48.866667, 2.333333]}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {kmlFile && sectors && (
            <GeoJSON
              key={hashString(JSON.stringify(kmlFile))}
              data={kmlFile}
              style={style}
              pointToLayer={pointToLayer}
            />
          )}
          {line && (
            <GeoJSON
              ref={geoJsonLine}
              data={line}
              style={style}
              pointToLayer={pointToLayer}
            />
          )}
          {sectors &&
            sectors.map((sector: any) => {
              return (
                <>
                  <GeoJSON
                    key={sector.id}
                    data={sector.backgroundArea}
                    style={{
                      color:
                        sector.center === "LFEE"
                          ? "blue"
                          : sector.center === "LFFF"
                          ? "green"
                          : sector.center === "LFRR"
                          ? "brown"
                          : sector.center === "LFMM"
                          ? "white"
                          : "orange",
                    }}
                    onEachFeature={(s, layer) =>
                      onEachSector(s, layer, sector.name)
                    }
                  />
                </>
              );
            })}
          <LayersControl position="topright">
            <LayerControle kmlFile={kmlFile} layers={layers} />
          </LayersControl>
        </MapContainer>
      )}

      {loading && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          style={{ height: props.height + "vh", width: "100%" }}
          sx={{ display: "flex" }}
        >
          <Skeleton
            variant="rectangular"
            animation="wave"
            style={{ height: props.height + "vh", width: "100%" }}
          ></Skeleton>
        </Grid>
      )}
    </div>
  );
};

export default AdminMap;
