import axios from "axios";
import * as sha512 from "js-sha512";
import AuthenticationService from "./AuthenticationService";

// Fichier de définition des fonctions se connectant à l'API.

const api_url = process.env.REACT_APP_API_URL || "";

export async function login(email: string, password: string) {
  const hashPassword = sha512.sha512(password);
  const payload = { email: email, password: hashPassword };
  const response = await axios.post(api_url + "signIn", payload);
  return response.data;
}

export async function register(
  email: string,
  firstName: string,
  name: string,
  company: string,
  favoriteFlightType: string,
  phoneNumber: string,
  password: string
) {
  var hashPassword = sha512.sha512(password);

  const payload = {
    email: email,
    firstName: firstName,
    name: name,
    companyName: company,
    favoriteFlightType: favoriteFlightType,
    phoneNumber: phoneNumber,
    password: hashPassword,
  };
  const response = await axios.post(api_url + "register", payload);
  return response.data;
}

export async function confirmEmail(token: string) {
  const payload = {
    token: token,
  };
  const response = await axios.post(api_url + "confirmEmail", payload);
  return response.data;
}

export async function forgotPassword(email: string) {
  const payload = {
    email: email,
  };
  const response = await axios.get(api_url + "lostPassword", {
    params: payload,
  });
  return response.data;
}

export async function changePassword(token: string, newPassword: string) {
  var hashPassword = sha512.sha512(newPassword);

  const payload = {
    token: token,
    password: hashPassword,
  };
  const response = await axios.post(api_url + "changePassword", payload);
  return response.data;
}

export async function listRequest(
  email: string,
  token: string,
  futurRequest: boolean,
  isProject: boolean
) {
  const payload = {
    userEmail: email,
    token: token,
    typeOfRequest: "processing",
    futurRequest: futurRequest,
    project: isProject,
  };
  const response = await axios.get(api_url + "listRequest", {
    params: payload,
  });

  return response.data;
}

export async function signOut(email: string, token: string) {
  const payload = {
    userEmail: email,
    token: token,
  };
  const response = await axios.get(api_url + "signOut", {
    params: payload,
  });
  return response.data;
}

export async function remember(email: string, token: string) {
  const payload = {
    userEmail: email,
    email: email,
    token: token,
  };
  const response = await axios.post(api_url + "remember", payload);
  return response.data;
}

export async function addNewRequest(
  globalInformations: any,
  specifiqueInformations: any,
  KMLFile: any,
  sendingTime: string
) {
  const payload = {
    applicantName: globalInformations.applicantName,
    centers: globalInformations.center,
    callsign: globalInformations.callsign,
    aircraftType: globalInformations.aircraftType,
    flightRules: globalInformations.flightRules,
    flightType: globalInformations.flightType,
    requiredLevel: globalInformations.requiredLevel,
    priorityLevel: globalInformations.priorityLevel.toString(),
    referenceNumber: globalInformations.referenceNumber,
    specificInformations: JSON.stringify(specifiqueInformations),
    KMLFile: KMLFile,
    sendingTime: sendingTime,
    isProject: false,
    usersEmail: globalInformations.usersEmail,
    token: AuthenticationService.token,
    userEmail: AuthenticationService.email,
    role: AuthenticationService.role,
  };
  const response = await axios.post(api_url + "newRequest", payload);
  return response.data;
}

export async function addNewRequestFromProject(
  globalInformations: any,
  specifiqueInformations: any,
  KMLFile: any,
  sendingTime: string,
  projectId: string
) {
  const payload = {
    applicantName: globalInformations.applicantName,
    centers: globalInformations.center,
    callsign: globalInformations.callsign,
    aircraftType: globalInformations.aircraftType,
    flightRules: globalInformations.flightRules,
    flightType: globalInformations.flightType,
    referenceNumber: globalInformations.referenceNumber,
    requiredLevel: globalInformations.requiredLevel,
    priorityLevel: globalInformations.priorityLevel.toString(),
    specificInformations: JSON.stringify(specifiqueInformations),
    KMLFile: KMLFile,
    sendingTime: sendingTime,
    isProject: false,
    projectId: projectId,
    usersEmail: globalInformations.usersEmail,
    token: AuthenticationService.token,
    userEmail: AuthenticationService.email,
    role: AuthenticationService.role,
  };
  const response = await axios.post(api_url + "newRequest", payload);
  return response.data;
}

export async function addNewProject(
  globalInformations: any,
  specifiqueInformations: any,
  KMLFile: any,
  sendingTime: string
) {
  const payload = {
    applicantName: globalInformations.applicantName,
    centers: globalInformations.center,
    callsign: globalInformations.callsign,
    aircraftType: globalInformations.aircraftType,
    flightRules: globalInformations.flightRules,
    flightType: globalInformations.flightType,
    requiredLevel: globalInformations.requiredLevel,
    priorityLevel: globalInformations.priorityLevel.toString(),
    referenceNumber: globalInformations.referenceNumber,
    specificInformations: JSON.stringify(specifiqueInformations),
    KMLFile: KMLFile,
    sendingTime: sendingTime,
    isProject: true,
    usersEmail: globalInformations.usersEmail,
    userEmail: AuthenticationService.email,
    token: AuthenticationService.token,
    role: AuthenticationService.role,
  };
  const response = await axios.post(api_url + "newRequest", payload);
  return response.data;
}
export async function getRequest(
  requestId: string,
  email: string,
  token: string
) {
  const payload = {
    requestId: requestId,
    userEmail: email,
    token: token,
  };
  const response = await axios.get(api_url + "getRequest", {
    params: payload,
  });
  return response.data;
}

export async function updateRequest(
  globalInformations: any,
  specifiqueInformations: any,
  KMLFile: any,
  updateTime: string,
  requestId: string,
  isProject: boolean
) {
  const payload = {
    requestId: requestId,
    applicantName: globalInformations.applicantName,
    centers: globalInformations.center,
    callsign: globalInformations.callsign,
    aircraftType: globalInformations.aircraftType,
    flightRules: globalInformations.flightRules,
    flightType: globalInformations.flightType,
    requiredLevel: globalInformations.requiredLevel,
    priorityLevel: parseInt(globalInformations.priorityLevel),
    referenceNumber: globalInformations.referenceNumber,
    specificInformations: JSON.stringify(specifiqueInformations),
    referenceToKMLFile: KMLFile,
    updateTime: updateTime,
    usersEmail: globalInformations.usersEmail,
    token: AuthenticationService.token,
    userEmail: AuthenticationService.email,
    isProject: isProject,
    role: AuthenticationService.role,
  };
  const response = await axios.post(api_url + "updateRequest", payload);
  return response.data;
}

export async function deleteRequest(
  requestId: string,
  centers: string[],
  applicantName: string,
  referenceNumber: string,
  time: string[],
  centerName: string[]
) {
  const payload = {
    requestId: requestId,
    userEmail: AuthenticationService.email,
    token: AuthenticationService.token,
    linkCenterId: centers,
    applicantName: applicantName,
    reference: referenceNumber,
    time: time,
    listOfCenterName: centerName,
  };
  const response = await axios.post(api_url + "deleteRequest", payload);
  return response.data;
}

export async function submitSaveRequest(requestId: string, updateTime: string) {
  const payload = {
    requestId: requestId,
    updateTime: updateTime,
    userEmail: AuthenticationService.email,
    token: AuthenticationService.token,
    role: AuthenticationService.role,
  };
  const response = await axios.get(api_url + "submitSaveRequest", {
    params: payload,
  });
  return response.data;
}

export async function listCompletedRequest(
  email: string,
  token: string,
  center: string
) {
  const payload = {
    userEmail: email,
    token: token,
    center: center,
    futurRequest: false,
    project: false,
  };
  const response = await axios.get(api_url + "listCompletedRequest", {
    params: payload,
  });
  return response.data;
}

export async function listDeletedRequest(email: string, token: string) {
  const payload = {
    userEmail: email,
    token: token,
    typeOfRequest: "deleted",
  };
  const response = await axios.get(api_url + "listRequest", {
    params: payload,
  });
  return response.data;
}

export async function listAllRequestOfCenter(
  center: string,
  email: string,
  token: string
) {
  const payload = {
    userEmail: email,
    token: token,
    center: center,
    futurRequest: true,
    project: false,
  };
  const response = await axios.get(api_url + "listAllRequest", {
    params: payload,
  });
  return response.data;
}

export async function listAllProjectOfCenter(
  center: string,
  email: string,
  token: string
) {
  const payload = {
    userEmail: email,
    token: token,
    center: center,
    futurRequest: false,
    project: true,
  };
  const response = await axios.get(api_url + "listAllRequest", {
    params: payload,
  });
  return response.data;
}

export async function listUser(email: string, token: string) {
  const payload = {
    userEmail: email,
    token: token,
  };
  const response = await axios.get(api_url + "listUser", {
    params: payload,
  });
  return response.data;
}

export async function listOnHoldRequest(
  center: string,
  email: string,
  token: string
) {
  const payload = {
    userEmail: email,
    token: token,
    center: center,
    futurRequest: false,
    project: false,
  };

  const response = await axios.get(api_url + "listOnHoldRequest", {
    params: payload,
  });
  return response.data;
}

export async function adminConfirmRegister(
  role: string,
  reason: string,
  answer: string,
  email: string,
  token: string,
  userEmail: string
) {
  const payload = {
    userEmail: userEmail,
    token: token,
    role: role,
    reason: reason,
    answer: answer,
    email: email,
  };
  const response = await axios.post(api_url + "adminConfirmRegister", payload);
  return response.data;
}

export async function updateUserRole(
  role: string,
  email: string,
  token: string,
  userEmail: string
) {
  const payload = {
    userEmail: userEmail,
    token: token,
    email: email,
    role: role,
  };
  const response = await axios.get(api_url + "updateUserRole", {
    params: payload,
  });
  return response.data;
}

export async function inspectRequest(
  requestId: string,
  reason: string,
  state: string,
  email: string,
  token: string,
  userEmail: string,
  linkCenterId: string,
  isProject: boolean,
  role: string
) {
  const payload = {
    userEmail: userEmail,
    token: token,
    requestId: requestId,
    reason: reason,
    state: state,
    email: email,
    linkCenterId: linkCenterId,
    isProject: isProject,
    role: role,
  };
  const response = await axios.post(api_url + "inspectRequest", payload);
  return response.data;
}

export async function getMessage(
  requestId: string,
  token: string,
  userEmail: string,
  center: string
) {
  const payload = {
    userEmail: userEmail,
    token: token,
    requestId: requestId,
    center: center,
  };
  const response = await axios.get(api_url + "getMessage", { params: payload });
  return response.data;
}

export async function newMessage(
  requestId: string,
  content: string,
  sendingTime: string,
  token: string,
  userEmail: string,
  from: string,
  to: string,
  isProject: boolean,
  userInformations: string,
  role: string
) {
  const payload = {
    userEmail: userEmail,
    token: token,
    requestId: requestId,
    content: content,
    sendingTime: sendingTime,
    from: from,
    to: to,
    isProject: isProject,
    userInformations: userInformations,
    role: role,
  };
  const response = await axios.post(api_url + "addMessage", payload);
  return response.data;
}

export async function getNotification(
  userEmail: string,
  token: string,
  target: string
) {
  const payload = {
    userEmail: userEmail,
    token: token,
    target: target,
  };
  const response = await axios.get(api_url + "getNotification", {
    params: payload,
  });
  return response.data;
}

export async function clearNotification(
  type: string,
  origin: string,
  requestId: string,
  ids: string[],
  userEmail: string,
  token: string,
  role: string
) {
  const payload = {
    userEmail: userEmail,
    token: token,
    type: type,
    origin: origin,
    requestId: requestId,
    ids: ids,
    role: role,
  };
  const response = await axios.get(api_url + "clearNotification", {
    params: payload,
  });
  return response.data;
}

export async function getUserInformations(userEmail: string, token: string) {
  const payload = {
    userEmail: userEmail,
    token: token,
    email: userEmail,
  };
  const response = await axios.get(api_url + "getUserInformations", {
    params: payload,
  });
  return response.data;
}

export async function updateUserInformations(
  name: string,
  firstName: string,
  phoneNumber: string,
  companyName: string,
  favoriteFlightType: string,
  token: string,
  userEmail: string
) {
  const payload = {
    userEmail: userEmail,
    email: userEmail,
    token: token,
    name: name,
    firstName: firstName,
    favoriteFlightType: favoriteFlightType,
    phoneNumber: phoneNumber,
    companyName: companyName,
  };
  const response = await axios.post(
    api_url + "updateUserInformations",
    payload
  );
  return response.data;
}

export async function updateAdminInformations(
  name: string,
  firstName: string,
  phoneNumber: string,
  center: string,
  token: string,
  userEmail: string
) {
  const payload = {
    userEmail: userEmail,
    email: userEmail,
    token: token,
    name: name,
    firstName: firstName,
    phoneNumber: phoneNumber,
    center: center,
  };
  const response = await axios.post(
    api_url + "updateUserInformations",
    payload
  );
  return response.data;
}

export async function addUserByAdmin(
  name: string,
  firstName: string,
  phoneNumber: string,
  center: string,
  email: string,
  token: string,
  userEmail: string
) {
  const payload = {
    userEmail: userEmail,
    email: email,
    token: token,
    name: name,
    firstName: firstName,
    phoneNumber: phoneNumber,
    center: center,
  };
  const response = await axios.post(api_url + "addUserByAdmin", payload);
  return response.data;
}

export async function createFirstPassword(token: string, newPassword: string) {
  var hashPassword = sha512.sha512(newPassword);

  const payload = {
    token: token,
    password: hashPassword,
  };
  const response = await axios.post(api_url + "createFirstPassword", payload);
  return response.data;
}

export async function getSectorFile(userEmail: string, token: string) {
  try {
    const response = await axios.get(
      "https://4me-atm.com/dataset-explorer/datasets/20230309.json"
    );
    return response.data;
  } catch (error) {
    console.error(error);
    const payload = {
      userEmail: userEmail,
      token: token,
      fileName: "20230118.json",
    };
    const response = await axios.get(api_url + "getFile", {
      params: payload,
    });
    return response.data;
  }
}

export async function getNavFixFile(userEmail: string, token: string) {
  const payload = {
    userEmail: userEmail,
    token: token,
  };
  const response = await axios.get(api_url + "getBalisesFile", {
    params: payload,
  });
  return response.data;
}
export async function getSectorLevelFile(userEmail: string, token: string) {
  const payload = {
    userEmail: userEmail,
    token: token,
    fileName: "SIA/controlLevel.json",
  };
  const response = await axios.get(api_url + "getFile", {
    params: payload,
  });
  return response.data;
}

export async function getRoadsFile(userEmail: string, token: string) {
  const payload = {
    userEmail: userEmail,
    token: token,
  };
  const response = await axios.get(api_url + "getRoadsFile", {
    params: payload,
  });
  return response.data;
}

export async function getFile(
  fileName: string,
  userEmail: string,
  token: string
) {
  const payload = {
    fileName: fileName,
    userEmail: userEmail,
    token: token,
  };
  const response = await axios.get(api_url + "getFile", {
    params: payload,
  });
  return response.data;
}

export async function updateFavoriteFilter(
  token: string,
  email: string,
  newFilter: string
) {
  const payload = {
    token: token,
    userEmail: email,
    email: email,
    newFilter: newFilter,
  };
  const response = await axios.post(api_url + "updateFavoriteFilter", payload);
  return response.data;
}
export async function updateProtecInformations(
  token: string,
  email: string,
  newProtecInformations: any,
  idProtec: string
) {
  const payload = {
    token: token,
    userEmail: email,
    email: email,
    newProtecInformations: JSON.stringify(newProtecInformations),
    idProtec: idProtec,
  };
  const response = await axios.post(
    api_url + "updateProtecInformations",
    payload
  );
  return response.data;
}

export async function addUnderAccount(
  name: string,
  firstName: string,
  phoneNumber: string,
  email: string,
  token: string,
  userEmail: string,
  company: string,
  favoriteFlightType: string
) {
  const payload = {
    userEmail: userEmail,
    email: email,
    token: token,
    name: name,
    firstName: firstName,
    phoneNumber: phoneNumber,
    company: company,
    favoriteFlightType: favoriteFlightType,
    mainAccount: userEmail,
  };
  const response = await axios.post(api_url + "addUnderAccount", payload);
  return response.data;
}

export async function deleteSubAccount(
  email: string,
  token: string,
  userEmail: string
) {
  const payload = {
    userEmail: userEmail,
    email: email,
    token: token,
  };
  const response = await axios.post(api_url + "deleteUnderAccount", payload);
  return response.data;
}

export async function listUnderAccount(
  userEmail: string,
  token: string,
  companyName: string
) {
  const payload = {
    userEmail: userEmail,
    token: token,
    email: userEmail,
    companyName: companyName,
  };
  const response = await axios.get(api_url + "listUnderAccount", {
    params: payload,
  });
  return response.data;
}

export async function listAllUserOfCompany(
  userEmail: string,
  token: string,
  companyName: string
) {
  const payload = {
    userEmail: userEmail,
    token: token,
    email: userEmail,
    companyName: companyName,
  };
  const response = await axios.get(api_url + "listAllUserOfCompany", {
    params: payload,
  });
  return response.data;
}

export async function listUserCompletedRequest(
  email: string,
  token: string,
  center: string
) {
  const payload = {
    userEmail: email,
    token: token,
    center: center,
    futurRequest: false,
    project: false,
  };
  const response = await axios.get(api_url + "listCompletedRequestOfUser", {
    params: payload,
  });
  return response.data;
}

export async function statisticsByCompany(
  email: string,
  token: string,
  center: string
) {
  const payload = {
    userEmail: email,
    token: token,
    center: center,
  };
  const response = await axios.get(api_url + "statisticsByCompany", {
    params: payload,
  });
  return response.data;
}
