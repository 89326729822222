import { Grid, Button } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { addNewRequest, deleteRequest } from "../../../../Service/ApiService";
import formatingTime from "../../../ShareFonction/FormattingTime";
import DeleteRequest from "../../Modal/DeleteRequest";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const ListOfButtonsUpdate = (props: any) => {
  const [deleteRequestConfirmation, setDeleteRequestConfirmation] =
    useState(false);
  const closeDeleteRequest = () => {
    setDeleteRequestConfirmation(false);
  };
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const confirmDeleteRequest = () => {
    const centerName: string[] = [];
    const centers = props.requestData.globalInformations.center.map(
      (center: any) => {
        if (center.state === "accepted" || center.state === "acceptedIf")
          centerName.push(center.center);
        return center.id;
      }
    );
    const time = formatingTime(
      props.requestData.specificInformations,
      props.requestData.globalInformations.flightType
    );
    deleteRequest(
      props.requestData.id,
      centers,
      props.requestData.globalInformations.applicantName,
      props.requestData.globalInformations.referenceNumber,
      time,
      centerName
    )
      .then(() => {
        enqueueSnackbar(t("request.deleteRequest"), {
          variant: "success",
        });
        setDeleteRequestConfirmation(false);
        props.reSubmitRequest();
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error(error);
      });
  };
  return (
    <>
      <Grid
        container
        alignItems="center"
        alignContent="center"
        justifyContent="center"
      >
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            const centers = props.requestData.globalInformations.center.map(
              (center: any) => {
                return center.center;
              }
            );
            props.requestData.globalInformations.center = centers;
            addNewRequest(
              props.requestData.globalInformations,
              props.requestData.specificInformations,
              props.requestData.referenceToKMLFile,
              DateTime.now().toFormat("dd-mm-yyyy")
            )
              .then(() => {
                enqueueSnackbar("Creation of the new request", {
                  variant: "success",
                });
                props.reSubmitRequest();
              })
              .catch((error) => {
                enqueueSnackbar("An error has occured", {
                  variant: "error",
                });
                console.error(error);
              });
          }}
          style={{ marginRight: "10px" }}
        >
          {t("request.submitSameRequest")}
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{ marginRight: "10px" }}
          onClick={() => {
            props.updateRequest();
          }}
        >
          {t("global.update")}
        </Button>
        <Button
          variant="contained"
          size="small"
          color="error"
          onClick={() => {
            setDeleteRequestConfirmation(true);
          }}
        >
          {t("global.delete")}
        </Button>
      </Grid>
      <DeleteRequest
        open={deleteRequestConfirmation}
        closeModal={closeDeleteRequest}
        confirmDeleteRequest={confirmDeleteRequest}
      />
    </>
  );
};

export default ListOfButtonsUpdate;
