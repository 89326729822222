import * as React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  gridClasses,
  GridToolbar,
} from "@mui/x-data-grid";
import { useEffect, useRef } from "react";
import AuthenticationService from "../../Service/AuthenticationService";
import { Badge, Button, Grid, Typography, alpha, styled } from "@mui/material";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import { DateTime } from "luxon";
import ChatIcon from "@mui/icons-material/Chat";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import getAllDatesBeetweenStartAndEnd from "../ShareFonction/ListAllDaysOfSchedule";
import sortTimeDataGrid from "../ShareFonction/SortTimeDataGrid";
import sortStateDataGrid from "../ShareFonction/sortStateDataGrid";
import { ProcessingGetRequest } from "../ShareFonction/ProcessignGetRequest";
import { Add, Public } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import LoadSummaryRequest from "./Modal/LoadSummaryRequest";
import { useSnackbar } from "notistack";
const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const GlobalDataGrid = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const playNotif = () => {
    const audio = new Audio("https://lasonotheque.org/UPLOAD/mp3/0127.mp3");
    var resp = audio.play();

    if (resp !== undefined) {
      resp
        .then((_) => {
          // autoplay starts!
        })
        .catch((error) => {
          //show error
        });
    }
  };

  useEffect(() => {
    setLoadGetRequest(false);
  }, [props.endLoadGetRequest]);

  useEffect(() => {
    setLoading(true);
    props.listOfRequest().then((newData: any) => {
      setData(newData);
      setLoading(false);
    });

    const s = setInterval(() => {
      props.listOfRequest().then((newData: any) => {
        AuthenticationService.updateNotification().then((update) => {
          if (update) {
            props.updateNotification();
            playNotif();
          }
          setData(newData);
        });
      });
    }, 10000);

    return () => clearInterval(s);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.update]);

  const [data, setData] = React.useState([] as any);
  const [loading, setLoading] = React.useState(true);
  const [loadGetRequest, setLoadGetRequest] = React.useState(false);

  const columns: GridColDef[] = [
    {
      field: "Notif",
      headerName: "",
      width: 0,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        const renderBadgeContent = (): any => {
          let nombre: any = 0;
          AuthenticationService.notification.request.forEach((request: any) => {
            if (
              params.id === request.requestId &&
              JSON.parse(request.typeModification).type !== "addMessageRequest"
            ) {
              nombre += 1;
            }
          });
          AuthenticationService.notification.project.forEach((project: any) => {
            if (
              params.id === project.requestId &&
              JSON.parse(project.typeModification).type !== "addMessageProject"
            ) {
              nombre += 1;
            }
          });
          return nombre;
        };
        return (
          <Badge
            color="secondary"
            badgeContent={renderBadgeContent()}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          ></Badge>
        );
      },
    },
    {
      field: "applicantName",
      headerName: t("request.summary.applicantName") || "",
      width: 180,
      editable: false,
      hide: AuthenticationService.role === "Administrator" ? false : true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "referenceNumber",
      headerName: t("request.summary.referenceNumber") || "",
      width: 200,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "aircraftType",
      headerName: t("request.summary.aircraftType") || "",
      width: 200,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "callsign",
      headerName: t("request.summary.callSign") || "",
      type: "string",
      width: 150,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "flightRules",
      headerName: t("request.summary.flightRules") || "",
      type: "string",
      width: 150,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "flightType",
      headerName: t("request.summary.flightType") || "",
      type: "string",
      width: 150,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "requiredLevel",
      headerName: t("request.summary.requiredLevel") || "",
      type: "number",
      width: AuthenticationService.language === "en" ? 120 : 140,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "priorityLevel",
      headerName: t("request.summary.priorityLevel") || "",
      type: "number",
      width: 80,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "time",
      headerName: t("request.summary.time") || "",
      type: "string",
      width: 180,
      editable: false,
      align: "center",
      hide: props.origin === "Project" ? true : false,
      valueGetter: (params) => ({
        specificInformations: params.row.specificInformations,
        flightType: params.row.flightType,
      }),
      headerAlign: "center",
      renderCell: (params) => {
        const displayTransformation = () => {
          const specificInformations = JSON.parse(
            params.row.specificInformations
          );
          if (
            params.row.flightType === "Test flight" ||
            params.row.flightType === "Scientific flight" ||
            params.row.flightType === "Calibration" ||
            params.row.flightType === "Photo mission"
          ) {
            return (
              <>
                <Typography>
                  {DateTime.fromISO(specificInformations.requestedDay).toFormat(
                    "dd/MM"
                  ) +
                    " : " +
                    DateTime.fromISO(specificInformations.startTime).toFormat(
                      "HH:mm"
                    ) +
                    " - " +
                    DateTime.fromISO(specificInformations.endTime).toFormat(
                      "HH:mm"
                    )}
                </Typography>
              </>
            );
          } else if (params.row.flightType === "Media coverage") {
            const start: DateTime = DateTime.fromISO(
              specificInformations.requestedDayStart
            );
            const end: DateTime = DateTime.fromISO(
              specificInformations.requestedDayEnd
            );

            const dayDiff = Math.round(end.diff(start, "days").days) + 1;
            const newScheduleDays = getAllDatesBeetweenStartAndEnd(
              dayDiff,
              start
            );
            return (
              <Grid container>
                {specificInformations.schedule.map(
                  (time: string[], index: number) => {
                    return (
                      <Grid item xs={12}>
                        <Typography>
                          {newScheduleDays[index] +
                            " : " +
                            DateTime.fromISO(time[0]).toFormat("HH:mm") +
                            " - " +
                            DateTime.fromISO(time[1]).toFormat("HH:mm")}
                        </Typography>
                      </Grid>
                    );
                  }
                )}
              </Grid>
            );
          } else if (
            params.row.flightType === "Various" ||
            params.row.flightType === "Skydiving"
          ) {
            return (
              <Grid container>
                {specificInformations.requestedDays.map(
                  (requestedDay: string, index: number) => {
                    return (
                      <Grid item xs={12}>
                        <Typography>
                          {DateTime.fromISO(requestedDay).toFormat("dd/MM") +
                            " : " +
                            DateTime.fromISO(
                              specificInformations.schedule[index][0]
                            ).toFormat("HH:mm") +
                            " - " +
                            DateTime.fromISO(
                              specificInformations.schedule[index][1]
                            ).toFormat("HH:mm")}
                        </Typography>
                      </Grid>
                    );
                  }
                )}
              </Grid>
            );
          }
        };

        return (
          <>
            {displayTransformation()}
            {/* {JSON.parse(params.row.specificInformations).schedule} */}
          </>
        );
      },
      sortComparator: (v1, v2) => {
        return sortTimeDataGrid(v1, v2);
      },
      sortingOrder: ["asc", "desc"],
    },
    {
      field: "state",
      headerName: t("request.summary.state") || "",
      type: "string",
      width: AuthenticationService.role === "Administrator" ? 120 : 200,
      hide:
        props.origin === "save" || props.origin === "Completed" ? true : false,
      editable: false,
      valueGetter: (params) => ({
        state: params.row.state,
        centers: params.row.centers,
      }),
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            {AuthenticationService.role !== "Administrator" &&
              params.row.centers.map((center: any, index: number) => {
                return (
                  <>
                    {params.row.state !== "saved" &&
                      params.row.state !== "deleted" && (
                        <>
                          <Grid item xs={7}>
                            <Typography style={{ margin: "5px" }}>
                              {center.center}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip title={center.state}>
                              <div
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "20px",
                                  background:
                                    center.state === "acceptedIf"
                                      ? "#A0B9D9"
                                      : center.state === "rejected"
                                      ? "#F28585"
                                      : center.state === "accepted"
                                      ? "#B4CF66"
                                      : "#F2B263",
                                  marginRight: "5px",
                                }}
                              ></div>
                            </Tooltip>
                          </Grid>
                        </>
                      )}
                    {(params.row.state === "saved" ||
                      params.row.state === "deleted") && (
                      <Grid item xs={10}>
                        <Typography style={{ margin: "5px" }}></Typography>
                      </Grid>
                    )}
                  </>
                );
              })}
            {AuthenticationService.role === "Administrator" && (
              <Tooltip title={params.row.centers.state}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "20px",
                    background:
                      params.row.centers.state === "acceptedIf"
                        ? "#A0B9D9"
                        : params.row.centers.state === "rejected"
                        ? "#F28585"
                        : params.row.centers.state === "accepted"
                        ? "#B4CF66"
                        : "#F2B263",
                  }}
                ></div>
              </Tooltip>
            )}
          </Grid>
        );
      },
      sortComparator: (v1, v2) => {
        // processing = 1 ; mixed : 0 ; other = -1
        var statev1 = 1;
        var statev2 = 1;
        if (v1.state === "processing") {
          v1.centers.forEach((center: any) => {
            if (center.state !== "processing") {
              statev1 = 0;
            }
          });
        } else {
          statev1 = -1;
        }
        if (v2.state === "processing") {
          v2.centers.forEach((center: any) => {
            if (center.state !== "processing") {
              statev2 = 0;
            }
          });
        } else {
          statev2 = -1;
        }

        return sortStateDataGrid(statev1, statev2);
      },
      sortingOrder: ["asc", "desc"],
    },
    {
      field: "action",
      headerName: t("request.summary.action") || "",
      sortable: false,
      width: 80,
      hide:
        props.origin === "save" || props.origin === "Completed" ? true : false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        setLoading(false);
        const onClickTchat = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          props.openTchat(
            params.id,
            params.row.applicantName,
            params.row.centers,
            params.row.project
          );
        };

        const renderBadgeContent = (): any => {
          let nombre: any = 0;
          AuthenticationService.notification.request.forEach((request: any) => {
            if (
              params.id === request.requestId &&
              JSON.parse(request.typeModification).type === "addMessageRequest"
            ) {
              nombre += 1;
            }
          });
          AuthenticationService.notification.project.forEach((project: any) => {
            if (
              params.id === project.requestId &&
              JSON.parse(project.typeModification).type === "addMessageProject"
            ) {
              nombre += 1;
            }
          });
          return nombre;
        };

        return (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            {params.row.state !== "saved" && params.row.state !== "deleted" && (
              <Tooltip title="Chat">
                <IconButton
                  onClick={(e) => {
                    onClickTchat(e);
                  }}
                >
                  <Badge
                    color="secondary"
                    badgeContent={renderBadgeContent()}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <ChatIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        height: "80vh",
        width: "100%",
      }}
    >
      <StripedDataGrid
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "10px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
        }}
        initialState={{
          filter: {
            filterModel: props.favorite ? props.favorite : { items: [] },
          },
        }}
        onFilterModelChange={(data) => {
          if (props.origin === "Favorite") props.updateFavorite(data);
        }}
        rows={data}
        hideFooterSelectedRowCount={true}
        columns={columns}
        loading={loading}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        rowsPerPageOptions={[10, 15, 20, 100]}
        experimentalFeatures={{ newEditingApi: true }}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 300}
        onRowDoubleClick={(params) => {
          setLoadGetRequest(true);
          ProcessingGetRequest(
            params.id.toString(),
            AuthenticationService.email,
            AuthenticationService.token,
            AuthenticationService.role,
            params.row.centers
          )
            .then((newRequestData) => {
              if (newRequestData.mapInformations.status === "Error") {
                enqueueSnackbar(t("error.unableToRetrieveMapInformation"), {
                  variant: "error",
                });
              }
              props.selectRequestData(newRequestData);
            })
            .catch((error) => {
              enqueueSnackbar(t("error.errorHasOccured"), {
                variant: "error",
              });
              setLoadGetRequest(false);
            });
        }}
        // componentsProps={{
        //   toolbar: {
        //     csvOptions: { disableToolbarButton: true },
        //     showQuickFilter: true,
        //     quickFilterProps: { debounceMs: 250 },
        //   },
        // }}
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: () => (
            <GridOverlay style={{ zIndex: 99 }}>
              <Grid
                container
                height="100%"
                alignItems="center"
                alignContent="center"
                justifyContent="center"
                style={{ zIndex: 99 }}
              >
                <Grid item xs={12}>
                  <Public
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      minWidth: "100px",
                      minHeight: "100px",
                    }}
                  />
                </Grid>
                {props.origin === "Project" && (
                  <Grid item xs={12}>
                    {t("project.noProject")}
                  </Grid>
                )}
                {props.origin !== "Project" && (
                  <Grid item xs={12}>
                    {t("request.noRequest")}
                  </Grid>
                )}
                {AuthenticationService.role !== "Administrator" && (
                  <>
                    {(props.origin === "Request" ||
                      props.origin === "Saved") && (
                      <>
                        <Grid item xs={12}>
                          {t("request.createNewRequestNow")}
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "15px" }}>
                          <Button
                            variant="contained"
                            style={{ pointerEvents: "all" }}
                            onClick={() => {
                              props.createNewRequest("NewRequest");
                            }}
                          >
                            <Add style={{ marginRight: "10px" }} />
                            {t("request.newRequest")}
                          </Button>
                        </Grid>
                      </>
                    )}
                    {props.origin === "Project" && (
                      <>
                        <Grid item xs={12}>
                          {t("project.createNewProjectNow")}
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "15px" }}>
                          <Button
                            variant="contained"
                            style={{ pointerEvents: "all" }}
                            onClick={() => {
                              props.createNewRequest();
                            }}
                          >
                            <Add style={{ marginRight: "10px" }} />
                            {t("project.newProject")}
                          </Button>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </GridOverlay>
          ),
          NoResultsOverlay: () => (
            <Grid
              container
              height="100%"
              alignItems="center"
              alignContent="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <CrisisAlertIcon
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                    minWidth: "100px",
                    minHeight: "100px",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {t("global.noSolution")}
              </Grid>
              <Grid item xs={12}>
                {t("global.tryAgain")}
              </Grid>
            </Grid>
          ),
        }}
      />
      <audio ref={audioRef}>
        <source
          src="https://lasonotheque.org/UPLOAD/mp3/0116.mp3"
          type="audio/mpeg"
        />
      </audio>
      {loadGetRequest && (
        <LoadSummaryRequest open={loadGetRequest}></LoadSummaryRequest>
      )}
    </Box>
  );
};

export default GlobalDataGrid;
