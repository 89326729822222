// Page d'accueil de SAMURAI (permet l'authentification)

import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import "../../App.css";
import backgroundImage from "../../Image/test.jpg";
import dgac from "../../Image/dgac-dsna.png";
import logo from "../../Image/logo.png";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../Router/Routes";
import CreateFirstPasswordForm from "./CreateFirstPasswordForm";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "#1F2440",
  },
  error: {
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
  },
  center: {
    textAlign: "center",
  },
  wait: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    minWidth: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
  },
  paper: {
    backgroundColor: "rgba(255, 255, 255, .7)",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
  },
  avatar: {},
  form: {
    width: "80%",
  },
  signUp: {
    marginTop: "14px",
  },
  touch: {
    "&:hover": { cursor: "pointer" },
  },
}));

const CreateFirstPassword = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const navigate = useNavigate();

  const [validNewPassword, setValidNewPassword] = React.useState(false);

  const closeModalValidNewPassword = () => {
    setValidNewPassword(false);
  };
  const openModalValidNewPassword = () => {
    setValidNewPassword(true);
  };

  const modalValidNewPassword = (
    <Dialog
      open={validNewPassword}
      onClose={closeModalValidNewPassword}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle>{t("register.createFirstPasswordTitleSuccess")}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          {parse(t("register.createFirstPasswordContentSuccess"))}
        </DialogContentText>
      </DialogContent>
      <Grid item xs={12} style={{ textAlign: "center", margin: "10px" }}>
        <Button
          onClick={() => {
            navigate(PATHS.Home);
          }}
          variant="contained"
        >
          {t("global.home")}
        </Button>
      </Grid>
    </Dialog>
  );

  return (
    <div className="App">
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        className={classes.wait}
      >
        <Grid item xs={11} sm={10} md={6} lg={5} className={classes.paper}>
          <Grid
            container
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "35vh" }}
          >
            <Grid
              item
              xs={12}
              className={classes.signUp}
              style={{ margin: "12px" }}
            >
              <Typography component="h1" variant="h5">
                {t("register.createFirstPasswordTitle")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              md={8}
              className={classes.signUp}
              style={{ margin: "12px", minHeight: "20vh" }}
            >
              <CreateFirstPasswordForm
                openModalValidNewPassword={openModalValidNewPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                alignContent="center"
                alignItems="center"
                justifyContent="center"
                style={{ backgroundColor: "white" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={3}
                  style={{
                    marginTop: "10px",
                    alignContent: "right",
                    alignItems: "right",
                  }}
                >
                  <img
                    src={dgac}
                    alt={"Logo DSNA/DGAC"}
                    loading="lazy"
                    style={{
                      maxWidth: "60px",
                      maxHeight: "60px",
                      minWidth: "60px",
                      minHeight: "60px",
                    }}
                  />
                </Grid>
                <Grid item xs={6} className={classes.signUp}></Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={3}
                  style={{
                    marginTop: "10px",
                    alignContent: "right",
                    alignItems: "right",
                  }}
                >
                  <img
                    src={logo}
                    alt={"Logo DSNA/DGAC"}
                    loading="lazy"
                    style={{
                      maxWidth: "100px",
                      maxHeight: "60px",
                      minWidth: "100px",
                      minHeight: "60px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {modalValidNewPassword}
    </div>
  );
};

export default CreateFirstPassword;
