import * as turf from "@turf/turf";
import { getFile } from "../../Service/ApiService";

export const applicantMapFile = ["SIA/militaire.geojson"];
export const adminMap = ["SIA/militaire.geojson", "SIA/roads.geojson"];

export function hashString(str: string) {
  var hash = 0,
    i,
    chr;
  for (i = 0; i < Math.min(str.length, 255); i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function analyseIfKMLTouchSector(
  file: any,
  flightLevel: string,
  sectorsArea: any,
  sectorsLevel: any
) {
  const newSector: any = [];

  sectorsArea.sectors.elementary.forEach((sector: any) => {
    const feature1 = turf.feature(sector.backgroundArea);
    const feature2: any = turf.featureCollection(file.features);
    const intersection = turf.lineIntersect(feature2, feature1);

    if (intersection.features.length !== 0) {
      const requiredLevels: any[] = flightLevel.split("-");

      const res = sectorsLevel.filter(
        (sectorLevel: any) =>
          sectorLevel.sector === sector.name &&
          isBetweenPlancherAndPlafond(
            requiredLevels,
            parseInt(sectorLevel.plancher),
            parseInt(sectorLevel.plafond)
          )
      );
      if (res.length !== 0) {
        newSector.push(sector);
      }
    }
  });
  return newSector;
}

export function isBetweenPlancherAndPlafond(
  values: number[],
  plancher: number,
  plafond: number
) {
  let isBetween = false;
  let compteur = 0;
  while (isBetween === false && compteur < values.length) {
    const newValue = values[compteur];
    if (newValue >= plancher && newValue <= plafond) isBetween = true;
    else compteur++;
  }
  return isBetween;
}

export function onEachSector(sector: any, layer: any, name: string) {
  layer.bindPopup(name);
}

export function style(feature: any) {
  return {
    fillColor: feature.properties.fill ? feature.properties.fill : "red",
    fillOpacity: feature.properties.fillOpacity
      ? feature.properties.fillOpacity
      : "0.6",
    stroke: feature.properties.stroke
      ? feature.properties.stroke
      : "rgba(0,0,0,0.5)",
    strokeOpacity: feature.properties.strokeOpacity
      ? feature.properties.strokeOpacity
      : 1,
    strokeWidth: feature.properties.strokeWidth
      ? feature.properties.strokeWidth
      : 1,
    weight: 2,
    //stroke-width: to have a constant width on the screen need to adapt with scale
    // opacity: 1,
    color: feature.properties.color
      ? feature.properties.color
      : feature.properties.fill
      ? " "
      : "black",
    // dashArray: '3',
  };
}

export async function getLayerControl(
  currentFileData: string[],
  kmlFile: any,
  email: string,
  token: string
) {
  const newLayerControlData: any[] = [];

  const asyncFiles = currentFileData.map((file: string) => {
    return getFile(file, email, token);
  });
  const files = await Promise.all(asyncFiles);
  files.forEach((fileData: any) => {
    const intersectionSpace: any = [];
    try {
      fileData.features.forEach((feature: any) => {
        const feature2: any = turf.featureCollection(kmlFile.features);
        const intersection = turf.lineIntersect(feature2, feature);
        if (intersection.features.length !== 0) {
          intersectionSpace.push(feature);
        }
      });
    } catch (error) {
      console.error(error);
    }
    newLayerControlData.push(fileData);
    if (intersectionSpace.length !== 0) {
      const newFeatureCollection: any =
        turf.featureCollection(intersectionSpace);
      newFeatureCollection.name = fileData.name + " intersec";
      newLayerControlData.push(newFeatureCollection);
    }
  });

  return newLayerControlData;
}

export async function extractWaypointsFromRoads(
  waypoints: string[],
  email: string,
  token: string
) {
  const waypointsInformation: any[] = [];
  return getFile("SIA/waypoints.json", email, token).then(
    (officialWaypoints: any[]) => {
      waypoints.forEach((waypoint) => {
        const waypointInformation = officialWaypoints.find(
          (balise: any) => balise.identifiant === waypoint
        );
        if (waypointInformation !== undefined)
          waypointsInformation.push(waypointInformation);
      });
      return waypointsInformation;
    }
  );
}

export async function checkIfWaypointsExist(
  waypoints: string[],
  email: string,
  token: string
) {
  const waypointsCheck: any[] = [];
  return getFile("SIA/waypoints.json", email, token).then(
    (officialWaypoints: any[]) => {
      waypoints.forEach((waypoint) => {
        const waypointInformation = officialWaypoints.find(
          (balise: any) => balise.identifiant === waypoint
        );
        if (waypointInformation === undefined)
          waypointsCheck.push({ waypoint: waypoint, color: "error" });
        else waypointsCheck.push({ waypoint: waypoint, color: "success" });
      });
      return waypointsCheck;
    }
  );
}

export function makeRoads(road: any[]) {
  const geometry: any[] = [];
  const features: any[] = [];

  road.forEach((waypoint: any) => {
    geometry.push(waypoint.geometrie);
    features.push(
      turf.point(waypoint.geometrie, { name: waypoint.identifiant })
    );
  });

  if (road.length > 1) {
    const newLine = turf.lineString(geometry, { stroke: "#F00" });
    features.push(newLine);
  }

  const collection = {
    type: "FeatureCollection",
    features: features,
  };
  return collection;
}

export async function loadFile(
  kmlFile: any,
  waypoints: string[],
  flightLevel: string,
  email: string,
  token: string
) {
  const currentFileData = adminMap;

  let line: any;

  const multiCall = [];

  multiCall.push(getFile("SIA/controlLevel.json", email, token));
  multiCall.push(getFile("20230118.json", email, token));
  multiCall.push(getLayerControl(currentFileData, kmlFile, email, token));

  if (waypoints.length !== 0) {
    const extractWaypoints = await extractWaypointsFromRoads(
      waypoints,
      email,
      token
    );
    line = makeRoads(extractWaypoints);
  }

  try {
    const data = await Promise.all(multiCall);

    const sectorsLevel = data[0];
    const sectorsArea = data[1];
    const newSector: any = analyseIfKMLTouchSector(
      kmlFile,
      flightLevel,
      sectorsArea,
      sectorsLevel
    );

    return {
      layer: data[2],
      sector: newSector,
      line: waypoints.length !== 0 ? line : [],
      status: "Success",
    };
  } catch (error) {
    console.error(error);
    return { layer: [], sector: [], status: "Error" };
  }
}

export async function applicantMapInformations(
  currentFileData: string[],
  waypoints: string[],
  kmlFile: any,
  email: string,
  token: string
) {
  try {
    if (waypoints.length !== 0) {
      const extractWaypoints = await extractWaypointsFromRoads(
        waypoints,
        email,
        token
      );
      const line = makeRoads(extractWaypoints);
      const layer = await getLayerControl(
        currentFileData,
        kmlFile,
        email,
        token
      );
      return { line: line, layer: layer, status: "Success" };
    } else {
      const layer = await getLayerControl(
        currentFileData,
        kmlFile,
        email,
        token
      );
      return { layer: layer, status: "Success" };
    }
  } catch (error) {
    console.error(error);
    return { line: [], layer: [], status: "Error" };
  }
}
