import * as React from "react";
import Box from "@mui/material/Box";
import {
  clearNotification,
  getMessage,
  inspectRequest,
} from "../../../Service/ApiService";
import AuthenticationService from "../../../Service/AuthenticationService";
import Tchat from "../../Common/Modal/Tchat";
import GlobalDataGrid from "../../Common/GlobalDataGrid";
import { useState } from "react";
import SummaryRequest from "../../Common/Modal/SummaryRequest";
import ApprovedIf from "../Modal/ApprovedIf";
import Rejected from "../Modal/Rejected";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const AdminDataGrid = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [summaryRequest, setSummaryRequest] = useState(false);
  const [summaryRequestData, setSummaryRequestData] = useState({} as any);
  const [rejectedRequest, setRejectedRequest] = useState(false);
  const [tchat, setTchat] = useState(false);
  const [tchatMessage, setTchatMessage] = useState([]);
  const [update, setUpdate] = useState(false);
  const [approvedIfRequest, setApprovedIfRequest] = React.useState(false);
  const [endLoadGetRequest, setEndLoadGetRequest] = useState(false);

  const closeApprovedIfRequest = () => {
    setApprovedIfRequest(false);
  };

  const closeSummaryRequest = () => {
    setSummaryRequest(false);
  };

  const closeTchat = () => {
    setTchat(false);
  };

  const openTchat = (
    requestId: string,
    applicantName: string,
    center: any,
    project: boolean
  ) => {
    const userEmail = AuthenticationService.email;
    const token = AuthenticationService.token;
    getMessage(requestId, token, userEmail, AuthenticationService.company)
      .then((data) => {
        setSummaryRequestData({
          requestId: requestId,
          applicantName: applicantName,
          target: applicantName,
          project: project,
        });
        setTchatMessage(data);
        clearNotification(
          "addMessage",
          "Administrator",
          requestId,
          [],
          AuthenticationService.email,
          AuthenticationService.token,
          AuthenticationService.role
        )
          .then(() => {
            AuthenticationService.updateNotification().then(() => {
              props.updateNotification();
              setTchat(true);
            });
          })
          .catch((error) => {
            enqueueSnackbar(t("error.errorHasOccuredClearNotification"), {
              variant: "error",
            });
            console.error(error);
            setTchat(true);
          });
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const conditionalProcessing = (state: string, reason: string) => {
    const id = summaryRequestData.id;
    const email = summaryRequestData.userEmail;
    const linkCenterId = summaryRequestData.centerId;
    const userEmail = AuthenticationService.email;
    const token = AuthenticationService.token;
    const isProject: boolean = props.origin === "Project" ? true : false;

    inspectRequest(
      id,
      reason,
      state,
      email,
      token,
      userEmail,
      linkCenterId,
      isProject,
      AuthenticationService.role
    )
      .then((data) => {
        if (data === "Success") {
          enqueueSnackbar(t("global.updateOfSuccessStatus"), {
            variant: "success",
          });
          finish();
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const updateTchat = (requestId: string) => {
    const userEmail = AuthenticationService.email;
    const token = AuthenticationService.token;
    getMessage(requestId, token, userEmail, AuthenticationService.company)
      .then((data) => {
        setTchatMessage(data);
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const updateProtecInformations = (newProtecInformations: any) => {
    summaryRequestData.protec.informations = newProtecInformations;

    setSummaryRequest({ ...summaryRequestData });
  };

  const closeRejectedRequest = () => {
    setRejectedRequest(false);
  };

  const selectAction = (action: string) => {
    switch (action) {
      case "Approved if":
        setApprovedIfRequest(true);
        break;
      case "accepted":
        acceptedRequest();
        break;
      case "rejected":
        setRejectedRequest(true);
        break;
      default:
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error("Une erreur est survenue !");
        break;
    }
  };

  const reSubmitRequest = () => {
    setSummaryRequest(false);
    setUpdate(!update);
  };

  const updateRequestChoice = () => {
    setSummaryRequest(false);
  };

  const finish = () => {
    closeRejectedRequest();
    closeApprovedIfRequest();
    closeSummaryRequest();
    setUpdate(!update);
  };

  const acceptedRequest = () => {
    const id = summaryRequestData.id;
    const email = summaryRequestData.userEmail;
    const linkCenterId = summaryRequestData.centerId;
    const userEmail = AuthenticationService.email;
    const token = AuthenticationService.token;
    const isProject: boolean = props.origin === "Project" ? true : false;

    inspectRequest(
      id,
      "",
      "accepted",
      email,
      token,
      userEmail,
      linkCenterId,
      isProject,
      AuthenticationService.role
    )
      .then((data) => {
        if (data === "Success") {
          enqueueSnackbar(t("global.successfulUpdate"), {
            variant: "success",
          });
          finish();
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const selectRequestData = (newRequestData: any) => {
    setSummaryRequestData(newRequestData);
    props.selectRequestData(newRequestData);
    if (props.origin === "Project" || props.origin === "Request")
      clearNotification(
        "noChat",
        "Administrator",
        newRequestData.id,
        [],
        AuthenticationService.email,
        AuthenticationService.token,
        AuthenticationService.role
      )
        .then(() => {
          AuthenticationService.updateNotification().then(() => {
            props.updateNotification();
            setSummaryRequest(true);
            setEndLoadGetRequest(!endLoadGetRequest);
          });
        })
        .catch((error) => {
          enqueueSnackbar(t("error.errorHasOccuredClearNotification"), {
            variant: "error",
          });
          console.error(error);
          setSummaryRequest(true);
          setEndLoadGetRequest(!endLoadGetRequest);
        });
    else {
      setSummaryRequest(true);
      setEndLoadGetRequest(!endLoadGetRequest);
    }
  };

  return (
    <Box sx={{ height: "80vh", width: "100%" }}>
      <GlobalDataGrid
        listOfRequest={props.listOfRequest}
        selectRequestData={selectRequestData}
        openTchat={openTchat}
        updateFavorite={props.updateFavorite}
        favorite={props.favorite}
        origin={props.origin}
        update={update}
        updateNotification={props.updateNotification}
        endLoadGetRequest={endLoadGetRequest}
      />
      {summaryRequest && (
        <SummaryRequest
          open={summaryRequest}
          closeModal={closeSummaryRequest}
          requestData={summaryRequestData}
          updateRequest={updateRequestChoice}
          updateProtecInformations={updateProtecInformations}
          reSubmitRequest={reSubmitRequest}
          selectAction={selectAction}
          origin={props.originSummmary}
          updateOrigin={props.updateOrigin}
        />
      )}
      <Tchat
        open={tchat}
        closeModal={closeTchat}
        messages={tchatMessage}
        requestData={summaryRequestData}
        updateTchat={updateTchat}
      />
      <ApprovedIf
        open={approvedIfRequest}
        closeModal={closeApprovedIfRequest}
        requestData={summaryRequestData}
        finish={finish}
        conditionalProcessing={conditionalProcessing}
      />
      <Rejected
        open={rejectedRequest}
        closeModal={closeRejectedRequest}
        requestData={summaryRequestData}
        finish={finish}
        conditionalProcessing={conditionalProcessing}
      />
    </Box>
  );
};

export default AdminDataGrid;
