import { DateTime } from "luxon";
import getAllDatesBeetweenStartAndEnd from "./ListAllDaysOfSchedule";

export default function formatingTime(
  specificInformations: any,
  flightType: string
) {
  if (
    flightType === "Test flight" ||
    flightType === "Scientific flight" ||
    flightType === "Calibration" ||
    flightType === "Photo mission"
  ) {
    return [
      DateTime.fromISO(specificInformations.requestedDay).toFormat("dd/MM") +
        " : " +
        DateTime.fromISO(specificInformations.endTime).toFormat("HHmm") +
        " - " +
        DateTime.fromISO(specificInformations.startTime).toFormat("HHmm"),
    ];
  } else if (flightType === "Media coverage") {
    const start: DateTime = DateTime.fromISO(
      specificInformations.requestedDayStart
    );
    const end: DateTime = DateTime.fromISO(
      specificInformations.requestedDayEnd
    );

    const dayDiff = Math.round(end.diff(start, "days").days) + 1;
    const newScheduleDays = getAllDatesBeetweenStartAndEnd(dayDiff, start);
    return specificInformations.schedule.map(
      (time: string[], index: number) => {
        return (
          newScheduleDays[index] +
          " : " +
          DateTime.fromISO(time[0]).toFormat("HHmm") +
          " - " +
          DateTime.fromISO(time[1]).toFormat("HHmm")
        );
      }
    );
  } else if (flightType === "Various" || flightType === "Skydiving") {
    return specificInformations.requestedDays.map(
      (requestedDay: string, index: number) => {
        return (
          DateTime.fromISO(requestedDay).toFormat("dd/MM") +
          " : " +
          DateTime.fromISO(specificInformations.schedule[index][0]).toFormat(
            "HH:mm"
          ) +
          " - " +
          DateTime.fromISO(specificInformations.schedule[index][1]).toFormat(
            "HH:mm"
          )
        );
      }
    );
  }
}
