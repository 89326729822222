import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../Router/Routes";
import { confirmEmail } from "../../Service/ApiService";
import backgroundImage from "../../Image/test.jpg";
import Error from "../Common/Modal/Error";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  error: {
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
  },
  accept: {
    backgroundColor: "green",
    color: "white",
    textAlign: "center",
  },
}));

const ConfirmEmail = (props: any) => {
  useEffect(() => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get("token") || "";

      confirmEmail(token)
        .then((retour) => {
          setSuccess(true);
        })
        .catch((error) => {
          updateErrorContent(
            "<Typography>" + error.response.data + "</Typography>"
          );
          setError(true);
        });
    } catch (e) {
      updateErrorContent("<Typography>" + e + "</Typography>");
      setError(true);
    }
  }, []);

  const classes = useStyles();
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorContent, setErrorContent] = useState({} as any);
  const { t } = useTranslation();

  const updateErrorContent = (newContent: string) => {
    setErrorContent(newContent);
    setError(true);
  };

  const closeSuccess = () => {
    setSuccess(false);
    navigate(PATHS.Home);
  };

  const closeError = () => {
    setError(false);
    navigate(PATHS.Home);
  };

  // Gestion des modals

  const modalSuccess = (
    <Dialog
      open={success}
      onClose={closeSuccess}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle className={classes.accept}>
        {t("register.confirmEmailTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          {parse(t("register.confirmEmailContent"))}
        </DialogContentText>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );

  return (
    <div
      style={{ minHeight: "100vh", maxHeight: "180vh" }}
      className={classes.background}
    >
      {modalSuccess}
      <Error open={error} errorContent={errorContent} closeModal={closeError} />
    </div>
  );
};

export default ConfirmEmail;
