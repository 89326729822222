import { Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  VictoryChart,
  VictoryBar,
  VictoryGroup,
  VictoryAxis,
  LineSegment,
  VictoryLabel,
} from "victory";

const GlobalStatistics = (props: any) => {
  const { t } = useTranslation();
  //   title={t("statistics.repartitionOfAcceptancesAndRejections") || "ddd"}

  return (
    <Paper style={{ height: "45vh", width: "100%" }} elevation={4}>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom style={{ margin: "15px" }}>
            {t("statistics.repartitionOfAcceptancesAndRejections")}
          </Typography>
        </Grid>
        <Grid item>
          <VictoryChart height={385} width={1200}>
            <VictoryGroup offset={15} colorScale={["#F15A5A", "#4EBA6F"]}>
              <VictoryBar
                data={props.data}
                labels={({ datum }) => datum.y}
                labelComponent={<VictoryLabel />}
                barWidth={({ index }) => 12}
              />
              <VictoryBar
                data={props.accepted}
                labels={({ datum }) => datum.y}
                labelComponent={<VictoryLabel />}
                barWidth={({ index }) => 12}
              />
            </VictoryGroup>
            <VictoryAxis
              gridComponent={<LineSegment />}
              label="Applicant"
              style={{
                axis: { stroke: "#82abbd" },
                axisLabel: {
                  padding: 30,
                  fontSize: 18,
                },
                grid: { stroke: "#82abbd60" },
                tickLabels: { fontSize: 14 },
              }}
            />
          </VictoryChart>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default GlobalStatistics;
