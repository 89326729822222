// Page d'accueil de SAMURAI (permet l'authentification)

import { Grid, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import "../App.css";
import backgroundImage from "../Image/test.jpg";
import dgac from "../Image/dgac-dsna.png";
import logo from "../Image/logo.png";
import Singin from "./Common/Singin";
import { useNavigate } from "react-router-dom";
import AuthenticationService from "../Service/AuthenticationService";
import { PATHS } from "../Router/Routes";
import Error from "./Common/Modal/Error";
import { useTranslation } from "react-i18next";
import Cookie from "./Common/Modal/Cookies";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "#1F2440",
  },
  error: {
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
  },
  center: {
    textAlign: "center",
  },
  wait: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    minWidth: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
  },
  paper: {
    backgroundColor: "rgba(255, 255, 255, .7)",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
  },
  avatar: {},
  form: {
    width: "80%",
  },
  signUp: {
    marginTop: "14px",
  },
  touch: {
    "&:hover": { cursor: "pointer" },
  },
}));

const Home = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [rememberResult, setRememberResult] = useState(false);
  const [error, setError] = useState(false);
  const [errorContent, setErrorContent] = useState({} as any);
  const [cookie, setCookie] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const cookie = localStorage.getItem("Cookie");
    if (cookie === null) {
      setCookie(true);
    }
    const rememberCheck = AuthenticationService.testSession();
    if (rememberCheck === true) {
      AuthenticationService.remember().then((response) => {
        if (response === "OK" && AuthenticationService.role === "Default") {
          navigate(PATHS.UserHome);
        } else if (
          response === "OK" &&
          AuthenticationService.role === "Administrator"
        ) {
          navigate(PATHS.AdminHome);
        } else {
          setRememberResult(true);
        }
      });
    } else {
      setRememberResult(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeCookie = () => {
    setCookie(false);
  };

  const updateErrorContent = (newContent: string) => {
    setErrorContent(newContent);
    setError(true);
  };
  const closeError = () => {
    setError(false);
  };

  return (
    <div className="App">
      {rememberResult && (
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          className={classes.wait}
        >
          <img
            src={dgac}
            alt={"Logo DSNA/DGAC"}
            loading="lazy"
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              maxWidth: "60px",
              maxHeight: "60px",
              minWidth: "60px",
              minHeight: "60px",
            }}
          />

          <Grid item xs={11} sm={10} md={6} lg={5} className={classes.paper}>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: "45vh" }}
            >
              <Grid
                item
                xs={12}
                className={classes.signUp}
                style={{ margin: "12px" }}
              >
                <Typography component="h1" variant="h5">
                  {t("title.signIn")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                md={8}
                className={classes.signUp}
                style={{ margin: "12px", minHeight: "30vh" }}
              >
                <Singin updateErrorContent={updateErrorContent} />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  alignContent="center"
                  alignItems="center"
                  justifyContent="center"
                  style={{ backgroundColor: "white" }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    lg={3}
                    style={{
                      marginTop: "10px",
                      alignContent: "right",
                      alignItems: "right",
                    }}
                  ></Grid>
                  <Grid item xs={6} className={classes.signUp}>
                    <Typography component="h1" variant="body2">
                      {t("newUser") + " ? "}
                      <Link
                        className={classes.touch}
                        variant="body2"
                        onClick={() => {
                          navigate("Register");
                        }}
                      >
                        {t("register.register")}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    lg={3}
                    style={{
                      marginTop: "10px",
                      alignContent: "right",
                      alignItems: "right",
                    }}
                  >
                    <img
                      src={logo}
                      alt={"Logo SAMURAI"}
                      loading="lazy"
                      style={{
                        maxWidth: "80px",
                        maxHeight: "60px",
                        minWidth: "80px",
                        minHeight: "60px",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Error closeModal={closeError} open={error} errorContent={errorContent} />
      <Cookie open={cookie} closeModal={closeCookie} />
    </div>
  );
};

export default Home;
