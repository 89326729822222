import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import { adminConfirmRegister } from "../../../Service/ApiService";
import AuthenticationService from "../../../Service/AuthenticationService";
import AdminValidationForm from "../Module/Form/AdminValidationForm";
import CloseIcon from "@mui/icons-material/Close";
import Error from "../../Common/Modal/Error";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

const AdminValidation = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState(false);
  const [errorContent, setErrorContent] = useState({} as any);
  const updateErrorContent = (newContent: string) => {
    setErrorContent(newContent);
    setError(true);
  };
  const closeError = () => {
    setError(false);
  };
  
  const adminValidation = (role: string, reason: string, answer: string) => {
    adminConfirmRegister(
      role,
      reason,
      answer,
      props.user.email,
      AuthenticationService.token,
      AuthenticationService.email
    )
      .then((data) => {
        if (data === "Success"){
          enqueueSnackbar(t("global.successfullUpdate"), {
            variant: "success",
          });
           props.updateListUser()
          };
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        updateErrorContent("<Typography>" + error + "</Typography>");
      });
  };
  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5" style={{ marginRight: "30px" }}>
            {t("account.validationAccount") +
              " " +
              props.user.firstName +
              " " +
              props.user.name +
              " account"}
          </Typography>
          <Grid
            item
            style={{ position: "absolute", right: "12px", top: "12px" }}
          >
            <IconButton
              onClick={() => {
                props.closeModal();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          <AdminValidationForm adminValidation={adminValidation} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          {!props.user.emailValidation && (
            <Grid item xs={8} style={{ marginTop: "10px" }}>
              <Typography variant="body2">
                {t("account.warningUserDontValidEmail")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogActions>
      <Error closeModal={closeError} open={error} errorContent={errorContent} />
    </Dialog>
  );
};
export default AdminValidation;
