import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import AdminValidation from "../../Modal/AdminValidation";
import ManageUser from "../../Modal/ManageUser";
import { useTranslation } from "react-i18next";

const UserDataGrid = (props: any) => {
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    props.listOfRequest().then((newData: any) => {
      setData(newData);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.update]);
  const [data, setData] = React.useState([] as any);
  const [adminValidation, setAdminValidation] = React.useState(false);
  const [manageUser, setManageUser] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState({} as any);
  const [loading, setLoading] = React.useState(true);

  const columns: GridColDef[] = [
    //   { field: "id", headerName: "ID", width: 90 },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "firstName",
      headerName: t("account.firstName") || "",
      width: 150,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: t("account.name") || "",
      width: 150,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "companyName",
      headerName: t("account.companyName") || "",
      width: 200,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "center",
      headerName: t("account.center") || "",
      width: 120,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "favoriteFlightType",
      headerName: t("account.favoriteFlightType") || "",
      type: "number",
      width: 150,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "phoneNumber",
      headerName: t("account.phoneNumber") || "",
      type: "number",
      width: 180,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "emailValidation",
      headerName: t("account.emailValidation") || "",
      width: 130,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        if (params.value) {
          return <CheckIcon />;
        } else {
          return <CloseIcon />;
        }
      },
    },
    {
      field: "validationByAdmin",
      headerName: t("account.adminValidation") || "",
      width: 130,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        if (params.value === "processing") {
          return <HourglassEmptyIcon />;
        } else if (params.value === "accepted") {
          return <CheckIcon />;
        } else {
          return <CloseIcon />;
        }
      },
    },
    {
      field: "role",
      headerName: t("account.role") || "",
      width: 110,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
  ];

  const closeAdminValidation = () => {
    setAdminValidation(false);
  };
  const closeManageUser = () => {
    setManageUser(false);
  };

  const updateListUser = () => {
    props.listOfRequest().then((newData: any) => {
      setData(newData);
      setLoading(false);
    });
    setAdminValidation(false);
  };

  return (
    <Box sx={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        loading={loading}
        rowsPerPageOptions={[10, 15, 20]}
        experimentalFeatures={{ newEditingApi: true }}
        onRowDoubleClick={(user) => {
          setSelectedUser(user.row);
          if (user.row.validationByAdmin === "processing") {
            setAdminValidation(true);
          } else {
            setManageUser(true);
          }
        }}
        getRowId={(user) => user.email}
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: () => (
            <Grid
              container
              height="100%"
              alignItems="center"
              alignContent="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <PublicIcon
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                    minWidth: "100px",
                    minHeight: "100px",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {t("account.noUser")}
              </Grid>
              <Grid item xs={12}>
                {t("account.createNewAdmin")}
              </Grid>
            </Grid>
          ),
          NoResultsOverlay: () => (
            <Grid
              container
              height="100%"
              alignItems="center"
              alignContent="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <CrisisAlertIcon
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                    minWidth: "100px",
                    minHeight: "100px",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {t("global.noSolution")}
              </Grid>
              <Grid item xs={12}>
                {t("global.tryAgain")}
              </Grid>
            </Grid>
          ),
        }}
      />
      {adminValidation && (
        <AdminValidation
          open={adminValidation}
          closeModal={closeAdminValidation}
          user={selectedUser}
          updateListUser={updateListUser}
        />
      )}
      {manageUser && (
        <ManageUser
          open={manageUser}
          closeModal={closeManageUser}
          user={selectedUser}
          updateListUser={updateListUser}
        />
      )}
    </Box>
  );
};

export default UserDataGrid;
