import { Grid, Paper, Typography } from "@mui/material";
import CalibrationSummary from "../User/Module/NewRequest/SummaryOfNewRequest/CalibrationSummary";
import MediaCoverageSummary from "../User/Module/NewRequest/SummaryOfNewRequest/MediaCoverageSummary";
import PhotoMissionSummary from "../User/Module/NewRequest/SummaryOfNewRequest/PhotoMissionSummary";
import ScientificFlightSummary from "../User/Module/NewRequest/SummaryOfNewRequest/ScientificFlightSummary";
import SkydivingSummary from "../User/Module/NewRequest/SummaryOfNewRequest/SkydivingSummary";
import TestFlightSummary from "../User/Module/NewRequest/SummaryOfNewRequest/TestFlightSummary";
import VariousSummary from "../User/Module/NewRequest/SummaryOfNewRequest/VariousSummary";
import MapSummaryRequest from "./Map/AdminMap";
import AuthenticationService from "../../Service/AuthenticationService";
import Zoom from "@mui/material/Zoom";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import MapTest from "./Map/ApplicantMap";
import ListOfButtons from "./ListOfButtons";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(10),
  },
}));

const SummaryOfRequest = (props: any) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      style={{ height: "70vh" }}
    >
      <Grid item xs={6}>
        <Paper elevation={7} style={{ maxHeight: "65vh", marginTop: "15px" }}>
          <Grid container justifyContent="center" alignContent="center">
            <Grid item xs={6}>
              <Grid container justifyContent="center" alignContent="center">
                <Grid item xs={10}>
                  <Typography
                    variant="h6"
                    style={{ textAlign: "left", margin: "20px" }}
                  >
                    <u>{t("request.summary.globalInformations")}</u>
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="body1"
                    style={{ textAlign: "left", marginBottom: "12px" }}
                  >
                    {t("request.summary.applicantName") +
                      " : " +
                      props.requestData.globalInformations.applicantName}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="body1"
                    style={{ textAlign: "left", marginBottom: "12px" }}
                  >
                    {t("request.summary.referenceNumber") +
                      " : " +
                      props.requestData.globalInformations.referenceNumber}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="body1"
                    style={{ textAlign: "left", marginBottom: "12px" }}
                  >
                    {t("request.summary.aircraftType") +
                      " : " +
                      props.requestData.globalInformations.aircraftType}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="body1"
                    style={{ textAlign: "left", marginBottom: "12px" }}
                  >
                    {t("request.summary.requiredLevel") +
                      " : " +
                      props.requestData.globalInformations.requiredLevel}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="body1"
                    style={{ textAlign: "left", marginBottom: "12px" }}
                  >
                    {t("request.summary.priorityLevel") +
                      " : " +
                      props.requestData.globalInformations.priorityLevel}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="body1"
                    style={{ textAlign: "left", marginBottom: "12px" }}
                  >
                    {t("request.summary.callSign") +
                      " : " +
                      props.requestData.globalInformations.callsign}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="body1"
                    style={{ textAlign: "left", marginBottom: "12px" }}
                  >
                    {t("request.summary.flightRules") +
                      " : " +
                      props.requestData.globalInformations.flightRules}
                  </Typography>
                </Grid>
                {props.origin !== "save" && (
                  <Grid item xs={10}>
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginBottom: "12px" }}
                    >
                      {t("request.summary.currentState") + " : "}
                    </Typography>
                    <ul>
                      {props.requestData.globalInformations.center.map(
                        (center: any, index: number) => {
                          return (
                            <li key={index}>
                              <Grid
                                container
                                alignContent="center"
                                alignItems="center"
                              >
                                <Typography style={{ marginRight: "10px" }}>
                                  {center.center}
                                </Typography>

                                <HtmlTooltip
                                  title={
                                    <>
                                      <Typography
                                        color="inherit"
                                        style={{
                                          margin: "10px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {center.state}
                                      </Typography>
                                      <Paper
                                        style={{
                                          backgroundColor: "white",
                                          maxHeight: "25vh",
                                          overflow: "auto",
                                        }}
                                      >
                                        {center.state === "acceptedIf" && (
                                          <>
                                            {center.reason
                                              .timeRestrictionEnd !== "" &&
                                              center.reason
                                                .timeRestrictionStart !==
                                                "" && (
                                                <Typography
                                                  color="inherit"
                                                  style={{
                                                    margin: center.reason
                                                      ? "10px"
                                                      : "",
                                                  }}
                                                >
                                                  {"Restriction time : " +
                                                    DateTime.fromISO(
                                                      center.reason
                                                        .timeRestrictionStart
                                                    ).toFormat("HH:mm") +
                                                    "UTC - " +
                                                    DateTime.fromISO(
                                                      center.reason
                                                        .timeRestrictionStart
                                                    ).toFormat("HH:mm") +
                                                    "UTC"}
                                                </Typography>
                                              )}
                                            {center.reason
                                              .notSimultaneousWith !== "" && (
                                              <Typography
                                                color="inherit"
                                                style={{
                                                  margin: center.reason
                                                    ? "10px"
                                                    : "",
                                                }}
                                              >
                                                {"Not simultaneous with : " +
                                                  center.reason
                                                    .notSimultaneousWith}
                                              </Typography>
                                            )}
                                            <Typography
                                              color="inherit"
                                              style={{
                                                margin: center.reason
                                                  ? "10px"
                                                  : "",
                                              }}
                                            >
                                              Comments :{" "}
                                              {center.reason.comments}
                                            </Typography>
                                          </>
                                        )}
                                        {center.state === "rejected" && (
                                          <Typography
                                            color="inherit"
                                            style={{
                                              margin: center.reason
                                                ? "10px"
                                                : "",
                                            }}
                                          >
                                            {center.reason}
                                          </Typography>
                                        )}
                                      </Paper>
                                    </>
                                  }
                                  arrow
                                  TransitionComponent={Zoom}
                                >
                                  <div
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                      borderRadius: "15px",
                                      background:
                                        center.state === "acceptedIf"
                                          ? "#A0B9D9"
                                          : center.state === "rejected"
                                          ? "#F28585"
                                          : center.state === "accepted"
                                          ? "#B4CF66"
                                          : center.state === "processing"
                                          ? "#F2B263"
                                          : "",
                                    }}
                                  ></div>
                                </HtmlTooltip>
                              </Grid>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={6} style={{ marginBottom: "20px" }}>
              <Grid container justifyContent="center" alignContent="center">
                <Grid item xs={10}>
                  <Typography
                    variant="h6"
                    style={{ textAlign: "left", margin: "20px" }}
                  >
                    <u>{props.requestData.globalInformations.flightType}</u>
                  </Typography>
                </Grid>
                {props.requestData.globalInformations.flightType ===
                  "Calibration" && (
                  <CalibrationSummary
                    specificInformations={
                      props.requestData.specificInformations
                    }
                    origin={props.origin}
                  />
                )}
                {props.requestData.globalInformations.flightType ===
                  "Media coverage" && (
                  <MediaCoverageSummary
                    specificInformations={
                      props.requestData.specificInformations
                    }
                    origin={props.origin}
                  />
                )}
                {props.requestData.globalInformations.flightType ===
                  "Photo mission" && (
                  <PhotoMissionSummary
                    specificInformations={
                      props.requestData.specificInformations
                    }
                    origin={props.origin}
                  />
                )}
                {props.requestData.globalInformations.flightType ===
                  "Test flight" && (
                  <TestFlightSummary
                    specificInformations={
                      props.requestData.specificInformations
                    }
                    origin={props.origin}
                  />
                )}
                {props.requestData.globalInformations.flightType ===
                  "Scientific flight" && (
                  <ScientificFlightSummary
                    specificInformations={
                      props.requestData.specificInformations
                    }
                    origin={props.origin}
                  />
                )}
                {props.requestData.globalInformations.flightType ===
                  "Skydiving" && (
                  <SkydivingSummary
                    specificInformations={
                      props.requestData.specificInformations
                    }
                    origin={props.origin}
                  />
                )}
                {props.requestData.globalInformations.flightType ===
                  "Various" && (
                  <VariousSummary
                    specificInformations={
                      props.requestData.specificInformations
                    }
                    origin={props.origin}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* <Grid item xs={10} style={{ marginTop: "5px", marginBottom: "5px" }}>
            {props.requestData.globalInformations.center.map((center: any) => {
              if (
                (center.state === "rejected" ||
                  center.state === "acceptedIf") &&
                center.center !== AuthenticationService.company
              )
                return (
                  <Paper
                    style={{
                      marginTop: "15px",
                      maxHeight: "20vh",
                      overflow: "auto",
                      backgroundColor:
                        center.state?.toString() === "acceptedIf"
                          ? "#A0B9D9"
                          : center.state?.toString() === "rejected"
                          ? "#F28585"
                          : center.state?.toString() === "accepted"
                          ? "#B4CF66"
                          : "#F2B263",
                    }}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                    >
                      <ReasonDetails
                        reason={center.reason}
                        center={center.center}
                        state={center.state}
                      />
                    </Grid>
                  </Paper>
                );
              else return "";
            })}
          </Grid> */}
      {((props.requestData.kmlFile &&
        props.requestData.kmlFile.features.length !== 0) ||
        props.requestData.globalInformations.flightType === "Test flight") && (
        <Grid item xs={6}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid
              item
              xs={12}
              style={{
                margin: "15px",
              }}
            >
              {AuthenticationService.role === "Administrator" && (
                <MapSummaryRequest
                  height="65"
                  file={props.requestData.kmlFile}
                  mapInformations={props.requestData.mapInformations}
                  flightLevel={
                    props.requestData.globalInformations.requiredLevel
                  }
                  waypoints={
                    props.requestData.specificInformations.filedRoad
                      ? props.requestData.specificInformations.filedRoad
                          .split(";")
                          .filter((chip: string) => chip.length !== 0)
                      : undefined
                  }
                />
              )}
              {AuthenticationService.role !== "Administrator" && (
                <MapTest
                  file={props.requestData.kmlFile}
                  mapInformations={props.requestData.mapInformations}
                  height="65"
                  waypoints={
                    props.requestData.specificInformations.filedRoad
                      ? props.requestData.specificInformations.filedRoad
                          .split(";")
                          .filter((chip: string) => chip.length !== 0)
                      : undefined
                  }
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      <ListOfButtons
        requestData={props.requestData}
        origin={props.origin}
        closeModal={props.closeModal}
        missionType={props.requestData.globalInformations.flightType}
        selectAction={props.selectAction}
        updateOrigin={props.updateOrigin}
        openProtec={props.openProtec}
        state={props.requestData.state}
        reSubmitRequest={props.reSubmitRequest}
        updateRequest={props.updateRequest}
        validDelete={props.validDelete}
        selectSubmitWithModification={props.selectSubmitWithModification}
        sumbitNow={props.sumbitNow}
      />
    </Grid>
  );
};

export default SummaryOfRequest;
