// Page d'accueil de SAMURAI (permet l'authentification)

import { Box } from "@mui/material";
import * as React from "react";
import "../../../App.css";
import NavBar from "./NavBar";
import Requests from "./Requests";
import NewRequest from "./NewRequest";
import Project from "./Project";
import CompletedRequest from "./CompletedRequest";
import { useState } from "react";
import NewRequestFromProject from "./NewRequestFromProject";

const UserHome = () => {
  const [update, setUpdate] = useState(false);
  const [currentPage, setCurrentPage] = React.useState("Requests");
  const changeCurrentPage = (newCurrentPage: string) => {
    setCurrentPage(newCurrentPage);
  };

  const updateNotification = () => {
    setUpdate(!update);
  };

  return (
    <div className="App">
      <Box sx={{ display: "flex" }}>
        <NavBar changeCurrentPage={changeCurrentPage} update={update} />
        {currentPage === "Requests" && (
          <Requests
            changeCurrentPage={changeCurrentPage}
            updateNotification={updateNotification}
          />
        )}
        {currentPage === "NewRequest" && (
          <NewRequest changeCurrentPage={changeCurrentPage} origin="Home" />
        )}
        {currentPage === "NewRequestFromProject" && (
          <NewRequestFromProject
            changeCurrentPage={changeCurrentPage}
            origin="Home"
          />
        )}
        {currentPage === "CompletedRequest" && <CompletedRequest />}
        {currentPage === "Project" && (
          <Project updateNotification={updateNotification} />
        )}
      </Box>
    </div>
  );
};

export default UserHome;
