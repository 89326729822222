import { Box, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Legal = (props: any) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item xs={10}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                minHeight: 128,
              },
            }}
          >
            <Paper elevation={4}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                <Grid item xs={12} style={{ margin: "5px" }}>
                  <Typography style={{ textAlign: "left" }}>
                    {t("settings.legal")}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ margin: "10px" }}>
                  <Typography style={{ textAlign: "center", color: "blue" }}>
                    {t("settings.privacyPolicy")}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ margin: "10px" }}>
                  <Typography style={{ textAlign: "center", color: "blue" }}>
                    {t("settings.termsAndConditions")}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ margin: "10px" }}>
                  <Typography style={{ textAlign: "center", color: "blue" }}>
                    {t("settings.support")}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Legal;
