import {
  TextField,
  Button,
  Grid,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Formik, Form } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { role_type } from "../../../Dropdown";
import { useTranslation } from "react-i18next";

interface AdminValidationFormInterface {
  role: string;
  reason: string;
}

const AdminValidationForm = (props: any) => {
  const { t } = useTranslation();

  const [answer, setAnswer] = useState("");
  return (
    <Formik
      initialValues={
        {
          role: "",
          reason: "",
        } as AdminValidationFormInterface
      }
      onSubmit={(values: AdminValidationFormInterface, actions) => {
        props.adminValidation(values.role, values.reason, answer);
      }}
      validationSchema={Yup.object().shape({
        role: Yup.string(),
        reason: Yup.string(),
      })}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => {
        return (
          <Form noValidate>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid xs={12}>
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={answer}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setAnswer((event.target as HTMLInputElement).value);
                    }}
                  >
                    <FormControlLabel
                      value="accept"
                      control={<Radio />}
                      label="Accept"
                    />
                    <FormControlLabel
                      value="refuse"
                      control={<Radio />}
                      label="Refuse"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {answer === "accept" && (
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="role"
                    label="Role"
                    name="role"
                    select
                    style={{ color: "white", textAlign: "left" }}
                    value={values.role}
                    helperText={errors.role && touched.role ? errors.role : ""}
                    error={errors.role && touched.role ? true : false}
                    onChange={(event) => {
                      setFieldValue("role", event.target.value);
                    }}
                    onBlur={handleBlur}
                  >
                    {role_type.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}

              {answer === "refuse" && (
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    multiline
                    rows={3}
                    id="reason"
                    label="Reason"
                    name="reason"
                    style={{ color: "white" }}
                    value={values.reason}
                    helperText={
                      errors.reason && touched.reason ? errors.reason : ""
                    }
                    error={errors.reason && touched.reason ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
              )}
            </Grid>

            {answer !== "" && (
              <Grid item xs={12}>
                <Button variant="contained" type="submit" sx={{ mr: 1 }}>
                  {t("global.confirm")}
                </Button>
              </Grid>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default AdminValidationForm;
