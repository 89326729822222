import { TextField, Button, Grid, Box, Typography, Stack } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface SkydivingInformationsForm {
  departureAerodrome: string;
  numberOfDrops: number[];
  requestedDays: DateTime[];
  schedule: DateTime[][];
  comments: string;
}

const SkydivingInformations = (props: any) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(props.specificInformations).length === 0) {
      setSpecificInformations({
        departureAerodrome: "",
        numberOfDrops: [1],
        requestedDays: [DateTime.now()],
        schedule: [[DateTime.now(), DateTime.now()]],
        comments: "",
      });
    } else {
      setSpecificInformations(props.specificInformations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [specificInformations, setSpecificInformations] = useState(
    props.specificInformations as SkydivingInformationsForm
  );
  const [schedulesDays, setSechedulesDays] = useState([
    DateTime.now().toFormat("dd/MM"),
  ]);
  return (
    <Formik
      initialValues={specificInformations}
      enableReinitialize
      onSubmit={(values: SkydivingInformationsForm, actions) => {
        props.specificInformationsValidation({
          departureAerodrome: values.departureAerodrome,
          numberOfDrops: values.numberOfDrops,
          requestedDays: values.requestedDays,
          schedule: values.schedule,
          comments: values.comments,
        });
      }}
      validationSchema={Yup.object().shape({
        departureAerodrome: Yup.string().required(
          t("form.enterValidDepartureAerodrome") || ""
        ),
        comments: Yup.string(),
      })}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => {
        return (
          <Form noValidate>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="departureAerodrome"
                  label="Departure aerodrome"
                  name="departureAerodrome"
                  style={{ color: "white" }}
                  value={values.departureAerodrome}
                  helperText={
                    errors.departureAerodrome && touched.departureAerodrome
                      ? errors.departureAerodrome
                      : ""
                  }
                  error={
                    errors.departureAerodrome && touched.departureAerodrome
                      ? true
                      : false
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              {values.requestedDays !== undefined &&
                props.origin !== "Project" &&
                values.requestedDays.map((requestedDay: any, index: number) => {
                  return (
                    <Grid item xs={12} key={index}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                      >
                        <Grid item xs={12} style={{ margin: "10px" }}>
                          <Typography>Day {schedulesDays[index]}</Typography>
                        </Grid>
                        <Grid xs={5} style={{ margin: "10px" }} key={index}>
                          <Stack spacing={3} style={{ marginTop: "12px" }}>
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                              <DesktopDatePicker
                                label="Requested day"
                                value={requestedDay}
                                inputFormat="dd/MM/yyyy"
                                onChange={(value): void => {
                                  values.requestedDays[index] = value;
                                  setFieldValue(
                                    "requestedDays",
                                    values.requestedDays
                                  );
                                  schedulesDays[index] =
                                    value.toFormat("dd/MM");
                                  setSechedulesDays(schedulesDays);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          </Stack>
                        </Grid>
                        <Grid item xs={5} style={{ margin: "10px" }}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="numberOfDrops"
                            label="Number of drops"
                            name="numberOfDrops"
                            style={{ color: "white" }}
                            value={values.numberOfDrops[index]}
                            onChange={(value: any) => {
                              values.numberOfDrops[index] = value.target.value;
                              setFieldValue(
                                "numberOfDrops",
                                values.numberOfDrops
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <Stack
                            spacing={3}
                            style={{ marginTop: "12px", marginRight: "10px" }}
                          >
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                              <TimePicker
                                label={"Start time"}
                                value={values.schedule[index][0]}
                                ampm={false}
                                onChange={(value: any) => {
                                  values.schedule[index][0] = value;
                                  setFieldValue("schedule", values.schedule);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <Stack spacing={3} style={{ marginTop: "12px" }}>
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                              <TimePicker
                                label={"End time"}
                                value={values.schedule[index][1]}
                                ampm={false}
                                onChange={(value: any) => {
                                  values.schedule[index][1] = value;
                                  setFieldValue("schedule", values.schedule);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              {props.origin !== "Project" && (
                <>
                  <Grid item xs={12} style={{ margin: "10px" }}>
                    <Grid
                      container
                      justifyContent="left"
                      alignItems="center"
                      alignContent="center"
                    >
                      <Grid item xs={3}>
                        <Typography>{t("form.moreDays") + " : "} </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          color="primary"
                          variant="contained"
                          style={{
                            maxWidth: "30px",
                            maxHeight: "30px",
                            minWidth: "30px",
                            minHeight: "30px",
                          }}
                          onClick={() => {
                            values.requestedDays.push(DateTime.now());
                            values.schedule.push([
                              DateTime.now(),
                              DateTime.now(),
                            ]);
                            values.numberOfDrops.push(1);
                            setFieldValue(
                              "numberOfDrops",
                              values.numberOfDrops
                            );
                            setFieldValue("schedule", values.schedule);
                            setFieldValue(
                              "requestedDays",
                              values.requestedDays
                            );
                            schedulesDays.push(
                              DateTime.now().toFormat("dd/MM")
                            );
                          }}
                        >
                          +
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="comments"
                  label="Comments"
                  name="comments"
                  multiline
                  rows={4}
                  style={{ color: "white" }}
                  value={values.comments}
                  helperText={
                    errors.comments && touched.comments ? errors.comments : ""
                  }
                  error={errors.comments && touched.comments ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                disabled={props.activeStep === 0}
                onClick={props.handleBack}
                sx={{ mr: 1 }}
                variant="outlined"
              >
                {t("global.back")}
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button variant="contained" type="submit" sx={{ mr: 1 }}>
                {t("global.next")}
              </Button>
              {props.activeStep !== props.steps.length &&
                props.completed[props.activeStep] && (
                  <Typography
                    variant="caption"
                    sx={{ display: "inline-block" }}
                  >
                    {t("global.step") +
                      " " +
                      (props.activeStep + 1) +
                      " " +
                      t("global.alreadyCompleted")}
                  </Typography>
                )}
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SkydivingInformations;
