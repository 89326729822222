import { Button, Grid, Box, Step, StepButton, Stepper } from "@mui/material";
import React from "react";
import UserInformations from "./UserInformations";
import CompanyInformations from "./CompanyInformations";
import PasswordInformations from "./PasswordInformations";
import ConfirmRegistration from "./ConfirmRegistration";
import { register } from "../../Service/ApiService";
import { useTranslation } from "react-i18next";

const Registration = () => {
  const { t } = useTranslation();
  const steps = [
    t("register.user"),
    t("register.company"),
    t("register.choosePassword"),
    t("register.confirmRegistration"),
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [userInformations, setUserInformations] = React.useState({
    email: "",
    firstName: "",
    name: "",
  });

  const [companyInformations, setCompanyInformations] = React.useState({
    companyName: "",
    favoriteFlightType: "",
    phoneNumber: "",
    indicatif: "+ ",
  });

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const userInformationsValidation = (
    email: string,
    firstName: string,
    name: string
  ) => {
    setUserInformations({ email: email, firstName: firstName, name: name });
    handleComplete();
  };

  const companyInformationsValidation = (
    companyName: string,
    favoriteFlightType: string,
    phoneNumber: string,
    indicatif: string
  ) => {
    console.log(phoneNumber)
    setCompanyInformations({
      companyName: companyName,
      favoriteFlightType: favoriteFlightType,
      phoneNumber: phoneNumber,
      indicatif: indicatif,
    });
    handleComplete();
  };

  const passwordInformationsValidation = (password: string) => {
    register(
      userInformations.email,
      userInformations.firstName,
      userInformations.name,
      companyInformations.companyName,
      companyInformations.favoriteFlightType,
      companyInformations.indicatif + companyInformations.phoneNumber,
      password
    );

    handleComplete();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit">{label}</StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>{t("global.reset")}</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid sx={{ mt: 2, mb: 1, py: 1 }}></Grid>
            {activeStep === 0 && (
              <UserInformations
                activeStep={activeStep}
                handleBack={handleBack}
                userInformationsValidation={userInformationsValidation}
                steps={steps}
                completed={completed}
                handleComplete={handleComplete}
                completedSteps={completedSteps}
                totalSteps={totalSteps}
                userInformations={userInformations}
              />
            )}
            {activeStep === 1 && (
              <CompanyInformations
                activeStep={activeStep}
                handleBack={handleBack}
                companyInformationsValidation={companyInformationsValidation}
                steps={steps}
                completed={completed}
                handleComplete={handleComplete}
                completedSteps={completedSteps}
                totalSteps={totalSteps}
                companyInformations={companyInformations}
              />
            )}
            {activeStep === 2 && (
              <PasswordInformations
                activeStep={activeStep}
                handleBack={handleBack}
                passwordInformationsValidation={passwordInformationsValidation}
                steps={steps}
                completed={completed}
                handleComplete={handleComplete}
                completedSteps={completedSteps}
                totalSteps={totalSteps}
              />
            )}
            {activeStep === 3 && <ConfirmRegistration />}
          </React.Fragment>
        )}
      </div>
    </Box>
  );
};

export default Registration;
