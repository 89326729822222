import { TextField, Button, Grid } from "@mui/material";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";

interface AdminValidationForm {
  reason: string;
}

const RejectedForm = (props: any) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={
        {
          reason: "",
        } as AdminValidationForm
      }
      onSubmit={(values: AdminValidationForm, actions) => {
        props.processRequest("rejected", values.reason);
      }}
      validationSchema={Yup.object().shape({
        reason: Yup.string(),
      })}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => {
        return (
          <Form noValidate>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  multiline
                  rows={3}
                  id="reason"
                  label="Reason"
                  name="reason"
                  style={{ color: "white" }}
                  value={values.reason}
                  helperText={
                    errors.reason && touched.reason ? errors.reason : ""
                  }
                  error={errors.reason && touched.reason ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" type="submit" sx={{ mr: 1 }}>
                {t("global.confirm")}
              </Button>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RejectedForm;
