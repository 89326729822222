import React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";

const ProtecExportPhotoMission = (props: any) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      style={{
        height: "800px",
      }}
    >
      <Grid
        item
        xs={5}
        style={{
          marginLeft: "40px",
          marginRight: "15px",
          backgroundColor: "#D9042B",
          height: "30px",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography align="center" color="white" style={{ fontSize: "25px" }}>
          {props.requestData.protec.informations.jobNumber}
        </Typography>
      </Grid>
      <Grid
        item
        xs={3}
        style={{
          marginRight: "10px",
          marginLeft: "10px",
          backgroundColor: "#D9042B",
          maxHeight: "30px",
          overflow: "hidden",
        }}
      >
        <Typography
          color="white"
          style={{ marginLeft: "10px", fontSize: "18px" }}
        >
          {props.requestData.protec.informations.otherCenters}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          style={{
            height: "30px",
          }}
        >
          <Grid item xs={10}>
            <Divider color="black" />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", fontSize: "18px" }}>
            {props.requestData.specificInformations.requestedDay.toFormat(
              "dd/MM/yyyy"
            )}
          </Grid>
          <Grid item xs={10}>
            <Divider color="black" />
          </Grid>
          <Grid
            item
            xs={10}
            style={{
              backgroundColor: "#CACACA",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            CDS/ACDS/FIR
          </Grid>
          <Grid item xs={10}>
            <Divider color="black" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ height: "650px" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid
            item
            xs={5}
            style={{
              height: "200px",
              textAlign: "left",
              margin: "5px",
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{
                marginRight: "5px",
                marginTop: "5px",
                marginBottom: "5px",
                height: "200px",
                border: "solid black 1px",
                overflow: "hidden",
              }}
            >
              <Grid item xs={12} style={{ margin: "15px" }}>
                <Typography style={{ marginTop: "15px", marginBottom: "15px" }}>
                  <u>
                    {" "}
                    <b>Société :</b>
                  </u>{" "}
                  {props.requestData.globalInformations.applicantName}
                </Typography>
                <Typography style={{ marginBottom: "15px" }}>
                  <u>
                    <b>Règles de vol :</b>
                  </u>{" "}
                  {props.requestData.globalInformations.flightRules}
                </Typography>
                <Typography style={{ marginBottom: "15px" }}>
                  <u>
                    <b>Durée totale :</b>
                  </u>{" "}
                  {
                    props.requestData.specificInformations
                      .actualDurationOfMission
                  }
                </Typography>
                <Typography style={{ marginBottom: "15px" }}>
                  <u>
                    <b>Immatriculation :</b>
                  </u>{" "}
                  {props.requestData.globalInformations.referenceNumber}
                </Typography>
                <Typography style={{ marginBottom: "15px" }}>
                  <u>
                    <b>Callsign :</b>
                  </u>{" "}
                  {props.requestData.globalInformations.callsign}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid
            item
            xs={5}
            style={{
              color: "red",
              minHeight: "50px",
              maxHeight: "150px",
              textAlign: "left",
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{
                marginTop: "5px",
                minHeight: "30px",
                maxHeight: "130px",
                marginBottom: "5px",
                border: "solid #D9042B 1px",
                overflow: "hidden",
              }}
            >
              <Grid xs={12} style={{ margin: "15px" }}>
                <Typography style={{ textAlign: "center" }}>
                  {"Axes : " +
                    props.requestData.specificInformations.numberOfAxesToFly +
                    " axes au FL" +
                    props.requestData.globalInformations.requiredLevel}
                  <span style={{ color: "black" }}></span>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{
                marginTop: "5px",
                minHeight: "30px",
                maxHeight: "130px",
                marginBottom: "5px",
                border: "solid black 1px",
                overflow: "hidden",
              }}
            >
              <Grid xs={12} style={{ margin: "15px" }}>
                <Typography>
                  <u>Consignes sur secteur :</u>
                </Typography>
                <Typography
                  color="black"
                  dangerouslySetInnerHTML={{
                    __html:
                      props.requestData.protec.informations.instructionOnSector,
                  }}
                ></Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={5}
            style={{
              minHeight: "30px",
              maxHeight: "350px",
              textAlign: "left",
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{
                marginRight: "5px",
                marginTop: "5px",
                minHeight: "30px",
                maxHeight: "320px",
                marginBottom: "5px",
                border: "solid black 1px",
                overflow: "hidden",
                color: "red",
              }}
            >
              <Grid xs={12} style={{ margin: "15px" }}>
                <Typography>
                  <u>Consignes CDS :</u>
                </Typography>
                <Typography
                  color="black"
                  dangerouslySetInnerHTML={{
                    __html:
                      props.requestData.protec.informations.cdsInstruction,
                  }}
                ></Typography>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginRight: "5px",
                marginTop: "5px",
                minHeight: "30px",
                maxHeight: "130px",
                border: "solid black 1px",
                overflow: "hidden",
              }}
            >
              <Grid item xs={12} style={{ margin: "15px" }}>
                <Typography>
                  <u>Organismes consultés :</u>
                </Typography>
                <Typography
                  color="black"
                  dangerouslySetInnerHTML={{
                    __html:
                      props.requestData.protec.informations
                        .organizationConsulted,
                  }}
                ></Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid
            item
            xs={5}
            style={{
              minHeight: "30px",
              maxHeight: "350px",
              textAlign: "left",
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              style={{
                marginTop: "5px",
                marginLeft: "5px",
                minHeight: "30px",
                maxHeight: "250px",
                border: "solid #D9042B 1px",
                overflow: "hidden",
                color: "#D9042B",
                backgroundColor: "#F2C6C2",
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  marginLeft: "15px",
                  marginRight: "15px",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  color="#D9042B"
                  dangerouslySetInnerHTML={{
                    __html: props.requestData.protec.informations.interaction,
                  }}
                ></Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              style={{
                marginTop: "5px",
                marginLeft: "5px",
                minHeight: "30px",
                border: "solid #D9042B 1px",
                overflow: "hidden",
                color: "#D9042B",
                backgroundColor: "#F2C6C2",
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  marginLeft: "15px",
                  marginRight: "15px",
                  marginBottom: "10px",
                }}
              >
                <p>Numéro RPO : 06 85 83 15 21</p>
                <span>
                  Numéro Sub CTL en cas de problème (horaires de bureau) :
                </span>
                <table style={{ borderCollapse: "collapse" }}>
                  <tr>
                    <td style={{ textAlign: "right", border: "none" }}>
                      03 26 84 62 98
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right", border: "none" }}>
                      62 46
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right", border: "none" }}>
                      62 72
                    </td>
                  </tr>
                </table>
                <Typography color="black"></Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          margin: "5px",
          textAlign: "center",
        }}
      >
        <Typography>Carte au verso</Typography>
        <UndoIcon style={{ rotate: "-90deg" }} />
      </Grid>
    </Grid>
  );
};

export default ProtecExportPhotoMission;
