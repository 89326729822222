import {
  Button,
  Grid,
  Box,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React from "react";
import WarningNoSelectKMLFile from "../../Modal/WarningNoSelectKMLFile";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

const SelectKMLFile = (props: any) => {
  const { t } = useTranslation();

  const [file, setFile] = React.useState(props.kmlFileInformations.kmlFile);
  const [fileName, setFileName] = React.useState(
    props.kmlFileInformations.fileName
  );
  const [warningNoSelectKML, setWarningNoSelectKML] = React.useState(false);

  const closeWarningNoSelectKML = () => {
    setWarningNoSelectKML(false);
  };

  const changeFile = (newFile: any) => {
    const fileReader = new FileReader();
    const newFileName = newFile.target.files[0].name;
    fileReader.readAsText(newFile.target.files[0], "UTF-8");
    fileReader.onload = (newFile) => {
      if (typeof newFile.target?.result === "string") {
        const kmlString = newFile.target?.result;
        setFile(kmlString);
        setFileName(newFileName);
        props.selectKMLFileChange(kmlString, newFileName);
      }
    };
  };
  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} style={{ height: "10vh" }}>
        <Typography>{t("request.flightArea")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack alignItems="center" spacing={2}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              hidden
              accept=".kml"
              type="file"
              onChange={(newFile) => {
                changeFile(newFile);
              }}
            />
            <CloudUploadIcon style={{ height: "10vh", width: "100%" }} />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {fileName && (
          <Typography textAlign="center">
            {fileName}
            <Button
              variant="contained"
              style={{
                maxHeight: "20px",
                minHeight: "20px",
                maxWidth: "20px",
                minWidth: "20px",
                marginLeft: "10px",
              }}
              onClick={() => {
                setFile(null);
                setFileName(null);
                props.selectKMLFileChange(null, null);
              }}
            >
              <Close />
            </Button>
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            disabled={props.activeStep === 0}
            onClick={props.handleBack}
            sx={{ mr: 1 }}
            variant="outlined"
          >
            {t("global.back")}
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            type="submit"
            sx={{ mr: 1 }}
            onClick={() => {
              if (file !== null) {
                props.selectKMLFileValidation();
              } else {
                setWarningNoSelectKML(true);
              }
            }}
            variant="contained"
          >
            {t("global.next")}
          </Button>
        </Box>
      </Grid>
      {props.activeStep !== props.steps.length &&
        props.completed[props.activeStep] && (
          <Typography variant="caption" sx={{ display: "inline-block" }}>
            {t("global.step") +
              " " +
              (props.activeStep + 1) +
              " " +
              t("global.alreadyCompleted")}
          </Typography>
        )}
      <WarningNoSelectKMLFile
        open={warningNoSelectKML}
        closeModal={closeWarningNoSelectKML}
        undo={closeWarningNoSelectKML}
        confirm={props.selectKMLFileValidation}
      />
    </Grid>
  );
};

export default SelectKMLFile;
