import {
  TextField,
  Button,
  Grid,
  Box,
  Typography,
  MenuItem,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { flight_type } from "../Dropdown";
import { useTranslation } from "react-i18next";

interface CompanyForm {
  companyName: string;
  favoriteFlightType: string;
  phoneNumber: string;
  indicatif: string;
}

const CompanyInformations = (props: any) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={props.companyInformations as CompanyForm}
      onSubmit={(values: CompanyForm, actions) => {
        props.companyInformationsValidation(
          values.companyName,
          values.favoriteFlightType,
          values.phoneNumber,
          values.indicatif
        );
      }}
      validationSchema={Yup.object().shape({
        favoriteFlightType: Yup.string().required(
          t("form.enterValidFavoriteFlightType") || ""
        ),
        companyName: Yup.string().required(
          t("form.enterValidCompanyName") || ""
        ),
      })}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => {
        return (
          <Form noValidate>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="companyName"
                  label="Company Name"
                  name="companyName"
                  style={{ color: "white" }}
                  value={values.companyName}
                  helperText={
                    errors.companyName && touched.companyName
                      ? errors.companyName
                      : " "
                  }
                  error={
                    errors.companyName && touched.companyName ? true : false
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="indicatif"
                  label="Indicatif"
                  name="indicatif"
                  style={{ color: "white" }}
                  value={values.indicatif}
                  helperText={
                    errors.indicatif && touched.indicatif
                      ? errors.indicatif
                      : " "
                  }
                  error={errors.indicatif && touched.indicatif ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid xs={8}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="phoneNumber"
                  label="Phone number"
                  name="phoneNumber"
                  style={{ color: "white" }}
                  value={values.phoneNumber}
                  helperText={
                    errors.phoneNumber && touched.phoneNumber
                      ? errors.phoneNumber
                      : " "
                  }
                  error={
                    errors.phoneNumber && touched.phoneNumber ? true : false
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="favoriteFlightType"
                  label="Favorite flight type"
                  name="favoriteFlightType"
                  select
                  style={{ color: "white" }}
                  value={values.favoriteFlightType}
                  helperText={
                    errors.favoriteFlightType && touched.favoriteFlightType
                      ? errors.favoriteFlightType
                      : " "
                  }
                  error={
                    errors.favoriteFlightType && touched.favoriteFlightType
                      ? true
                      : false
                  }
                  onChange={(event) => {
                    setFieldValue("favoriteFlightType", event.target.value);
                  }}
                  onBlur={handleBlur}
                >
                  <MenuItem key={""} value={""}></MenuItem>
                  {flight_type.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={props.activeStep === 0}
                onClick={props.handleBack}
                sx={{ mr: 1 }}
              >
                {t("global.back")}
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button type="submit" sx={{ mr: 1 }}>
                {t("global.next")}
              </Button>
              {props.activeStep !== props.steps.length &&
                props.completed[props.activeStep] && (
                  <Typography
                    variant="caption"
                    sx={{ display: "inline-block" }}
                  >
                    {t("global.step") +
                      " " +
                      (props.activeStep + 1) +
                      " " +
                      t("global.alreadyCompleted")}
                  </Typography>
                )}
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CompanyInformations;
