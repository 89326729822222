import { TextField, Button, Grid, Box, Typography, Stack } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface PhotoMissionInformationsForm {
  numberOfAxesToFly: string;
  totalDurationOfMission: string;
  actualDurationOfMission: string;
  requestedDay: DateTime;
  startTime: DateTime;
  endTime: DateTime;
  comments: string;
}

const PhotoMissionInformations = (props: any) => {
  const { t } = useTranslation();

  const [specificInformations, setSpecificInformations] = useState(
    props.specificInformations as PhotoMissionInformationsForm
  );
  useEffect(() => {
    if (Object.keys(props.specificInformations).length === 0) {
      setSpecificInformations({
        numberOfAxesToFly: "",
        totalDurationOfMission: "",
        actualDurationOfMission: "",
        requestedDay: DateTime.now(),
        startTime: DateTime.now(),
        endTime: DateTime.now(),
        comments: "",
      });
    } else {
      setSpecificInformations(props.specificInformations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={specificInformations}
      enableReinitialize
      onSubmit={(values: PhotoMissionInformationsForm, actions) => {
        props.specificInformationsValidation({
          numberOfAxesToFly: values.numberOfAxesToFly,
          totalDurationOfMission: values.totalDurationOfMission,
          actualDurationOfMission: values.actualDurationOfMission,
          requestedDay: values.requestedDay,
          startTime: values.startTime,
          endTime: values.endTime,
          comments: values.comments,
        });
      }}
      validationSchema={Yup.object().shape({
        numberOfAxesToFly: Yup.string().required(
          t("form.enterValidAxesToFly") || ""
        ),
        totalDurationOfMission: Yup.string().required(
          t("form.enterValidTotalDurationOfMission") || ""
        ),
        actualDurationOfMission: Yup.string().required(
          t("form.enterValidActualDurationOfMission") || ""
        ),
        startTime: Yup.string().required(t("form.enterValidstartTime") || ""),
        endTime: Yup.string().required(t("form.enterValidendTime") || ""),
        comments: Yup.string(),
      })}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => {
        return (
          <Form noValidate>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={5} style={{ margin: "5px" }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="numberOfAxesToFly"
                  label="Number of axes to fly"
                  name="numberOfAxesToFly"
                  style={{ color: "white" }}
                  value={values.numberOfAxesToFly}
                  helperText={
                    errors.numberOfAxesToFly && touched.numberOfAxesToFly
                      ? errors.numberOfAxesToFly
                      : ""
                  }
                  error={
                    errors.numberOfAxesToFly && touched.numberOfAxesToFly
                      ? true
                      : false
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={5} style={{ margin: "5px" }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="totalDurationOfMission"
                  label="Total duration of the mission"
                  name="totalDurationOfMission"
                  style={{ color: "white" }}
                  value={values.totalDurationOfMission}
                  helperText={
                    errors.totalDurationOfMission &&
                    touched.totalDurationOfMission
                      ? errors.totalDurationOfMission
                      : ""
                  }
                  error={
                    errors.totalDurationOfMission &&
                    touched.totalDurationOfMission
                      ? true
                      : false
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              {props.origin !== "Project" && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="actualDurationOfMission"
                      label="Actual duration of the mission to fly"
                      name="actualDurationOfMission"
                      style={{ color: "white" }}
                      value={values.actualDurationOfMission}
                      helperText={
                        errors.actualDurationOfMission &&
                        touched.actualDurationOfMission
                          ? errors.actualDurationOfMission
                          : ""
                      }
                      error={
                        errors.actualDurationOfMission &&
                        touched.actualDurationOfMission
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>{" "}
                  <Grid xs={12}>
                    <Stack spacing={3} style={{ marginTop: "12px" }}>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DesktopDatePicker
                          label="Requested day"
                          value={values.requestedDay}
                          inputFormat="dd/MM/yyyy"
                          onChange={(value): void => {
                            setFieldValue("requestedDay", value);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={5} style={{ margin: "12px" }}>
                    <Stack spacing={3} style={{ marginTop: "12px" }}>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <TimePicker
                          label="Start time"
                          value={values.startTime}
                          ampm={false}
                          onChange={(value) => {
                            setFieldValue("startTime", value);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={5} style={{ margin: "12px" }}>
                    <Stack spacing={3} style={{ marginTop: "12px" }}>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <TimePicker
                          label="End time"
                          value={values.endTime}
                          ampm={false}
                          onChange={(value) => {
                            setFieldValue("endTime", value);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="comments"
                  label="Comments"
                  name="comments"
                  multiline
                  rows={4}
                  style={{ color: "white" }}
                  value={values.comments}
                  helperText={
                    errors.comments && touched.comments ? errors.comments : ""
                  }
                  error={errors.comments && touched.comments ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                disabled={props.activeStep === 0}
                onClick={props.handleBack}
                sx={{ mr: 1 }}
                variant="outlined"
              >
                {t("global.back")}
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button variant="contained" type="submit" sx={{ mr: 1 }}>
                {t("global.next")}
              </Button>
            </Box>
            {props.activeStep !== props.steps.length &&
              props.completed[props.activeStep] && (
                <Typography variant="caption" sx={{ display: "inline-block" }}>
                  {t("global.step") +
                    " " +
                    (props.activeStep + 1) +
                    " " +
                    t("global.alreadyCompleted")}
                </Typography>
              )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default PhotoMissionInformations;
