import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddUserForm from "../../Module/accountManagement/AddUserForm";
import { useTranslation } from "react-i18next";

const AddUser = (props: any) => {
  const { t } = useTranslation();

  const userAdded = () => {
    props.successfullAditionUser();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5" style={{ marginRight: "15px" }}>
            {t("account.newUser")}
          </Typography>
          <Grid
            item
            style={{ position: "absolute", right: "12px", top: "12px" }}
          >
            <IconButton
              onClick={() => {
                props.closeModal();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <AddUserForm userAdded={userAdded} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default AddUser;
