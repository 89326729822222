import { TextField, Button, Grid, Box, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

interface UserInformationsForm {
  email: string;
  firstName: string;
  name: string;
}

const UserInformations = (props: any) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={props.userInformations as UserInformationsForm}
      onSubmit={(values: UserInformationsForm, actions) => {
        props.userInformationsValidation(
          values.email,
          values.firstName,
          values.name
        );
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email()
          .required(t("form.enterValidEmail") || ""),
        firstName: Yup.string().required(t("form.enterValidFirstName") || ""),
        name: Yup.string().required(t("form.enterValidName") || ""),
      })}
    >
      {({ values, touched, errors, handleBlur, handleChange }) => {
        return (
          <Form noValidate>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  style={{ color: "white" }}
                  value={values.email}
                  helperText={errors.email && touched.email ? errors.email : ""}
                  error={errors.email && touched.email ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="firstName"
                  label="FirstName"
                  name="firstName"
                  style={{ color: "white" }}
                  value={values.firstName}
                  helperText={
                    errors.firstName && touched.firstName
                      ? errors.firstName
                      : ""
                  }
                  error={errors.firstName && touched.firstName ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  style={{ color: "white" }}
                  value={values.name}
                  helperText={errors.name && touched.name ? errors.name : ""}
                  error={errors.name && touched.name ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={props.activeStep === 0}
                onClick={props.handleBack}
                sx={{ mr: 1 }}
              >
                {t("global.back")}
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button type="submit" sx={{ mr: 1 }}>
                {t("global.next")}
              </Button>
              {props.activeStep !== props.steps.length &&
                props.completed[props.activeStep] && (
                  <Typography
                    variant="caption"
                    sx={{ display: "inline-block" }}
                  >
                    {t("global.step") +
                      " " +
                      (props.activeStep + 1) +
                      " " +
                      t("global.alreadyCompleted")}{" "}
                  </Typography>
                )}
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserInformations;
