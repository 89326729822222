// Page d'accueil de SAMURAI (permet l'authentification)

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import "../../../App.css";
import WarningCloseUpdateModal from "./WarningCloseUpdateModal";
import CloseIcon from "@mui/icons-material/Close";
import UpdateRequestContent from "../Module/UpdateRequestContent";

// import * as Gp from "geoportal-extensions-leaflet";
// const tj = require("@mapbox/togeojson");

const UpdateRequest = (props: any) => {
  const [warningCloseUpdateWindow, setWarningCloseUpdateWindow] =
    useState(false);

  const closeWarningCloseUpdateWindow = () => {
    setWarningCloseUpdateWindow(false);
  };

  const abort = () => {
    setWarningCloseUpdateWindow(false);
    props.closeModal();
  };

  return (
    <Dialog
      open={props.open}
      maxWidth={false}
      fullWidth
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            style={{ position: "absolute", right: "12px", marginTop: "10px" }}
          >
            <IconButton
              onClick={() => {
                setWarningCloseUpdateWindow(true);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          style={{ height: "85vh" }}
        >
          <UpdateRequestContent
            origin={props.origin}
            requestData={props.requestData}
            finish={props.finish}
            height="80"
            heightForm="65"
          />
        </Grid>
      </DialogContent>
      <WarningCloseUpdateModal
        open={warningCloseUpdateWindow}
        closeModal={closeWarningCloseUpdateWindow}
        abort={abort}
        undo={closeWarningCloseUpdateWindow}
      />
    </Dialog>
  );
};

export default UpdateRequest;
