import { DialogActions, Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const ListOfButtonsSaved = (props: any) => {
  const { t } = useTranslation();

  return (
    <DialogActions>
      <Grid
        container
        alignItems="center"
        alignContent="center"
        justifyContent="center"
      >
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            props.selectSubmitWithModification();
          }}
          style={{ marginRight: "10px" }}
        >
          {t("request.submitWithModifications")}
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{ marginRight: "10px" }}
          onClick={() => {
            props.sumbitNow();
          }}
        >
          {t("request.submitNow")}
        </Button>
      </Grid>
    </DialogActions>
  );
};

export default ListOfButtonsSaved;
