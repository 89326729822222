import { TextField, Button, Grid, Box, Typography, Stack } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ScientificFlightInformationsForm {
  departureAerodrome: string;
  arrivalAerodrome: string;
  filedRoad: string;
  requestedDay: DateTime;
  startTime: DateTime;
  endTime: DateTime;
  comments: string;
}

const ScientificFlightInformations = (props: any) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(props.specificInformations).length === 0) {
      setSpecificInformations({
        departureAerodrome: "",
        arrivalAerodrome: "",
        filedRoad: "",
        requestedDay: DateTime.now(),
        startTime: DateTime.now(),
        endTime: DateTime.now(),
        comments: "",
      });
    } else {
      setSpecificInformations(props.specificInformations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [specificInformations, setSpecificInformations] = useState(
    props.specificInformations as ScientificFlightInformationsForm
  );
  return (
    <Formik
      initialValues={specificInformations}
      enableReinitialize
      onSubmit={(values: ScientificFlightInformationsForm, actions) => {
        props.specificInformationsValidation({
          departureAerodrome: values.departureAerodrome,
          arrivalAerodrome: values.arrivalAerodrome,
          filedRoad: values.filedRoad,
          requestedDay: values.requestedDay,
          startTime: values.startTime,
          endTime: values.endTime,
          comments: values.comments,
        });
      }}
      validationSchema={Yup.object().shape({
        departureAerodrome: Yup.string().required(
          t("form.enterValidDepartureAerodrome") || ""
        ),
        arrivalAerodrome: Yup.string().required(
          t("form.enterValidArrivalAerodrome") || ""
        ),
        filedRoad: Yup.string().required(t("form.enterValidFiledRoad") || ""),
        startTime: Yup.string().required(t("form.enterValidstartTime") || ""),
        endTime: Yup.string().required(t("form.enterValidendTime") || ""),
        comments: Yup.string(),
      })}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => {
        return (
          <Form noValidate>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={5} style={{ margin: "10px" }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="departureAerodrome"
                  label="Departure aerodrome"
                  name="departureAerodrome"
                  style={{ color: "white" }}
                  value={values.departureAerodrome}
                  helperText={
                    errors.departureAerodrome && touched.departureAerodrome
                      ? errors.departureAerodrome
                      : ""
                  }
                  error={
                    errors.departureAerodrome && touched.departureAerodrome
                      ? true
                      : false
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={5} style={{ margin: "10px" }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="arrivalAerodrome"
                  label="Arrival aerodrome"
                  name="arrivalAerodrome"
                  style={{ color: "white" }}
                  value={values.arrivalAerodrome}
                  helperText={
                    errors.arrivalAerodrome && touched.arrivalAerodrome
                      ? errors.arrivalAerodrome
                      : ""
                  }
                  error={
                    errors.arrivalAerodrome && touched.arrivalAerodrome
                      ? true
                      : false
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="filedRoad"
                  label="Filed road"
                  name="filedRoad"
                  style={{ color: "white" }}
                  value={values.filedRoad}
                  helperText={
                    errors.filedRoad && touched.filedRoad
                      ? errors.filedRoad
                      : ""
                  }
                  error={errors.filedRoad && touched.filedRoad ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              {props.origin !== "Project" && (
                <>
                  <Grid xs={12}>
                    <Stack spacing={3} style={{ marginTop: "12px" }}>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DesktopDatePicker
                          label="Requested day"
                          value={values.requestedDay}
                          inputFormat="dd/MM/yyyy"
                          onChange={(value): void => {
                            setFieldValue("requestedDay", value);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={5} style={{ margin: "12px" }}>
                    <Stack spacing={3} style={{ marginTop: "12px" }}>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <TimePicker
                          label="Start time"
                          value={values.startTime}
                          ampm={false}
                          onChange={(value) => {
                            setFieldValue("startTime", value);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={5} style={{ margin: "12px" }}>
                    <Stack spacing={3} style={{ marginTop: "12px" }}>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <TimePicker
                          label="End time"
                          value={values.endTime}
                          ampm={false}
                          onChange={(value) => {
                            setFieldValue("endTime", value);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="comments"
                  label="Comments"
                  name="comments"
                  multiline
                  rows={4}
                  style={{ color: "white" }}
                  value={values.comments}
                  helperText={
                    errors.comments && touched.comments ? errors.comments : ""
                  }
                  error={errors.comments && touched.comments ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                disabled={props.activeStep === 0}
                onClick={props.handleBack}
                sx={{ mr: 1 }}
                variant="outlined"
              >
                {t("global.back")}
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button variant="contained" type="submit" sx={{ mr: 1 }}>
                {t("global.next")}
              </Button>
              {props.activeStep !== props.steps.length &&
                props.completed[props.activeStep] && (
                  <Typography
                    variant="caption"
                    sx={{ display: "inline-block" }}
                  >
                    {t("global.step") +
                      " " +
                      (props.activeStep + 1) +
                      " " +
                      t("global.alreadyCompleted")}
                  </Typography>
                )}
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ScientificFlightInformations;
