import { DateTime } from "luxon";

function getAllDatesBeetweenStartAndEnd(
  difference: number,
  start: DateTime
): any[] {
  const newScheduleDays = [];
  for (let index = 0; index < difference; index++) {
    newScheduleDays.push(start);
    start = start.plus({ days: 1 });
  }

  return newScheduleDays;
}

function sortDays(request1: any, request2: any) {
  var dateA = request1.sendingTime;
  var dateB = request2.sendingTime;
  return dateA > dateB ? 1 : -1;
}

function formatingTime(specificInformations: any, flightType: string) {
  if (
    flightType === "Test flight" ||
    flightType === "Scientific flight" ||
    flightType === "Calibration" ||
    flightType === "Photo mission"
  ) {
    return [DateTime.fromISO(specificInformations.requestedDay)];
  } else if (flightType === "Media coverage") {
    const start: DateTime = DateTime.fromISO(
      specificInformations.requestedDayStart
    );
    const end: DateTime = DateTime.fromISO(
      specificInformations.requestedDayEnd
    );

    const dayDiff = Math.round(end.diff(start, "days").days) + 1;
    const newScheduleDays = getAllDatesBeetweenStartAndEnd(dayDiff, start);
    return specificInformations.schedule
      .map((time: string[], index: number) => {
        return newScheduleDays[index];
      })
      .sort(sortDays);
  } else if (flightType === "Various" || flightType === "Skydiving") {
    return specificInformations.requestedDays
      .map((requestedDay: string, index: number) => {
        return DateTime.fromISO(requestedDay);
      })
      .sort(sortDays);
  }
}

// Pagination à voir

export default function sortTimeDataGrid(request1: any, request2: any) {
  const dateA = formatingTime(
    JSON.parse(request1.specificInformations),
    request1.flightType
  )[0];
  const dateB = formatingTime(
    JSON.parse(request2.specificInformations),
    request2.flightType
  )[0];
  return dateA > dateB ? 1 : -1;
}
