import i18next from "i18next";
import { login, signOut, remember, getNotification } from "./ApiService";

// Fichier permettant le stockage des informations de l'utilisateur connecté.
export default class AuthenticationService {
  static isAuthenticated: boolean = false;
  static email: string;
  static lastName: string;
  static firstName: string;
  static token: string;
  static role: string;
  static company: string;
  static favorite: any;
  static notification: any;
  static language: string = "en";
  static isMainAccount: boolean;

  static async login(
    email: string,
    password: string,
    remember: boolean
  ): Promise<String> {
    var isAuthenticated = false;
    this.language = "en";
    const response = await login(email, password)
      .then((userData) => {
        const target =
          userData.role === "Administrator" ? userData.company : email;
        return getNotification(email, userData.token, target)
          .then((data) => {
            this.company = userData.company;
            this.notification = data;
            this.email = email;
            this.token = userData.token;
            isAuthenticated = true;
            this.role = userData.role;
            this.firstName = userData.firstName;
            this.lastName = userData.name;
            this.isMainAccount =
              userData.role === "Administrator"
                ? false
                : userData.isMainAccount;
            this.favorite = userData.favorite
              ? JSON.parse(userData.favorite)
              : "";
            return "OK";
          })
          .catch((error) => {
            console.error(error);
            return "Error";
          });
      })
      .catch((error: any) => {
        return error.response.data;
      });
    if (response !== "OK")
      return new Promise((resolve) => {
        resolve(response);
      });

    this.isAuthenticated = isAuthenticated;
    if (localStorage.getItem("Cookie") === "Yes") {
      localStorage.setItem("token", this.token);
      localStorage.setItem("email", email);
      localStorage.setItem("remember", remember ? "1" : "0");
    }

    return new Promise((resolve) => {
      resolve("OK");
    });
  }

  static testSession(): boolean {
    const rememberCheck = localStorage.getItem("remember");
    if (rememberCheck === "1") {
      return true;
    } else {
      return false;
    }
  }

  static updateLanguage(newLanguage: string) {
    this.language = newLanguage;
    i18next.changeLanguage(newLanguage);
  }

  static async remember(): Promise<string> {
    const token = localStorage.getItem("token") || "";
    const email = localStorage.getItem("email") || "";

    const response = await remember(email, token)
      .then((userData) => {
        const target =
          userData.role === "Administrator" ? userData.company : email;
        return getNotification(email, token, target)
          .then((data) => {
            this.company = userData.company;
            this.notification = data;
            this.email = email;
            this.token = userData.token;
            this.isAuthenticated = true;
            this.role = userData.role;
            this.firstName = userData.firstName;
            this.lastName = userData.name;
            this.isMainAccount =
              userData.role === "Administrator"
                ? false
                : userData.isMainAccount;
            this.favorite = userData.favorite
              ? JSON.parse(userData.favorite)
              : "";
            return "OK";
          })
          .catch((error) => {
            console.error(error);
            return "Error";
          });
      })
      .catch((error) => {
        console.error(error);
        return "Error";
      });

    return new Promise((resolve) => {
      resolve(response);
    });
  }

  static async updateNotification() {
    const target = this.role === "Administrator" ? this.company : this.email;
    const response = await getNotification(this.email, this.token, target).then(
      (data) => {
        if (
          data.request.length !== this.notification.request.length ||
          data.project.length !== this.notification.project.length
        ) {
          this.notification = data;
          return true;
        } else {
          return false;
        }
      }
    );
    return new Promise((resolve) => {
      resolve(response);
    });
  }

  static async logout() {
    await signOut(this.email, this.token)
      .then((response) => {
        if (response === "Disconnected") this.isAuthenticated = false;
      })
      .catch((error) => {
        console.error(error);
      });
    return new Promise((resolve) => {
      resolve(this.isAuthenticated);
    });
  }
}
