import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import ApprovedIfForm from "../Module/Form/ApprovedIfForm";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { listAllRequestOfCenter } from "../../../Service/ApiService";
import AuthenticationService from "../../../Service/AuthenticationService";
import { useSnackbar } from "notistack";

const ApprovedIf = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const listOfRequest = async () => {
    return listAllRequestOfCenter(
      AuthenticationService.company,
      AuthenticationService.email,
      AuthenticationService.token
    )
      .then((newData: any) => {
        setRequests(newData);
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  useEffect(() => {
    listOfRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [requests, setRequests] = useState([]);
  const processRequest = (state: string, reason: string) => {
    props.conditionalProcessing(state, reason);
  };
  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5" style={{ marginRight: "15px" }}>
            {t("request.conditionallyAccept")}
          </Typography>
          <Grid
            item
            style={{ position: "absolute", right: "12px", top: "12px" }}
          >
            <IconButton
              onClick={() => {
                props.closeModal();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          <ApprovedIfForm processRequest={processRequest} requests={requests} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
export default ApprovedIf;
