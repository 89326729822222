import { Dialog } from "@mui/material";
import { useState } from "react";
import UpdateRole from "./ManageUser/UpdateRole";
const ManageUser = (props: any) => {
  const [currentChoice, setCurrentChoice] = useState("UpdateRole");
  const changeCurrentChoice = (newCurrentChoice: string) => {
    setCurrentChoice(newCurrentChoice);
  };
  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      aria-labelledby="draggable-dialog-title"
    >
      {currentChoice === "UpdateRole" && (
        <UpdateRole
          user={props.user}
          closeModal={props.closeModal}
          updateListUser={props.updateListUser}
          changeCurrentChoice={changeCurrentChoice}
        />
      )}
    </Dialog>
  );
};
export default ManageUser;
