import {
  Grid,
  Button,
  Typography,
  MenuItem,
  TextField,
  Card,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { listRequest } from "../../../Service/ApiService";
import AuthenticationService from "../../../Service/AuthenticationService";
import { useSnackbar } from "notistack";
import { ProcessingGetRequest } from "../../ShareFonction/ProcessignGetRequest";

const LoadRequestFromProject = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const listOfProject = async () => {
    return listRequest(
      AuthenticationService.email,
      AuthenticationService.token,
      false,
      true
    )
      .then((newData) => {
        seRequests(newData);
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const getRequestData = () => {
    ProcessingGetRequest(
      selectedRequest.id,
      AuthenticationService.email,
      AuthenticationService.token,
      AuthenticationService.role,
      selectedRequest.centers
    ).then((requestData: any) => {
      const centers: any = [];
      requestData.globalInformations.center.forEach((center: any) => {
        centers.push(center.center);
      });
      requestData.globalInformations.center = centers;
      props.selectedRequestData(requestData);
    });
  };

  useEffect(() => {
    listOfProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedRequest, setSelectedRequest] = useState({} as any);
  const [requests, seRequests] = useState([]);

  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      style={{ height: "98vh" }}
    >
      <Card
        elevation={8}
        style={{ margin: "10px", height: "40vh", width: "50%" }}
      >
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          style={{
            height: "40vh",
          }}
        >
          <Grid item xs={10}>
            <Typography>{t("request.createNewRequestFromProject")}</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="selectedRequest"
              name="selectedRequest"
              select
              style={{ color: "white", textAlign: "left", height: "10vh" }}
              value={selectedRequest}
              onChange={(event: any) => {
                setSelectedRequest(event.target.value);
              }}
            >
              {requests.map((option: any) => (
                <MenuItem key={option.id} value={option}>
                  {option.referenceNumber}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={10}>
            <Button variant="contained" onClick={getRequestData}>
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
export default LoadRequestFromProject;
