import { Edit, InsertPhoto } from "@mui/icons-material";
import {
  Grid,
  Button,
  CardMedia,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import { Formik, Form } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./editor.css";
interface ProtecCalibrationFormData {
  instructionOnSector: string;
  cdsInstruction: string;
  callingNumber: string;
}

const ProtectCalibrationForm = (props: any) => {
  const [map, setMap] = useState(
    props.protecInformations.informations?.map as any
  );
  const [update, setUpdate] = useState(false);
  const getBase64 = (file: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setMap(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  return (
    <Formik
      initialValues={
        {
          instructionOnSector:
            props.protecInformations.informations?.instructionOnSector || "",
          cdsInstruction:
            props.protecInformations.informations?.cdsInstruction || "",
          callingNumber:
            props.protecInformations.informations?.callingNumber || "",
        } as ProtecCalibrationFormData
      }
      onSubmit={(values: ProtecCalibrationFormData, actions) => {
        const newProtecInformations = {
          instructionOnSector: values.instructionOnSector,
          cdsInstruction: values.cdsInstruction,
          callingNumber: values.callingNumber,
        };
        setUpdate(false);

        props.updateProtecInformations(
          newProtecInformations,
          props.protecInformations.id
        );
      }}
      validationSchema={Yup.object().shape({
        instructionOnSector: Yup.string(),
        cdsInstruction: Yup.string(),
        callingNumber: Yup.string(),
      })}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => {
        return (
          <Form noValidate>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={6}>
                <Divider textAlign="center" style={{ margin: "10px" }}>
                  <Typography>Instruction on sector</Typography>
                </Divider>
                <ReactQuill
                  readOnly={!update}
                  style={{
                    margin: "10px",
                    backgroundColor: !update ? "#F2E3D5" : "",
                  }}
                  placeholder="Write something..."
                  theme="snow"
                  value={values.instructionOnSector}
                  onChange={(value) =>
                    setFieldValue("instructionOnSector", value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Divider textAlign="center" style={{ margin: "10px" }}>
                  <Typography>CDS instruction</Typography>
                </Divider>
                <ReactQuill
                  readOnly={!update}
                  theme="snow"
                  style={{
                    margin: "10px",
                    backgroundColor: !update ? "#F2E3D5" : "",
                  }}
                  value={values.cdsInstruction}
                  placeholder="Write something..."
                  onChange={(value) => setFieldValue("cdsInstruction", value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Divider textAlign="center" style={{ margin: "10px" }}>
                  <Typography>Calling number</Typography>
                </Divider>
                <ReactQuill
                  readOnly={!update}
                  style={{
                    margin: "10px",
                    backgroundColor: !update ? "#F2E3D5" : "",
                  }}
                  theme="snow"
                  value={values.callingNumber}
                  placeholder="Write something..."
                  onChange={(value) => setFieldValue("callingNumber", value)}
                />
              </Grid>
              <Grid item xs={6} style={{ textAlign: "center" }}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  sx={{
                    maxWidth: 280,
                    margin: "0 auto",
                    padding: "0.1em",
                  }}
                >
                  <Grid item xs={12}>
                    <IconButton
                      aria-label="upload picture"
                      component="label"
                      style={{ backgroundColor: "#FA5D5D", color: "white" }}
                    >
                      <input
                        disabled={!update}
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={(e) => {
                          if (e.target.files !== null)
                            getBase64(e.target.files[0]);
                        }}
                      />
                      <InsertPhoto />
                    </IconButton>
                  </Grid>
                  {map !== null && map !== undefined && (
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <CardMedia
                        height="250"
                        sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                        component="img"
                        src={`data:image/png;base64 ${map}`}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {update && (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Button type="submit" variant="outlined">
                    CONFIRM
                  </Button>
                </Grid>
              )}
              {!update && (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Button
                    variant="outlined"
                    type="button"
                    onClick={() => setUpdate(true)}
                  >
                    <Edit />
                  </Button>
                </Grid>
              )}
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProtectCalibrationForm;
