import { Grid, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import getAllDatesBeetweenStartAndEnd from "../../../../ShareFonction/ListAllDaysOfSchedule";
import { useTranslation } from "react-i18next";

const MediaCoverageSummary = (props: any) => {
  const { t } = useTranslation();

  const [schedulesDays, setSchedulesDays] = useState([
    DateTime.now().toFormat("dd/MM"),
  ]);

  useEffect(() => {
    const start: DateTime = props.specificInformations.requestedDayStart;
    const end: DateTime = props.specificInformations.requestedDayEnd;

    const dayDiff = Math.round(end.diff(start, "days").days) + 1;
    const newScheduleDays = getAllDatesBeetweenStartAndEnd(dayDiff, start);
    setSchedulesDays(newScheduleDays);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item xs={12}>
        <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
          {t("request.summary.departureAerodrome") +
            " : " +
            props.specificInformations.departureAerodrome}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
          {t("request.summary.arrivalAerodrome") +
            " : " +
            props.specificInformations.arrivalAerodrome}
        </Typography>
      </Grid>
      {props.origin.indexOf("Project") === -1 && (
        <>
          <Grid item xs={12}>
            <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
              {t("request.summary.requestedDayStart") +
                " : " +
                props.specificInformations.requestedDayStart.toFormat(
                  "dd-MM-yyyy"
                )}
            </Typography>
          </Grid>
          {props.specificInformations.requestedDayEnd.diff(
            props.specificInformations.requestedDayStart,
            "days"
          ).days > 0.9 && (
            <Grid item xs={12}>
              <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
                {t("request.summary.requestedDayEnd") +
                  " : " +
                  props.specificInformations.requestedDayEnd.toFormat(
                    "dd-MM-yyyy"
                  )}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
              {t("request.summary.time") + " : "}
              <ul>
                {props.specificInformations.schedule.map(
                  (time: DateTime[], index: number) => {
                    return (
                      <li key={index}>
                        {schedulesDays[index]} : {time[0].toFormat("HHmm")}UTC -{" "}
                        {time[1].toFormat("HHmm")}UTC
                      </li>
                    );
                  }
                )}
              </ul>
            </Typography>
          </Grid>
        </>
      )}
      {props.specificInformations.comments && (
        <Grid item xs={12}>
          <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
            {t("request.summary.comments") +
              " : " +
              props.specificInformations.comments}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default MediaCoverageSummary;
