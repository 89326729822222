// Page d'accueil de SAMURAI (permet l'authentification)

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import "../../../App.css";
import RequestsDataGrid from "../Module/DataGrid/RequestsDataGrid";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "#1F2440",
  },
  error: {
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
  },
  center: {
    textAlign: "center",
  },
  wait: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
  },
  paper: {
    backgroundColor: "rgba(255, 255, 255, .7)",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
  },
  avatar: {},
  form: {
    width: "80%",
  },
  signUp: {
    marginTop: "14px",
  },
  touch: {
    "&:hover": { cursor: "pointer" },
  },
}));

const Requests = (props: any) => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      className={classes.wait}
    >
      <Grid
        item
        xs={12}
        style={{
          minHeight: "2vh",
          backgroundColor: "#2D3E4E",
        }}
      />
      <Grid item xs={12}>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          style={{ height: "98vh" }}
        >
          <Grid item xs={11}>
            <RequestsDataGrid
              changeCurrentPage={props.changeCurrentPage}
              updateNotification={props.updateNotification}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Requests;
