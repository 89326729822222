import { Button, CardMedia, Grid, Tooltip } from "@mui/material";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DownloadingIcon from "@mui/icons-material/Downloading";
import ProtecExportPhotoMission from "./Export/ProtecExportPhotoMission";
import ProtecExportCalibration from "./Export/ProtecExportCalibration";
import ProtecExportTestFlight from "./Export/ProtecExportTestFlight";
import ProtecExportMediaCoverage from "./Export/ProtecExportMediaCoverage";
import ProtectMediaCoverageForm from "./Form/ProtecMediaCoverageForm";
import ProtectCalibrationForm from "./Form/ProtecCalibrationForm";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import AuthenticationService from "../../../../Service/AuthenticationService";
import { updateProtecInformations } from "../../../../Service/ApiService";
import ProtectPhotoMissionForm from "./Form/ProtecPhotoMissionForm";
import ProtectTestFlightForm from "./Form/ProtecTestFlightForm";
import "./export.css";

const ProtecInformationsSummary = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef<HTMLDivElement>(null);

  const updateProtecInformation = (
    newProtecInformations: any,
    idProtec: string
  ) => {
    updateProtecInformations(
      AuthenticationService.token,
      AuthenticationService.email,
      newProtecInformations,
      idProtec
    )
      .then(() => {
        props.updateProtecInformations(newProtecInformations);
        //protecInformations.informations = newProtecInformations;
        enqueueSnackbar(t("protec.protecUpdate"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  return (
    <Grid container justifyContent="center" alignContent="center">
      <Grid item xs={12}>
        {props.missionType === "Media coverage" && (
          <>
            <ProtectMediaCoverageForm
              protecInformations={props.protecInformations}
              updateProtecInformations={updateProtecInformation}
            />
            {props.requestData.protec.informations && (
              <Grid item xs={12}>
                <div style={{ overflow: "hidden", height: 0 }}>
                  <style type="text/css" media="print">
                    {
                      "\
  @page { size: landscape; }\
"
                    }
                  </style>
                  <Grid container ref={ref} className="test">
                    <Grid item xs={12}>
                      <ProtecExportMediaCoverage
                        requestData={props.requestData}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        textAlign: "center",
                        height: "800px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CardMedia
                        style={{
                          height: "70%",
                          width: "70%",
                        }}
                        component="img"
                        src={`data:image/png;base64 ${props.requestData.protec.informations.map}`}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            )}
            <ReactToPrint
              trigger={() => (
                <Tooltip title="Download protec without update">
                  <Button
                    variant="contained"
                    style={{
                      margin: "15px",
                      height: "30px",
                      minWidth: "30px",
                      maxWidth: "30px",
                    }}
                  >
                    <DownloadForOfflineIcon />
                  </Button>
                </Tooltip>
              )}
              content={() => ref.current}
            />
            <Tooltip title="Download protec with update">
              <Button
                variant="contained"
                style={{
                  margin: "15px",
                  height: "30px",
                  minWidth: "30px",
                  maxWidth: "30px",
                }}
              >
                <DownloadingIcon />
              </Button>
            </Tooltip>
          </>
        )}
        {props.missionType === "Photo mission" && (
          <>
            <ProtectPhotoMissionForm
              protecInformations={props.protecInformations}
              updateProtecInformations={updateProtecInformation}
            />
            {props.requestData.protec.informations && (
              <Grid item xs={12}>
                <div style={{ overflow: "hidden", height: 0 }}>
                  <style type="text/css" media="print">
                    {
                      "\
  @page { size: landscape; }\
"
                    }
                  </style>
                  <Grid container ref={ref} className="test">
                    <Grid item xs={12}>
                      <ProtecExportPhotoMission
                        requestData={props.requestData}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        textAlign: "center",
                        height: "100vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CardMedia
                        style={{
                          height: "70vh",
                          width: "70vw",
                        }}
                        component="img"
                        src={`data:image/png;base64 ${props.requestData.protec.informations.map}`}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            )}

            <ReactToPrint
              trigger={() => (
                <Tooltip title="Download protec without update">
                  <Button
                    variant="contained"
                    style={{
                      margin: "15px",
                      height: "30px",
                      minWidth: "30px",
                      maxWidth: "30px",
                    }}
                  >
                    <DownloadForOfflineIcon />
                  </Button>
                </Tooltip>
              )}
              content={() => ref.current}
            />
            <Tooltip title="Download protec with update">
              <Button
                variant="contained"
                style={{
                  margin: "15px",
                  height: "30px",
                  minWidth: "30px",
                  maxWidth: "30px",
                }}
              >
                <DownloadingIcon />
              </Button>
            </Tooltip>
          </>
        )}
        {props.missionType === "Calibration" && (
          <>
            <ProtectCalibrationForm
              protecInformations={props.protecInformations}
              updateProtecInformations={updateProtecInformation}
            />
            {props.requestData.protec.informations && (
              <Grid item xs={12}>
                <div style={{ overflow: "hidden", height: 0 }}>
                  <style type="text/css" media="print">
                    {
                      "\
  @page { size: landscape; }\
"
                    }
                  </style>
                  <Grid container ref={ref} className="test">
                    <Grid item xs={12}>
                      <ProtecExportCalibration
                        requestData={props.requestData}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        textAlign: "center",
                        height: "100vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CardMedia
                        style={{
                          height: "70vh",
                          width: "70vw",
                        }}
                        component="img"
                        src={`data:image/png;base64 ${props.requestData.protec.informations.map}`}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            )}

            <ReactToPrint
              trigger={() => (
                <Tooltip title="Download protec without update">
                  <Button
                    variant="contained"
                    style={{
                      margin: "15px",
                      height: "30px",
                      minWidth: "30px",
                      maxWidth: "30px",
                    }}
                  >
                    <DownloadForOfflineIcon />
                  </Button>
                </Tooltip>
              )}
              content={() => ref.current}
            />
            <Tooltip title="Download protec with update">
              <Button
                variant="contained"
                style={{
                  margin: "15px",
                  height: "30px",
                  minWidth: "30px",
                  maxWidth: "30px",
                }}
              >
                <DownloadingIcon />
              </Button>
            </Tooltip>
          </>
        )}
        {props.missionType === "Test flight" && (
          <>
            <ProtectTestFlightForm
              protecInformations={props.protecInformations}
              updateProtecInformations={updateProtecInformation}
            />
            {props.requestData.protec.informations && (
              <Grid item xs={12}>
                <div style={{ overflow: "hidden", height: 0 }}>
                  <style type="text/css" media="print">
                    {
                      "\
  @page { size: landscape; }\
"
                    }
                  </style>
                  <Grid container ref={ref} className="test">
                    <Grid item xs={12}>
                      <ProtecExportTestFlight
                        requestData={props.requestData}
                        map={props.requestData.protec.informations.map}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            )}
            <ReactToPrint
              trigger={() => (
                <Tooltip title="Download protec without update">
                  <Button
                    variant="contained"
                    style={{
                      margin: "15px",
                      height: "30px",
                      minWidth: "30px",
                      maxWidth: "30px",
                    }}
                  >
                    <DownloadForOfflineIcon />
                  </Button>
                </Tooltip>
              )}
              content={() => ref.current}
            />
            <Tooltip title="Download protec with update">
              <Button
                variant="contained"
                style={{
                  margin: "15px",
                  height: "30px",
                  minWidth: "30px",
                  maxWidth: "30px",
                }}
              >
                <DownloadingIcon />
              </Button>
            </Tooltip>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ProtecInformationsSummary;
