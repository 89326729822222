import { useState } from "react";
import { listCompletedRequest } from "../../../../Service/ApiService";
import AuthenticationService from "../../../../Service/AuthenticationService";
import AdminDataGrid from "../AdminDataGrid";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const CompletedDataGrid = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const listOfRequest = async () => {
    return listCompletedRequest(
      AuthenticationService.email,
      AuthenticationService.token,
      AuthenticationService.company
    )
      .then((newData: any) => {
        return newData;
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const [origin, setOrigin] = useState("");

  const updateOrigin = (newOrigin: string) => {
    setOrigin(newOrigin);
  };

  const selectRequestData = () => {
    setOrigin("Completed");
  };

  return (
    <AdminDataGrid
      listOfRequest={listOfRequest}
      selectRequestData={selectRequestData}
      origin="Completed"
      originSummmary={origin}
      updateOrigin={updateOrigin}
    />
  );
};

export default CompletedDataGrid;
