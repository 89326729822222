import { Grid, Button } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useTranslation } from "react-i18next";

const ListOfButtonsProcessing = (props: any) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      alignItems="center"
      alignContent="center"
      justifyContent="center"
    >
      {props.state !== "processing" && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{
            marginRight: "10px",
            maxHeight: "30px",
            minHeight: "30px",
            maxWidth: "30px",
            minWidth: "30px",
            textAlign: "center",
          }}
          onClick={() => {
            props.updateOrigin("stateDefined");
          }}
        >
          <KeyboardArrowLeftIcon />
        </Button>
      )}
      <Button
        variant="contained"
        size="small"
        color="error"
        style={{ marginRight: "10px" }}
        onClick={() => {
          props.selectAction("rejected");
        }}
      >
        {t("request.rejectButton")}
      </Button>
      <Button
        variant="contained"
        size="small"
        color="warning"
        style={{ marginRight: "10px" }}
        onClick={() => {
          props.selectAction("Approved if");
        }}
      >
        {t("request.approveUnderConditionButton")}
      </Button>
      <Button
        variant="contained"
        size="small"
        color="success"
        onClick={() => {
          props.selectAction("accepted");
        }}
      >
        {t("request.acceptButton")}
      </Button>
    </Grid>
  );
};

export default ListOfButtonsProcessing;
