import { useCallback, useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, GeoJSON, LayersControl } from "react-leaflet";
import L from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import LayerControle from "./LayerControle";
import { hashString } from "../../ShareFonction/map";

const ApplicantMap = (props: any) => {
  const geoJsonLine: any = useRef(null);

  const [kmlFile, setKMLFile] = useState(null as any);
  const [layers, setLayers] = useState([] as any);
  const [line, setLine] = useState(null as any);

  useEffect(() => {
    setKMLFile(props.file);
    setLayers(props.mapInformations.layer);
    if (props.mapInformations.line && geoJsonLine.current) {
      geoJsonLine.current.clearLayers().addData(props.mapInformations.line);
    }
    setLine(props.mapInformations.line);
  }, [props.mapInformations, props.file]);

  const pointToLayer = useCallback((feature: any, latlng: any) => {
    if (feature.properties && feature.properties.icon) {
      return L.marker(latlng, {
        icon: L.icon({
          iconUrl: feature.properties.icon,
          iconSize: new L.Point(20, 20),
        }),
        title: feature.properties.name ? feature.properties.name : " ",
      });
      // .bindTooltip(feature.properties.name ? feature.properties.name : " ", {
      //   permanent: true,
      //   direction: "bottom",
      //   className: "transparent-tooltip",
      //   offset: [0, -8],
      // });
    }

    return L.marker(latlng, {
      icon: L.icon({
        iconUrl: markerIconPng,
        iconSize: new L.Point(15, 15),
      }),
    });
  }, []);
  const onEachFeature = useCallback((feature: any, layer: any) => {
    if (feature.properties && feature.properties.popupContent) {
      layer.bindPopup(feature.properties.popupContent, {
        autoClose: false,
      });
    }
  }, []);

  const style = (feature: any) => {
    return {
      fillColor: feature.properties.fill ? feature.properties.fill : "red",
      fillOpacity: feature.properties.fillOpacity
        ? feature.properties.fillOpacity
        : "0.6",
      stroke: feature.properties.stroke
        ? feature.properties.stroke
        : "rgba(0,0,0,0.5)",
      strokeOpacity: feature.properties.strokeOpacity
        ? feature.properties.strokeOpacity
        : 1,
      strokeWidth: feature.properties.strokeWidth
        ? feature.properties.strokeWidth
        : 1,
      weight: 2,
      color: feature.properties.color
        ? feature.properties.color
        : feature.properties.fill
        ? " "
        : "black",
    };
  };
  return (
    <div>
      <MapContainer
        center={[48.866667, 2.333333]}
        style={{
          height: props.height + "vh",
          width: "100%",
        }}
        zoom={8}
        maxZoom={11}
      >
        <TileLayer
          subdomains={["clarity"]}
          url="https://{s}.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />

        <LayersControl position="topright">
          <LayerControle kmlFile={kmlFile} layers={layers} />
        </LayersControl>
        {line && (
          <GeoJSON
            ref={geoJsonLine}
            data={line}
            style={style}
            onEachFeature={onEachFeature}
            pointToLayer={pointToLayer}
          />
        )}
        <TileLayer
          subdomains={["clarity"]}
          url="https://wxs.ign.fr/an7nvfzojv5wa96dsga5nk8w/geoportail/wmts?layer=GEOGRAPHICALGRIDSYSTEMS.MAPS.SCAN-OACI&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileCol={x}&TileRow={y}"
          attribution="IGN-F/Géoportail"
          maxZoom={12}
        />

        {kmlFile && (
          <GeoJSON
            key={hashString(JSON.stringify(kmlFile))}
            data={kmlFile}
            pointToLayer={pointToLayer}
            onEachFeature={onEachFeature}
            style={style}
          />
        )}
      </MapContainer>
    </div>
  );
};

export default ApplicantMap;
