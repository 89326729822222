import { TextField, Button, Grid, Box, Typography } from "@mui/material";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import SingleDay from "./MediaCoverageRadio/SingleDay";
import SeveralDays from "./MediaCoverageRadio/SeveralDays";
import { useTranslation } from "react-i18next";
interface MediaCoverageInformationsForm {
  departureAerodrome: string;
  arrivalAerodrome: string;
  requestedDayStart: DateTime;
  requestedDayEnd: DateTime;
  schedule: DateTime[];
  comments: string;
}

const MediaCoverageInformations = (props: any) => {
  const { t } = useTranslation();

  const [uniqueDay, setUniqueDay] = useState("");
  const [loading, setLoading] = useState(true);
  const [specificInformations, setSpecificInformations] = useState({
    departureAerodrome: "",
    arrivalAerodrome: "",
    requestedDayStart: DateTime.now(),
    requestedDayEnd: DateTime.now(),
    schedule: [],
    comments: "",
  } as MediaCoverageInformationsForm);

  useEffect(() => {
    if (Object.keys(props.specificInformations).length !== 0) {
      setSpecificInformations(props.specificInformations);
      if (
        props.specificInformations.requestedDayEnd.toFormat("DD-mm-yyyy") !==
        props.specificInformations.requestedDayStart.toFormat("DD-mm-yyyy")
      ) {
        setUniqueDay("severalDays");
      } else {
        setUniqueDay("singleDay");
      }
    } else {
      setUniqueDay("singleDay");
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!loading && (
        <Formik
          initialValues={specificInformations as MediaCoverageInformationsForm}
          onSubmit={(values: MediaCoverageInformationsForm, actions) => {
            if (values.requestedDayStart <= values.requestedDayEnd) {
              props.specificInformationsValidation({
                departureAerodrome: values.departureAerodrome,
                arrivalAerodrome: values.arrivalAerodrome,
                requestedDayStart: values.requestedDayStart,
                requestedDayEnd: values.requestedDayEnd,
                schedule: values.schedule,
                comments: values.comments,
              });
            }
          }}
          validationSchema={Yup.object().shape({
            departureAerodrome: Yup.string().required(
              t("form.enterValidDepartureAerodrome") || ""
            ),
            arrivalAerodrome: Yup.string().required(
              t("form.enterValidArrivalAerodrome") || ""
            ),
            // schedule: Yup.string().required("Enter a valid code"),
          })}
        >
          {({
            values,
            touched,
            errors,
            setFieldValue,
            handleBlur,
            handleChange,
          }) => {
            return (
              <Form noValidate>
                <Grid
                  container
                  alignContent="center"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={5} style={{ margin: "10px" }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="departureAerodrome"
                      label="Departure aerodrome"
                      multiline
                      rows={2}
                      name="departureAerodrome"
                      style={{ color: "white" }}
                      value={values.departureAerodrome}
                      helperText={
                        errors.departureAerodrome && touched.departureAerodrome
                          ? errors.departureAerodrome
                          : ""
                      }
                      error={
                        errors.departureAerodrome && touched.departureAerodrome
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={5} style={{ margin: "10px" }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="arrivalAerodrome"
                      label="Arrival aerodrome"
                      multiline
                      rows={2}
                      name="arrivalAerodrome"
                      style={{ color: "white" }}
                      value={values.arrivalAerodrome}
                      helperText={
                        errors.arrivalAerodrome && touched.arrivalAerodrome
                          ? errors.arrivalAerodrome
                          : ""
                      }
                      error={
                        errors.arrivalAerodrome && touched.arrivalAerodrome
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  {props.origin !== "Project" && (
                    <>
                      <Grid xs={12}>
                        <FormControl>
                          <FormLabel id="demo-controlled-radio-buttons-group">
                            {t("form.numberOfDays")}
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={uniqueDay}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (
                                (event.target as HTMLInputElement).value ===
                                "singleDay"
                              ) {
                                setFieldValue(
                                  "requestedDayStart",
                                  DateTime.now()
                                );
                                setFieldValue(
                                  "requestedDayEnd",
                                  DateTime.now()
                                );
                                setFieldValue("schedule", []);
                              } else if (
                                (event.target as HTMLInputElement).value ===
                                "severalDays"
                              ) {
                                if (props.schedule === undefined) {
                                  setFieldValue(
                                    "requestedDayEnd",
                                    DateTime.now().plus({ days: 1 })
                                  );
                                }
                              }

                              setUniqueDay(
                                (event.target as HTMLInputElement).value
                              );
                            }}
                          >
                            <FormControlLabel
                              value="singleDay"
                              control={<Radio />}
                              label="Single day"
                            />
                            <FormControlLabel
                              value="severalDays"
                              control={<Radio />}
                              label="Several days"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {uniqueDay === "singleDay" && (
                        <SingleDay
                          requestedDay={values.requestedDayStart}
                          schedule={values.schedule}
                        />
                      )}
                      {uniqueDay === "severalDays" && (
                        <SeveralDays
                          requestedDay={values.requestedDayStart}
                          schedule={values.schedule}
                        />
                      )}
                    </>
                  )}

                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="comments"
                      label="Comments"
                      name="comments"
                      multiline
                      rows={4}
                      style={{ color: "white" }}
                      value={values.comments}
                      helperText={
                        errors.comments && touched.comments
                          ? errors.comments
                          : ""
                      }
                      error={errors.comments && touched.comments ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    variant="outlined"
                    disabled={props.activeStep === 0}
                    onClick={props.handleBack}
                    sx={{ mr: 1 }}
                  >
                    {t("global.back")}
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button variant="contained" type="submit" sx={{ mr: 1 }}>
                    {t("global.next")}
                  </Button>
                </Box>
                {props.activeStep !== props.steps.length &&
                  props.completed[props.activeStep] && (
                    <Typography
                      variant="caption"
                      sx={{ display: "inline-block" }}
                    >
                      {t("global.step") +
                        " " +
                        (props.activeStep + 1) +
                        " " +
                        t("global.alreadyCompleted")}
                    </Typography>
                  )}
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default MediaCoverageInformations;
