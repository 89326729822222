import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Button,
  Typography,
  IconButton,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const DeleteRequest = (props: any) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5" style={{ marginRight: "30px" }}>
            {t("request.deleteModalTitle")}
          </Typography>
          <Grid item style={{ position: "absolute", right: "12px" }}>
            <IconButton
              onClick={() => {
                props.closeModal();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            {parse(t("request.deleteModalContent"))}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                props.confirmDeleteRequest();
              }}
            >
              {t("global.confirm")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteRequest;
