import * as React from "react";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SummaryOfRequest from "./SummaryRequest";
import { Grid, IconButton, Tooltip } from "@mui/material";
import AuthenticationService from "../../Service/AuthenticationService";
import ProtecInformationsSummary from "../Admin/Module/Protec/ProtecInformationsSummary";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const TabPannelSwitcheur = (props: any) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
      <AppBar position="static" style={{ backgroundColor: "#2D3E4E" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={10}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Typography
                variant="h5"
                style={{ marginRight: "15px" }}
                textAlign="center"
              >
                {props.origin === "Project"
                  ? t("project.summaryProject")
                  : t("request.summary.summaryRequest")}
              </Typography>
              {props.requestData.state?.toString() !== "deleted" && (
                <Tooltip title={props.requestData.state}>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "20px",
                      background:
                        AuthenticationService.role === "Administrator"
                          ? props.requestData.state?.toString() === "acceptedIf"
                            ? "#A0B9D9"
                            : props.requestData.state?.toString() === "rejected"
                            ? "#F28585"
                            : props.requestData.state?.toString() === "accepted"
                            ? "#B4CF66"
                            : "#F2B263"
                          : props.requestData.state?.toString() ===
                            "Partially accepted"
                          ? "#A0B9D9"
                          : props.requestData.state?.toString() === "Rejected"
                          ? "#F28585"
                          : props.requestData.state?.toString() === "Accepted"
                          ? "#B4CF66"
                          : "#F2B263",
                      marginRight: "5px",
                    }}
                  ></div>
                </Tooltip>
              )}
              <Grid
                item
                style={{ position: "absolute", right: "30px", top: "35px" }}
              >
                <IconButton
                  style={{
                    backgroundColor: "white",
                    maxHeight: "20px",
                    minHeight: "20px",
                    maxWidth: "20px",
                    minWidth: "20px",
                  }}
                  onClick={() => {
                    props.closeModal();
                  }}
                >
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Tabs
              value={value}
              variant="fullWidth"
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#fce4ec",
                },
              }}
              textColor="inherit"
              aria-label="full width tabs example"
            >
              <Tab label={t("protec.informations")} {...a11yProps(0)} />
              {AuthenticationService.role === "Administrator" && (
                <Tab label={t("protec.protec")} {...a11yProps(1)} />
              )}
            </Tabs>
          </Grid>
        </Grid>
      </AppBar>

      <TabPanel value={value} index={0} dir={theme.direction}>
        <SummaryOfRequest
          requestData={props.requestData}
          origin={props.origin}
          closeModal={props.closeModal}
          missionType={props.requestData.globalInformations.flightType}
          selectAction={props.selectAction}
          updateOrigin={props.updateOrigin}
          openProtec={props.openProtec}
          state={props.requestData.state}
          reSubmitRequest={props.reSubmitRequest}
          updateRequest={props.updateRequest}
          validDelete={props.validDelete}
          sumbitNow={props.sumbitNow}
          selectSubmitWithModification={props.selectSubmitWithModification}
        />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        {AuthenticationService.role === "Administrator" &&
          props.requestData.state !== undefined && (
            <ProtecInformationsSummary
              updateProtecInformations={props.updateProtecInformations}
              protecInformations={props.requestData.protec}
              openProtec={props.openProtec}
              requestData={props.requestData}
              missionType={props.requestData.globalInformations.flightType}
            />
          )}
      </TabPanel>
    </Box>
  );
};

export default TabPannelSwitcheur;
