import { Button, Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ListOfButtonsProcessing from "../Admin/Module/ListOfButtons/ListOfButtonsProcessing";
import ListOfButtonsProcessingProject from "../Admin/Module/ListOfButtons/ListOfButtonsProcessingProject";
import ShowStatusAndModification from "../Admin/Module/accountManagement/ShowStatusAndModification";
import ListOfButtonsProject from "../User/Module/ListOfButtons/ListOfButonsProject";
import ListOfButtonsSaved from "../User/Module/ListOfButtons/ListOfButtonsSaved";
import ListOfButtonsUpdate from "../User/Module/ListOfButtons/ListOfButtonsUpdate";
import DownloadIcon from "@mui/icons-material/Download";
import AuthenticationService from "../../Service/AuthenticationService";

const ListOfButtons = (props: any) => {
  return (
    <Grid item xs={12} style={{ margin: "20px" }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item>
          {(props.origin === "stateDefined" ||
            props.origin === "stateDefinedProject") && (
            <ShowStatusAndModification
              closeModal={props.closeModal}
              missionType={props.requestData.globalInformations.flightType}
              selectAction={props.selectAction}
              requestData={props.requestData}
              updateOrigin={props.updateOrigin}
              origin={props.origin}
              openProtec={props.openProtec}
            />
          )}
          {props.origin === "processing" && (
            <ListOfButtonsProcessing
              closeModal={props.closeModal}
              selectAction={props.selectAction}
              state={props.requestData.state}
              updateOrigin={props.updateOrigin}
            />
          )}
          {props.origin === "processingProject" && (
            <ListOfButtonsProcessingProject
              closeModal={props.closeModal}
              selectAction={props.selectAction}
              state={props.requestData.state}
              updateOrigin={props.updateOrigin}
            />
          )}
          {props.origin === "update" && (
            <ListOfButtonsUpdate
              closeModal={props.closeModal}
              reSubmitRequest={props.reSubmitRequest}
              updateRequest={props.updateRequest}
              requestData={props.requestData}
            />
          )}
          {props.origin === "save" && (
            <ListOfButtonsSaved
              closeModal={props.closeModal}
              reSubmitRequest={props.reSubmitRequest}
              updateRequest={props.updateRequest}
              requestData={props.requestData}
              sumbitNow={props.sumbitNow}
              selectSubmitWithModification={props.selectSubmitWithModification}
            />
          )}

          {props.origin === "Project" && (
            <ListOfButtonsProject
              closeModal={props.closeModal}
              selectSubmitWithModification={props.selectSubmitWithModification}
              updateRequest={props.updateRequest}
              requestData={props.requestData}
              reSubmitRequest={props.reSubmitRequest}
            />
          )}
        </Grid>
        {AuthenticationService.role === "Administrator" && (
          <Grid item>
            <Tooltip title="Download KML File">
              <Button
                type="button"
                variant="contained"
                color="primary"
                href={`data:text/xml;charset=utf-8,${encodeURIComponent(
                  props.requestData.referenceToKMLFile
                )}`}
                download={`${props.requestData.globalInformations.referenceNumber}.kml`}
                style={{
                  height: "30px",
                  minWidth: "30px",
                  maxWidth: "30px",
                  marginLeft: "10px",
                }}
              >
                <DownloadIcon />
              </Button>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ListOfButtons;
