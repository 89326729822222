import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Legal from "../Settinngs/Legal";
// import FeedBack from "../Settinngs/FeedBack";
import { useTranslation } from "react-i18next";
const Settings = (props: any) => {
  const { t } = useTranslation();

  const closeModal = () => {
    props.closeModal();
  };

  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="lg"
      onClose={closeModal}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle textAlign="center">
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5" style={{ marginRight: "15px" }}>
            {t("settings.settings")}
          </Typography>
          <Grid item style={{ position: "absolute", right: "12px" }}>
            <IconButton
              onClick={() => {
                closeModal();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={6}>
            <Legal />
          </Grid>
          {/* <Grid item xs={6}>
            <FeedBack />
          </Grid> */}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default Settings;
