export enum PATHS {
  Register = "/register",
  ConfirmEmail = "/confirmEmail",
  ForgotPassword = "/forgotPassword",
  ChangePassword = "/changePassword",
  CreateFirstPassword = "/createFirstPassword",
  UserHome = "/Home",
  AdminHome = "/AdminHome",
  Home = "/",
}
