import CalibrationInformations from "./SpecificInformation/CalibrationInformations";
import MediaCoverageInformations from "./SpecificInformation/MediaCoverageInformations";
import PhotoMissionInformations from "./SpecificInformation/PhotoMissionInformations";
import ScientificFlightInformations from "./SpecificInformation/ScientificFlightInformations";
import SkydivingInformations from "./SpecificInformation/SkydivingInformations";
import TestFlightInformations from "./SpecificInformation/TestFlightInformations";
import VariousInformations from "./SpecificInformation/VariousInformations";

const SpecificInformations = (props: any) => {
  return (
    <>
      {props.typeOfSpecificInformations === "Calibration" && (
        <CalibrationInformations
          activeStep={props.activeStep}
          handleBack={props.handleBack}
          specificInformationsValidation={props.specificInformationsValidation}
          steps={props.steps}
          completed={props.completed}
          handleComplete={props.handleComplete}
          completedSteps={props.completedSteps}
          totalSteps={props.totalSteps}
          specificInformations={props.specificInformations}
          origin={props.origin}
        />
      )}
      {props.typeOfSpecificInformations === "Media coverage" && (
        <MediaCoverageInformations
          activeStep={props.activeStep}
          handleBack={props.handleBack}
          specificInformationsValidation={props.specificInformationsValidation}
          steps={props.steps}
          completed={props.completed}
          handleComplete={props.handleComplete}
          completedSteps={props.completedSteps}
          totalSteps={props.totalSteps}
          specificInformations={props.specificInformations}
          origin={props.origin}
        />
      )}
      {props.typeOfSpecificInformations === "Photo mission" && (
        <PhotoMissionInformations
          activeStep={props.activeStep}
          handleBack={props.handleBack}
          specificInformationsValidation={props.specificInformationsValidation}
          steps={props.steps}
          completed={props.completed}
          handleComplete={props.handleComplete}
          completedSteps={props.completedSteps}
          totalSteps={props.totalSteps}
          specificInformations={props.specificInformations}
          origin={props.origin}
        />
      )}
      {props.typeOfSpecificInformations === "Test flight" && (
        <TestFlightInformations
          activeStep={props.activeStep}
          handleBack={props.handleBack}
          specificInformationsValidation={props.specificInformationsValidation}
          steps={props.steps}
          completed={props.completed}
          handleComplete={props.handleComplete}
          completedSteps={props.completedSteps}
          totalSteps={props.totalSteps}
          specificInformations={props.specificInformations}
          origin={props.origin}
          createRoads={props.createRoads}
        />
      )}
      {props.typeOfSpecificInformations === "Scientific flight" && (
        <ScientificFlightInformations
          activeStep={props.activeStep}
          handleBack={props.handleBack}
          specificInformationsValidation={props.specificInformationsValidation}
          steps={props.steps}
          completed={props.completed}
          handleComplete={props.handleComplete}
          completedSteps={props.completedSteps}
          totalSteps={props.totalSteps}
          specificInformations={props.specificInformations}
          origin={props.origin}
        />
      )}
      {props.typeOfSpecificInformations === "Skydiving" && (
        <SkydivingInformations
          activeStep={props.activeStep}
          handleBack={props.handleBack}
          specificInformationsValidation={props.specificInformationsValidation}
          steps={props.steps}
          completed={props.completed}
          handleComplete={props.handleComplete}
          completedSteps={props.completedSteps}
          totalSteps={props.totalSteps}
          specificInformations={props.specificInformations}
          origin={props.origin}
        />
      )}
      {props.typeOfSpecificInformations === "Various" && (
        <VariousInformations
          activeStep={props.activeStep}
          handleBack={props.handleBack}
          specificInformationsValidation={props.specificInformationsValidation}
          steps={props.steps}
          completed={props.completed}
          handleComplete={props.handleComplete}
          completedSteps={props.completedSteps}
          totalSteps={props.totalSteps}
          specificInformations={props.specificInformations}
          origin={props.origin}
        />
      )}
    </>
  );
};

export default SpecificInformations;
