// Page d'accueil de SAMURAI (permet l'authentification)

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import "../../../App.css";
import CompletedDataGrid from "../Module/DataGrid/CompletedDataGrid";

const useStyles = makeStyles((theme) => ({
  wait: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
  },
}));

const CompletedRequest = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      className={classes.wait}
    >
      <Grid
        item
        xs={12}
        style={{
          minHeight: "2vh",
          backgroundColor: "#2D3E4E",
        }}
      />
      <Grid item xs={12}>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          style={{ height: "98vh" }}
        >
          <Grid item xs={11}>
            <CompletedDataGrid />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CompletedRequest;
