import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { statisticsByCompany } from "../../../Service/ApiService";
import AuthenticationService from "../../../Service/AuthenticationService";
import GlobalStatistics from "./Statistics/GlobalStatistics";
import PieChartStatistics from "./Statistics/PieChartStatistics";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import DistributionByApplicantStatistics from "./Statistics/DistributionByApplicantStatistics";

const StatisticsInterface = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    statisticsByCompany(
      AuthenticationService.email,
      AuthenticationService.token,
      AuthenticationService.company
    )
      .then((newData: any) => {
        const newNewData: any = [];

        const newNewDataAccepted: any = [];
        const statisticsByApplicant: any = [];
        const applicants: any = [];

        newData.forEach((d: any) => {
          applicants.push(d.company);
          newNewDataAccepted.push({
            y: d.accepted,
            x: d.company,
          });
          newNewData.push({
            y: d.rejected + 1,
            x: d.company,
          });
          statisticsByApplicant.push({
            applicant: d.company,
            graph: [
              { y: d.rejected + 1, x: "Rejected" },
              {
                y: d.accepted,
                x: "Accepted",
              },
            ],
          });
        });
        setData(newNewData);
        setAccepted(newNewDataAccepted);
        setTest(statisticsByApplicant);
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [data, setData] = useState([] as any);
  const [accepted, setAccepted] = useState([] as any);
  const [test, setTest] = useState([] as any);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      style={{ margin: "5px" }}
    >
      <Grid item xs={10} style={{ margin: "5px" }}>
        <GlobalStatistics data={data} accepted={accepted} />
      </Grid>
      <Grid item xs={10} md={5} style={{ margin: "5px" }}>
        <PieChartStatistics data={data} />
      </Grid>
      <Grid item xs={10} md={5} style={{ margin: "5px" }}>
        <DistributionByApplicantStatistics data={test} />
      </Grid>

      {/* <VictoryChart
        theme={VictoryTheme.material}
        domain={{ y: [0, 5] }}
        // categories={{ x: [["test accepted", "test rejected"]] }}
      >
        <VictoryGroup offset={20} colorScale={"qualitative"}>
          <VictoryBar data={data} />
          <VictoryBar data={accepted} />
        </VictoryGroup>
      </VictoryChart> */}
    </Grid>
  );
};

export default StatisticsInterface;
