import { DateTime } from "luxon";
import { getRequest } from "../../Service/ApiService";
import toGeoJSON from "./toGeoJson";
import {
  adminMap,
  applicantMapFile,
  applicantMapInformations,
  loadFile,
} from "./map";
import AuthenticationService from "../../Service/AuthenticationService";

export const ProcessingGetRequest = async (
  id: string,
  email: string,
  token: string,
  role: string,
  centers: any
) => {
  const requestData: any = await getRequest(id, email, token);
  const globalInformations: any = {
    applicantName: requestData.applicantName,
    aircraftType: requestData.aircraftType,
    flightRules: requestData.flightRules,
    referenceNumber: requestData.referenceNumber,
    callsign: requestData.callsign,
    flightType: requestData.flightType,
    requiredLevel: requestData.requiredLevel,
    priorityLevel: requestData.priorityLevel,
    center: requestData.centers,
    usersEmail: requestData.usersEmail,
  };

  var specificInformations;

  switch (globalInformations.flightType) {
    case "Calibration":
      specificInformations = {
        requiredLevel: JSON.parse(requestData.specificInformations)
          .requiredLevel,
        workDescription: JSON.parse(requestData.specificInformations)
          .workDescription,
        comments: JSON.parse(requestData.specificInformations).comments,
        endTime: DateTime.fromISO(
          JSON.parse(requestData.specificInformations).endTime
        ),
        startTime: DateTime.fromISO(
          JSON.parse(requestData.specificInformations).startTime
        ),
        requestedDay: DateTime.fromISO(
          JSON.parse(requestData.specificInformations).requestedDay
        ),
      };
      break;
    case "Media coverage":
      specificInformations = {
        arrivalAerodrome: JSON.parse(requestData.specificInformations)
          .arrivalAerodrome,
        departureAerodrome: JSON.parse(requestData.specificInformations)
          .departureAerodrome,
        comments: JSON.parse(requestData.specificInformations).comments,
        requiredLevel: JSON.parse(requestData.specificInformations)
          .requiredLevel,
        schedule: JSON.parse(requestData.specificInformations).schedule.map(
          (schedule: string[]) => {
            return [
              DateTime.fromISO(schedule[0]),
              DateTime.fromISO(schedule[1]),
            ];
          }
        ),
        requestedDayStart: DateTime.fromISO(
          JSON.parse(requestData.specificInformations).requestedDayStart
        ),
        requestedDayEnd: DateTime.fromISO(
          JSON.parse(requestData.specificInformations).requestedDayEnd
        ),
      };
      break;
    case "Photo mission":
      specificInformations = {
        numberOfAxesToFly: JSON.parse(requestData.specificInformations)
          .numberOfAxesToFly,
        totalDurationOfMission: JSON.parse(requestData.specificInformations)
          .totalDurationOfMission,
        comments: JSON.parse(requestData.specificInformations).comments,
        actualDurationOfMission: JSON.parse(requestData.specificInformations)
          .actualDurationOfMission,
        requiredLevel: JSON.parse(requestData.specificInformations)
          .requiredLevel,
        endTime: DateTime.fromISO(
          JSON.parse(requestData.specificInformations).endTime
        ),
        startTime: DateTime.fromISO(
          JSON.parse(requestData.specificInformations).startTime
        ),
        requestedDay: DateTime.fromISO(
          JSON.parse(requestData.specificInformations).requestedDay
        ),
      };
      break;
    case "Test flight":
      specificInformations = {
        arrivalAerodrome: JSON.parse(requestData.specificInformations)
          .arrivalAerodrome,
        departureAerodrome: JSON.parse(requestData.specificInformations)
          .departureAerodrome,
        comments: JSON.parse(requestData.specificInformations).comments,
        filedRoad: JSON.parse(requestData.specificInformations).filedRoad,
        requiredLevel: JSON.parse(requestData.specificInformations)
          .requiredLevel,
        endTime: DateTime.fromISO(
          JSON.parse(requestData.specificInformations).endTime
        ),
        startTime: DateTime.fromISO(
          JSON.parse(requestData.specificInformations).startTime
        ),
        requestedDay: DateTime.fromISO(
          JSON.parse(requestData.specificInformations).requestedDay
        ),
      };
      break;
    case "Skydiving":
      specificInformations = {
        departureAerodrome: JSON.parse(requestData.specificInformations)
          .departureAerodrome,
        comments: JSON.parse(requestData.specificInformations).comments,
        numberOfDrops: JSON.parse(requestData.specificInformations)
          .numberOfDrops,
        schedule: JSON.parse(requestData.specificInformations).schedule.map(
          (schedule: string[]) => {
            return [
              DateTime.fromISO(schedule[0]),
              DateTime.fromISO(schedule[1]),
            ];
          }
        ),
        requestedDays: JSON.parse(
          requestData.specificInformations
        ).requestedDays.map((requestedDay: string) => {
          return DateTime.fromISO(requestedDay);
        }),
      };
      break;
    case "Various":
      specificInformations = {
        typeOfMission: JSON.parse(requestData.specificInformations)
          .typeOfMission,
        comments: JSON.parse(requestData.specificInformations).comments,
        description: JSON.parse(requestData.specificInformations).description,
        requiredLevel: JSON.parse(requestData.specificInformations)
          .requiredLevel,
        schedule: JSON.parse(requestData.specificInformations).schedule.map(
          (schedule: string[]) => {
            return [
              DateTime.fromISO(schedule[0]),
              DateTime.fromISO(schedule[1]),
            ];
          }
        ),
        requestedDays: JSON.parse(
          requestData.specificInformations
        ).requestedDays.map((requestedDay: string) => {
          return DateTime.fromISO(requestedDay);
        }),
      };
      break;
    case "Scientific flight":
      specificInformations = {
        arrivalAerodrome: JSON.parse(requestData.specificInformations)
          .arrivalAerodrome,
        departureAerodrome: JSON.parse(requestData.specificInformations)
          .departureAerodrome,
        comments: JSON.parse(requestData.specificInformations).comments,
        filedRoad: JSON.parse(requestData.specificInformations).filedRoad,
        requiredLevel: JSON.parse(requestData.specificInformations)
          .requiredLevel,
        endTime: DateTime.fromISO(
          JSON.parse(requestData.specificInformations).endTime
        ),
        startTime: DateTime.fromISO(
          JSON.parse(requestData.specificInformations).startTime
        ),
        requestedDay: DateTime.fromISO(
          JSON.parse(requestData.specificInformations).requestedDay
        ),
      };
  }

  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(
    requestData.referenceToKMLFile,
    "text/xml"
  );

  const test = toGeoJSON.kml(xmlDoc);
  const currentFileData =
    role === "Administrator" ? adminMap : applicantMapFile;

  const waypoints: string[] =
    globalInformations.flightType === "Test flight"
      ? specificInformations?.filedRoad
          .split(";")
          .filter((chip: string) => chip.length !== 0)
      : [];

  const mapInformations: any =
    role === "Administrator"
      ? await loadFile(
          test,
          waypoints,
          globalInformations.requiredLevel,
          AuthenticationService.email,
          AuthenticationService.token
        )
      : await applicantMapInformations(
          currentFileData,
          waypoints,
          test,
          AuthenticationService.email,
          AuthenticationService.token
        );

  const newRequestData = {
    globalInformations: globalInformations,
    specificInformations: specificInformations,
    referenceToKMLFile: requestData.referenceToKMLFile,
    kmlFile: test,
    sendingTime: requestData.sendingTime,
    state: role === "Administrator" ? centers.state : requestData.state,
    updateTime: requestData.updateTime,
    reason: role === "Administrator" ? centers.reason : requestData.reason,
    userEmail: requestData.userEmail,
    id: requestData.id,
    centerId: centers.id,
    mapInformations: mapInformations,
    protec:
      role === "Administrator"
        ? requestData.protec.filter(
            (newProtec: any) => newProtec.id === centers.protecId
          )[0]
        : "",
  };
  return newRequestData;
};
