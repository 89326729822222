// Page d'accueil de SAMURAI (permet l'authentification)

import { Grid } from "@mui/material";
import * as React from "react";
import "../../../App.css";
import { useState } from "react";
import LoadRequestFromProject from "../Module/LoadRequestFromProject";
import UpdateRequestContent from "../Module/UpdateRequestContent";

const NewRequestFromProject = (props: any) => {
  const [loadRequest, setLoadRequest] = useState(true);
  const [requestData, setRequestData] = useState(null as any);

  const selectedRequestData = (requestData: any) => {
    setRequestData(requestData);
    setLoadRequest(false);
  };

  const finish = () => {
    props.changeCurrentPage("Requests");
  };

  return (
    <>
      {!loadRequest && (
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
          style={{ height: "100vh" }}
        >
          <UpdateRequestContent
            origin="newRequestFromProject"
            requestData={requestData}
            height="90"
            heightForm="72"
            finish={finish}
          />
        </Grid>
      )}
      {loadRequest && (
        <LoadRequestFromProject selectedRequestData={selectedRequestData} />
      )}
    </>
  );
};

export default NewRequestFromProject;
