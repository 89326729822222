import { TextField, Grid, Typography, Stack } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import getAllDatesBeetweenStartAndEnd from "../../../../../ShareFonction/ListAllDaysOfSchedule";

const SeveralDays = (props: any) => {
  const formikProps: any = useFormikContext();
  const [schedules, setSchedules] = useState([
    [DateTime.now(), DateTime.now()],
  ]);
  const [schedulesDays, setSchedulesDays] = useState([
    DateTime.now().toFormat("dd/MM"),
  ]);

  const [requestedDayStartError, setRequestedDayStartError] = useState(false);
  const [requestedDayEndError, setRequestedDayEndError] = useState(false);

  useEffect(() => {
    const start: DateTime = formikProps.values.requestedDayStart;
    const end: DateTime = formikProps.values.requestedDayEnd;

    const dayDiff = Math.round(end.diff(start, "days").days) + 1;
    const newScheduleDays = getAllDatesBeetweenStartAndEnd(dayDiff, start);
    setSchedulesDays(newScheduleDays);
    if (props.schedule.length > 1) {
      formikProps.setFieldValue("schedule", props.schedule);
      setSchedules(props.schedule);
    } else {
      const newSchedule: DateTime[][] = [];
      for (let index = 0; index < dayDiff; index++) {
        newSchedule.push([DateTime.now(), DateTime.now()]);
      }
      formikProps.setFieldValue("schedule", newSchedule);
      setSchedules(newSchedule);
    }
    if (dayDiff < 0) {
      setRequestedDayStartError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid xs={12}>
        <Stack spacing={3} style={{ marginTop: "12px" }}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DesktopDatePicker
              label="Requested day start"
              value={formikProps.values.requestedDayStart}
              inputFormat="dd/MM/yyyy"
              onChange={(value): void => {
                formikProps.setFieldValue("requestedDayStart", value);

                if (value > formikProps.values.requestedDayEnd) {
                  setRequestedDayStartError(true);
                  setSchedulesDays([]);
                } else {
                  setRequestedDayStartError(false);
                  const dayDiff =
                    Math.round(
                      formikProps.values.requestedDayEnd.diff(value, ["days"])
                        .values.days
                    ) + 1;
                  const newSchedule: DateTime[][] = [];

                  for (let index = 0; index < dayDiff; index++) {
                    newSchedule.push([DateTime.now(), DateTime.now()]);
                  }

                  const newScheduleDays = getAllDatesBeetweenStartAndEnd(
                    dayDiff,
                    value
                  );

                  setSchedules(newSchedule);
                  setSchedulesDays(newScheduleDays);
                  formikProps.setFieldValue("schedule", newSchedule);
                }
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ width: "100%" }}
                  {...params}
                  error={requestedDayStartError}
                  helperText={
                    requestedDayStartError
                      ? "The start day can not be after the end date !"
                      : ""
                  }
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
      </Grid>
      <Grid xs={12}>
        <Stack spacing={3} style={{ marginTop: "12px" }}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DesktopDatePicker
              label="Requested day end"
              value={formikProps.values.requestedDayEnd}
              inputFormat="dd/MM/yyyy"
              onChange={(value): void => {
                formikProps.setFieldValue("requestedDayEnd", value);
                const start: DateTime = formikProps.values.requestedDayStart;

                if (value < start) {
                  setRequestedDayEndError(true);
                  setSchedulesDays([]);
                } else {
                  setRequestedDayEndError(false);

                  //On ajoute +1 car la différence ne prend pas en compte les bornes
                  const dayDiff =
                    Math.round(value.diff(start, ["days"]).values.days) + 1;
                  const newSchedule: DateTime[][] = [];

                  for (let index = 0; index < dayDiff; index++) {
                    newSchedule.push([DateTime.now(), DateTime.now()]);
                  }

                  const newScheduleDays = getAllDatesBeetweenStartAndEnd(
                    dayDiff,
                    start
                  );

                  setSchedules(newSchedule);
                  setSchedulesDays(newScheduleDays);
                  formikProps.setFieldValue("schedule", newSchedule);
                }
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ width: "100%" }}
                  {...params}
                  error={requestedDayEndError}
                  helperText={
                    requestedDayEndError
                      ? "The end day can not be before the start date !"
                      : ""
                  }
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
      </Grid>
      {schedulesDays.map((item: any, index: number) => {
        return (
          <Grid item xs={12} key={index}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Grid item xs={12} style={{ margin: "10px" }}>
                <Typography>Day {item}</Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack
                  spacing={3}
                  style={{ marginTop: "12px", marginRight: "10px" }}
                >
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <TimePicker
                      label={"Start time"}
                      value={schedules[index][0]}
                      ampm={false}
                      onChange={(value: any) => {
                        schedules[index][0] = value;
                        formikProps.setFieldValue("schedule", schedules);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack spacing={3} style={{ marginTop: "12px" }}>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <TimePicker
                      label={"End time"}
                      value={schedules[index][1]}
                      ampm={false}
                      onChange={(value: any) => {
                        schedules[index][1] = value;
                        formikProps.setFieldValue("schedule", schedules);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default SeveralDays;
