import { TextField, Button, Grid, Box, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

interface PasswordForm {
  password: string;
  checkPassword: string;
}

const PasswordInformations = (props: any) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={
        {
          password: "",
          checkPassword: "",
        } as PasswordForm
      }
      onSubmit={(values: PasswordForm, actions) => {
        props.passwordInformationsValidation(values.password);
        actions.resetForm();
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()/])/,
            t("form.passwordMatch") || ""
          )
          .min(8, t("form.passwordMinLenght") || "")
          .required(t("form.enterValidPassword") || ""),
        checkPassword: Yup.string()
          .oneOf(
            [Yup.ref("password"), null],
            t("form.passwordsMustMatch") || ""
          )
          .required(t("form.enterValidCheckPassword") || ""),
      })}
    >
      {({ values, touched, errors, handleBlur, handleChange }) => {
        return (
          <Form noValidate>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  type="password"
                  style={{ color: "white" }}
                  value={values.password}
                  helperText={
                    errors.password && touched.password ? errors.password : ""
                  }
                  error={errors.password && touched.password ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="checkPassword"
                  label="Confirm password"
                  name="checkPassword"
                  type="password"
                  style={{ color: "white" }}
                  value={values.checkPassword}
                  helperText={
                    errors.checkPassword && touched.checkPassword
                      ? errors.checkPassword
                      : ""
                  }
                  error={
                    errors.checkPassword && touched.checkPassword ? true : false
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={props.activeStep === 0}
                onClick={props.handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button type="submit" sx={{ mr: 1 }}>
                Finish
              </Button>
              {props.activeStep !== props.steps.length &&
                props.completed[props.activeStep] && (
                  <Typography
                    variant="caption"
                    sx={{ display: "inline-block" }}
                  >
                    {t("global.step") +
                      " " +
                      (props.activeStep + 1) +
                      " " +
                      t("global.alreadyCompleted")}
                  </Typography>
                )}
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PasswordInformations;
