import { TextField, Button, Grid } from "@mui/material";
import { Formik, FormikProps, Form } from "formik";
import * as Yup from "yup";
import { changePassword } from "../../Service/ApiService";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

interface LostPasswordForm {
  password: string;
  checkPassword: string;
}

const ChangePasswordForm = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        password: "",
        checkPassword: "",
      }}
      onSubmit={(values: LostPasswordForm, actions) => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get("token") || "";
        changePassword(token, values.password)
          .then(() => {
            props.openModalValidNewPassword();
            actions.resetForm();
          })
          .catch((error) => {
            enqueueSnackbar(t("error.errorHasOccured"));
            console.error(error);
          });
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()/])/,
            t("form.passwordMatch") || ""
          )
          .min(8, t("form.passwordMinLenght") || "")
          .required(t("form.enterValidPassword") || ""),
        checkPassword: Yup.string()
          .oneOf(
            [Yup.ref("password"), null],
            t("form.passwordsMustMatch") || ""
          )
          .required(t("form.enterValidCheckPassword") || ""),
      })}
    >
      {(props: FormikProps<LostPasswordForm>) => {
        const { values, touched, errors, handleBlur, handleChange } = props;
        return (
          <Form noValidate>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  type="password"
                  style={{ color: "white" }}
                  value={values.password}
                  helperText={
                    errors.password && touched.password ? errors.password : ""
                  }
                  error={errors.password && touched.password ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="checkPassword"
                  label="Confirm password"
                  name="checkPassword"
                  type="password"
                  style={{ color: "white" }}
                  value={values.checkPassword}
                  helperText={
                    errors.checkPassword && touched.checkPassword
                      ? errors.checkPassword
                      : ""
                  }
                  error={
                    errors.checkPassword && touched.checkPassword ? true : false
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={12} md={8} style={{ marginTop: "8px" }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {t("global.confirm")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordForm;
