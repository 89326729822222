// Page d'accueil de SAMURAI (permet l'authentification)

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import "../../App.css";
import backgroundImage from "../../Image/test.jpg";
import dgac from "../../Image/dgac-dsna.png";
import logo from "../../Image/logo.png";
import { useNavigate } from "react-router-dom";
import ForgotPasswordForm from "./ForgotPasswordForm";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { PATHS } from "../../Router/Routes";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "#1F2440",
  },
  error: {
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
  },
  center: {
    textAlign: "center",
  },
  wait: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    minWidth: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
  },
  paper: {
    backgroundColor: "rgba(255, 255, 255, .7)",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
  },
  avatar: {},
  form: {
    width: "80%",
  },
  signUp: {
    marginTop: "14px",
  },
  touch: {
    "&:hover": { cursor: "pointer" },
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [validSendEmail, setValidSendEmail] = React.useState(false);

  const closeModalValidSendEmail = () => {
    setValidSendEmail(false);
    navigate(PATHS.Home);
  };
  const openModalValidSendEmail = () => {
    setValidSendEmail(true);
  };

  const modalValidSendEmail = (
    <Dialog
      open={validSendEmail}
      onClose={closeModalValidSendEmail}
      aria-labelledby="draggable-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>{t("register.resetYourPassword")}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          {parse(t("register.sentEmail"))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeModalValidSendEmail}
          style={{ textAlign: "center" }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div className="App">
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        className={classes.wait}
      >
        <Grid item xs={11} sm={10} md={6} lg={5} className={classes.paper}>
          <Grid
            container
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "35vh" }}
          >
            <Grid
              item
              xs={12}
              className={classes.signUp}
              style={{ margin: "12px" }}
            >
              <Typography component="h1" variant="h5">
                {t("register.forgotPassword")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              md={8}
              className={classes.signUp}
              style={{ margin: "12px", minHeight: "20vh" }}
            >
              <ForgotPasswordForm
                openModalValidSendEmail={openModalValidSendEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                alignContent="center"
                alignItems="center"
                justifyContent="center"
                style={{ backgroundColor: "white" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={3}
                  style={{
                    marginTop: "10px",
                    alignContent: "right",
                    alignItems: "right",
                  }}
                >
                  <img
                    src={dgac}
                    alt={"Logo DSNA/DGAC"}
                    loading="lazy"
                    style={{
                      maxWidth: "60px",
                      maxHeight: "60px",
                      minWidth: "60px",
                      minHeight: "60px",
                    }}
                  />
                </Grid>
                <Grid item xs={6} className={classes.signUp}>
                  <Typography component="h1" variant="body2">
                    <Link
                      display="block"
                      className={classes.touch}
                      variant="body2"
                      onClick={() => {
                        navigate(PATHS.Home);
                      }}
                    >
                      <KeyboardDoubleArrowLeftIcon
                        style={{ textAlign: "end" }}
                      />
                      {t("register.backToLogin")}
                    </Link>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={3}
                  style={{
                    marginTop: "10px",
                    alignContent: "right",
                    alignItems: "right",
                  }}
                >
                  <img
                    src={logo}
                    alt={"Logo DSNA/DGAC"}
                    loading="lazy"
                    style={{
                      maxWidth: "100px",
                      maxHeight: "60px",
                      minWidth: "100px",
                      minHeight: "60px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {modalValidSendEmail}
    </div>
  );
};

export default ForgotPassword;
