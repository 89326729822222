import React from "react";
import { CardMedia, Divider, Grid, Typography } from "@mui/material";
import parse from "html-react-parser";

const ProtecExportTestFlight = (props: any) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      style={{
        height: "100vh",
      }}
    >
      <Grid
        item
        xs={7}
        style={{
          backgroundColor: "red",
          height: "8vh",
          border: "solid black 1px",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography align="center" color="white">
          Vol circulaire XXXX-XXXX
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          style={{
            margin: "5px",
            height: "8vh",
          }}
        >
          <Grid item xs={7}>
            <Divider color="black" />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            Date
          </Grid>
          <Grid item xs={7}>
            <Divider color="black" />
          </Grid>
          <Grid
            item
            xs={7}
            style={{ backgroundColor: "#CACACA", textAlign: "center" }}
          >
            CDS/FMP/Secteurs
          </Grid>
          <Grid item xs={7}>
            <Divider color="black" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ height: "80vh" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid
            item
            xs={6}
            style={{
              height: "20vh",
              textAlign: "left",
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{
                marginLeft: "5px",
                marginTop: "5px",
                marginBottom: "5px",
                height: "18vh",
                border: "solid black 1px",
                overflow: "hidden",
              }}
            >
              <Grid item xs={7} style={{ margin: "15px" }}>
                <Typography>
                  <u>Société :</u>{" "}
                  {props.requestData.globalInformations.applicantName}
                </Typography>
                <Typography>
                  <u>Règles de vol :</u>{" "}
                  {props.requestData.globalInformations.flightRules}
                </Typography>
                <Typography>
                  <u>DEP :</u>{" "}
                  {props.requestData.specificInformations.departureAerodrome}
                </Typography>
                <Typography>
                  <u>ARR :</u>{" "}
                  {props.requestData.specificInformations.arrivalAerodrome}
                </Typography>
                <Typography>
                  <u>Immatriculation / callsign :</u>{" "}
                  {props.requestData.globalInformations.referenceNumber} /{" "}
                  {props.requestData.globalInformations.callsign}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  <u>FL :</u>{" "}
                  {props.requestData.specificInformations.requiredLevel}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{
                marginLeft: "5px",
                marginTop: "5px",
                minHeight: "5vh",
                maxHeight: "18vh",
                marginBottom: "5px",
                border: "solid black 1px",
                overflow: "hidden",
              }}
            >
              <Grid xs={12} style={{ margin: "15px" }}>
                <Typography>
                  <u>Consignes sur secteur :</u>
                </Typography>
                <Typography color="black">
                  {parse(
                    props.requestData.protec.informations.instructionOnSector
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{
                marginLeft: "5px",
                marginTop: "5px",
                minHeight: "5vh",
                maxHeight: "18vh",
                marginBottom: "5px",
                border: "solid black 1px",
                overflow: "hidden",
                color: "red",
              }}
            >
              <Grid xs={12} style={{ margin: "15px" }}>
                <Typography>
                  <u>Consignes CDS :</u>
                </Typography>
                <Typography color="black">
                  {parse(props.requestData.protec.informations.cdsInstruction)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              style={{
                marginTop: "5px",
                marginLeft: "5px",
                minHeight: "5vh",
                maxHeight: "15vh",
                border: "solid black 1px",
                overflow: "hidden",
                backgroundColor: "#FF5F5D",
              }}
            >
              <Grid item xs={12} style={{ margin: "15px" }}>
                <Typography>
                  Numéro RPO : 06 85 83 15 21 <br /> Numéro Sub CTL en cas de
                  problème (horaire de bureau) :
                  <br />
                </Typography>
                <Typography color="black">
                  {parse(props.requestData.protec.informations.callingNumber)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              minHeight: "5vh",
              maxHeight: "18vh",
              textAlign: "left",
            }}
          >
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                xs={10}
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CardMedia
                  style={{
                    height: "70vh",
                    width: "60vw",
                  }}
                  component="img"
                  src={`data:image/png;base64 ${props.map}`}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProtecExportTestFlight;
