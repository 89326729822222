import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Skeleton,
} from "@mui/material";

const LoadSummaryRequest = (props: any) => {
  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="xl"
      onClose={props.closeModal}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle textAlign="center">
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              style={{ position: "absolute", right: "12px" }}
            />
          </Grid>
          <Grid item xs={10}>
            <Skeleton height={150} sx={{ fontSize: "1rem" }} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ height: "70vh" }}>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          style={{ height: "70vh", width: "100%" }}
          sx={{ display: "flex" }}
        >
          <Grid item xs={5}>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={10}>
                <Skeleton
                  variant="rectangular"
                  style={{ height: "50vh", width: "100%", margin: "10px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={10}>
                <Skeleton
                  variant="rectangular"
                  style={{ height: "60vh", width: "100%", margin: "5px" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default LoadSummaryRequest;
