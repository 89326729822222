import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import RejectedForm from "../Module/Form/RejectedForm";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const Rejected = (props: any) => {
  const { t } = useTranslation();

  const processRequest = (state: string, reason: string) => {
    props.conditionalProcessing(state, reason);
  };
  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5" style={{ marginRight: "15px" }}>
            {t("request.reject")}
          </Typography>
          <Grid
            item
            style={{ position: "absolute", right: "12px", top: "12px" }}
          >
            <IconButton
              onClick={() => {
                props.closeModal();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          <RejectedForm processRequest={processRequest} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
export default Rejected;
