import { Grid, Paper, Typography } from "@mui/material";
import { t } from "i18next";
import { VictoryPie } from "victory";

const PieChartStatistics = (props: any) => {
  return (
    <Paper style={{ height: "45vh" }} elevation={4}>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom style={{ margin: "10px" }}>
            {t("statistics.overallDistributionAcceptances")}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <VictoryPie
            data={props.data}
            labels={({ datum }) => datum.y}
            padAngle={({ datum }) => datum.y}
            innerRadius={60}
            height={280}
            width={500}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PieChartStatistics;
