// Page d'accueil de SAMURAI (permet l'authentification)

import { Box } from "@mui/material";
import * as React from "react";
import "../../../App.css";
import NavBar from "./NavBar";
import OnHoldRequest from "./OnHoldRequest";
import Project from "./Project";
import Requests from "./Requests";
import UserManagement from "./UserManagement";
import Favorite from "./Favorite";
import CompletedRequest from "./CompletedRequest";
import { useState } from "react";
import Statistics from "./Statistics";

const AdminHome = () => {
  const [update, setUpdate] = useState(false);
  const [currentPage, setCurrentPage] = React.useState("Requests");
  const changeCurrentPage = (newCurrentPage: string) => {
    setCurrentPage(newCurrentPage);
  };

  const updateNotification = () => {
    setUpdate(!update);
  };
  return (
    <div className="App">
      <Box sx={{ display: "flex" }}>
        <NavBar
          changeCurrentPage={changeCurrentPage}
          update={updateNotification}
        />
        {/* On fait varier l'affichage en fonction de la page choisie par l'utilisateur, par défaut c'est la page des requêtes en cours ou à venir qui s'ouvre */}
        {currentPage === "Requests" && (
          <Requests updateNotification={updateNotification} />
        )}
        {currentPage === "CompletedRequest" && <CompletedRequest />}
        {currentPage === "OnHoldRequest" && <OnHoldRequest />}
        {currentPage === "Projects" && (
          <Project updateNotification={updateNotification} />
        )}
        {currentPage === "Favorite" && <Favorite />}
        {currentPage === "Statistics" && <Statistics />}
        {currentPage === "UserManagement" && <UserManagement />}
      </Box>
    </div>
  );
};

export default AdminHome;
