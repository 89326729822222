import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../Router/Routes";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const ConfirmRegistration = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item style={{ marginBottom: "12px" }}>
        <Typography variant="h6">
          {" "}
          {t("register.confirmRegisterTitle")}{" "}
        </Typography>
      </Grid>
      <Grid item>
        <Typography style={{ textAlign: "left", marginBottom: "12px" }}>
          {parse(t("register.confirmRegisterContent"))}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={() => {
            navigate(PATHS.Home);
          }}
        >
          {t("global.home")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ConfirmRegistration;
