// Page d'accueil de SAMURAI (permet l'authentification)

import { Card, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "../../../App.css";
import MapTest from "../../Common/Map/ApplicantMap";
import NewRequestForm from "../Module/NewRequestForm";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  applicantMapFile,
  applicantMapInformations,
} from "../../ShareFonction/map";
import AuthenticationService from "../../../Service/AuthenticationService";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "#1F2440",
  },
  error: {
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
  },
  center: {
    textAlign: "center",
  },
  wait: {
    minHeight: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
  },
  paper: {
    backgroundColor: "rgba(255, 255, 255, .7)",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
  },
  avatar: {},
  form: {
    width: "80%",
  },
  signUp: {
    marginTop: "14px",
  },
  touch: {
    "&:hover": { cursor: "pointer" },
  },
}));

const NewRequest = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [file, setFile] = useState({
    type: "FeatureCollection",
    features: [],
    name: "Initial value",
  } as any);
  const [stateOnFile, setStateOnFile] = useState(true);
  const [waypoints, setWaypoints] = useState([] as any);
  const [mapInformations, setMapInformations] = useState({
    line: [],
    layer: [],
    status: "",
  } as any);

  useEffect(() => {
    applicantMapInformations(
      applicantMapFile,
      waypoints,
      file,
      AuthenticationService.email,
      AuthenticationService.token
    ).then((newMapInformations) => {
      setMapInformations(newMapInformations);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeFile = (newFile: any) => {
    applicantMapInformations(
      applicantMapFile,
      waypoints,
      newFile,
      AuthenticationService.email,
      AuthenticationService.token
    ).then((newMapInformations) => {
      setMapInformations(newMapInformations);
      setFile(newFile);
    });
  };
  const finish = () => {
    props.changeCurrentPage("Requests");
  };

  const updateStateOnFile = (newState: boolean) => {
    setStateOnFile(newState);
  };

  const createNewRoad = async (newWaypoints: string[]) => {
    applicantMapInformations(
      applicantMapFile,
      newWaypoints,
      file,
      AuthenticationService.email,
      AuthenticationService.token
    ).then((newMapInformations) => {
      setMapInformations(newMapInformations);
      setWaypoints(newWaypoints);
    });
  };

  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      className={classes.wait}
    >
      <Grid item xs={stateOnFile ? 8 : 6}>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} style={{ height: "100%" }}>
            <Card elevation={8} style={{ margin: "10px" }}>
              <MapTest
                file={file}
                height="95"
                mapInformations={mapInformations}
              />
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={stateOnFile ? 4 : 6}>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          style={{ height: "100vh" }}
        >
          <Card elevation={8} style={{ margin: "10px", height: "95vh" }}>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={10} style={{ height: "8vh" }}>
                <Typography
                  variant="h4"
                  style={{ marginBottom: "20px", marginTop: "10px" }}
                >
                  {props.origin !== "Project"
                    ? t("request.newRequest")
                    : t("project.newProject")}
                </Typography>
              </Grid>
              <Grid item xs={10} lg={11} style={{ height: "85vh" }}>
                <NewRequestForm
                  changeFile={changeFile}
                  finish={finish}
                  origin={props.origin}
                  updateStateOnFile={updateStateOnFile}
                  createNewRoad={createNewRoad}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewRequest;
