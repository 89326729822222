import { useState } from "react";
import { listAllRequestOfCenter } from "../../../../Service/ApiService";
import AuthenticationService from "../../../../Service/AuthenticationService";
import AdminDataGrid from "../AdminDataGrid";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const RequestsDataGrid = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const listOfRequest = async () => {
    return listAllRequestOfCenter(
      AuthenticationService.company,
      AuthenticationService.email,
      AuthenticationService.token
    )
      .then((newData: any) => {
        return newData;
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const [origin, setOrigin] = useState("");

  const updateOrigin = (newOrigin: string) => {
    setOrigin(newOrigin);
  };

  const selectRequestData = (newRequestData: any) => {
    setOrigin(
      newRequestData.state === "processing" ? "processing" : "stateDefined"
    );
  };

  return (
    <AdminDataGrid
      listOfRequest={listOfRequest}
      selectRequestData={selectRequestData}
      origin="Request"
      originSummmary={origin}
      updateOrigin={updateOrigin}
      updateNotification={props.updateNotification}
    />
  );
};

export default RequestsDataGrid;
