import { Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import WarningValidationUpdateRequest from "../../Modal/WarningValidationUpdateRequest";
import CalibrationSummary from "../NewRequest/SummaryOfNewRequest/CalibrationSummary";
import MediaCoverageSummary from "../NewRequest/SummaryOfNewRequest/MediaCoverageSummary";
import PhotoMissionSummary from "../NewRequest/SummaryOfNewRequest/PhotoMissionSummary";
import ScientificFlightSummary from "../NewRequest/SummaryOfNewRequest/ScientificFlightSummary";
import SkydivingSummary from "../NewRequest/SummaryOfNewRequest/SkydivingSummary";
import TestFlightSummary from "../NewRequest/SummaryOfNewRequest/TestFlightSummary";
import VariousSummary from "../NewRequest/SummaryOfNewRequest/VariousSummary";
import { useTranslation } from "react-i18next";

const SummaryOfUpdatedRequest = (props: any) => {
  const { t } = useTranslation();

  const [warningUpdateRequest, setWarningUpdateRequest] = useState(false);

  const closeWarningUpdateRequest = () => {
    setWarningUpdateRequest(false);
  };

  const confirm = () => {
    props.validSummaryOfRequest();
  };
  const undo = () => {
    setWarningUpdateRequest(false);
  };
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item xs={12} style={{ marginBottom: "40px", textAlign: "center" }}>
        <Typography variant="h5">{t("request.summary.title")}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: "12px" }}
            >
              {t("request.summary.applicantName") +
                " : " +
                props.globalInformations.applicantName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: "12px" }}
            >
              {t("request.summary.referenceNumber") +
                " : " +
                props.globalInformations.referenceNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: "12px" }}
            >
              {t("request.summary.aircraftType") +
                " : " +
                props.globalInformations.aircraftType}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: "12px" }}
            >
              {t("request.summary.aircraftType") +
                " : " +
                props.globalInformations.callsign}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: "12px" }}
            >
              {t("request.summary.requiredLevel") +
                " : " +
                props.globalInformations.requiredLevel}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: "12px" }}
            >
              {t("request.summary.priorityLevel") +
                " : " +
                props.globalInformations.priorityLevel}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: "12px" }}
            >
              {t("request.summary.flightRuless") +
                " : " +
                props.globalInformations.flightRules}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ textAlign: "left", marginBottom: "12px" }}
            >
              {t("request.summary.centers")}
              <ul>
                {props.globalInformations.center.map((centers: string) => {
                  return <li>{centers}</li>;
                })}
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          style={{ textAlign: "center", margin: "20px" }}
        >
          <u>{props.globalInformations.flightType}</u>
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid
            item
            xs={12}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            {props.globalInformations.flightType === "Calibration" && (
              <CalibrationSummary
                specificInformations={props.specificInformations}
                origin={props.origin}
              />
            )}
            {props.globalInformations.flightType === "Media coverage" && (
              <MediaCoverageSummary
                specificInformations={props.specificInformations}
                origin={props.origin}
              />
            )}
            {props.globalInformations.flightType === "Photo mission" && (
              <PhotoMissionSummary
                specificInformations={props.specificInformations}
                origin={props.origin}
              />
            )}
            {props.globalInformations.flightType === "Test flight" && (
              <TestFlightSummary
                specificInformations={props.specificInformations}
                origin={props.origin}
              />
            )}
            {props.globalInformations.flightType === "Scientific flight" && (
              <ScientificFlightSummary
                specificInformations={props.specificInformations}
                origin={props.origin}
              />
            )}
            {props.globalInformations.flightType === "Various" && (
              <VariousSummary
                specificInformations={props.specificInformations}
                origin={props.origin}
              />
            )}
            {props.globalInformations.flightType === "Skydiving" && (
              <SkydivingSummary
                specificInformations={props.specificInformations}
                origin={props.origin}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5} style={{ textAlign: "center" }}>
        <Button
          variant="outlined"
          onClick={() => {
            props.handleBack();
          }}
        >
          {t("global.back")}
        </Button>
      </Grid>
      <Grid item xs={5} style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          onClick={() => {
            setWarningUpdateRequest(true);
          }}
        >
          {t("global.confirm")}
        </Button>
      </Grid>
      <WarningValidationUpdateRequest
        open={warningUpdateRequest}
        closeModal={closeWarningUpdateRequest}
        confirm={confirm}
        origin={props.origin}
        undo={undo}
      />
    </Grid>
  );
};

export default SummaryOfUpdatedRequest;
