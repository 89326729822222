import { Button, Grid, MenuItem, TextField } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  updateAdminInformations,
  updateUserInformations,
} from "../../../Service/ApiService";
import AuthenticationService from "../../../Service/AuthenticationService";
import { crna_type, flight_type } from "../../Dropdown";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

interface UserInformationsFormData {
  name: string;
  firstName: string;
  phoneNumber: string;
  companyName: string;
  favoriteFlightType: string;
  center: string;
}

const UserInformationsForm = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={
        {
          name: props.userInformations.name,
          firstName: props.userInformations.firstName,
          phoneNumber: props.userInformations.phoneNumber,
          companyName: props.userInformations.companyName,
          center: props.userInformations.center,
          favoriteFlightType: props.userInformations.favoriteFlightType,
        } as UserInformationsFormData
      }
      onSubmit={(values: UserInformationsFormData, actions) => {
        if (props.userInformations.companyName) {
          updateUserInformations(
            values.name,
            values.firstName,
            values.phoneNumber,
            values.companyName,
            values.favoriteFlightType,
            AuthenticationService.token,
            AuthenticationService.email
          )
            .then(() => {
              props.finishUpdate();
            })
            .catch((error) => {
              enqueueSnackbar(
                t("error.errorHasOccured", {
                  variant: "error",
                })
              );
              console.error(error);
            });
        } else {
          updateAdminInformations(
            values.name,
            values.firstName,
            values.phoneNumber,
            values.center,
            AuthenticationService.token,
            AuthenticationService.email
          )
            .then(() => {
              props.finishUpdate();
            })
            .catch((error) => {
              enqueueSnackbar(
                t("error.errorHasOccured", {
                  variant: "error",
                })
              );
              console.error(error);
            });
        }
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string(),
        firstName: Yup.string(),
        phoneNumber: Yup.string(),
        company: Yup.string(),
        favoriteFlightType: Yup.string(),
      })}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => {
        return (
          <Form noValidate>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Grid item xs={4} style={{ margin: "15px" }}>
                <TextField
                  label="Name"
                  value={values.name}
                  fullWidth
                  InputProps={{
                    readOnly: props.update ? false : true,
                  }}
                  name="name"
                  helperText={errors.name && touched.name ? errors.name : ""}
                  error={errors.name && touched.name ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={4} style={{ margin: "15px" }}>
                <TextField
                  id="outlined-required"
                  fullWidth
                  label="FirstName"
                  value={values.firstName}
                  InputProps={{
                    readOnly: props.update ? false : true,
                  }}
                  name="firstName"
                  helperText={
                    errors.firstName && touched.firstName
                      ? errors.firstName
                      : ""
                  }
                  error={errors.firstName && touched.firstName ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={4} style={{ margin: "15px" }}>
                <TextField
                  id="outlined-required"
                  fullWidth
                  label="Phone number"
                  value={values.phoneNumber}
                  InputProps={{
                    readOnly: props.update ? false : true,
                  }}
                  name="phoneNumber"
                  helperText={
                    errors.phoneNumber && touched.phoneNumber
                      ? errors.phoneNumber
                      : ""
                  }
                  error={
                    errors.phoneNumber && touched.phoneNumber ? true : false
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              {AuthenticationService.role !== "Administrator" && (
                <>
                  <Grid item xs={4} style={{ margin: "15px" }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      InputProps={{
                        readOnly: props.update ? false : true,
                      }}
                      id="favoriteFlightType"
                      label="Favorite flight type"
                      name="favoriteFlightType"
                      select
                      style={{ color: "white" }}
                      value={values.favoriteFlightType}
                      helperText={
                        errors.favoriteFlightType && touched.favoriteFlightType
                          ? errors.favoriteFlightType
                          : ""
                      }
                      error={
                        errors.favoriteFlightType && touched.favoriteFlightType
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        setFieldValue("favoriteFlightType", event.target.value);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem key={""} value={""}></MenuItem>
                      {flight_type.map(
                        (option: { value: string; label: string }) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: "center", margin: "10px" }}
                  >
                    <TextField
                      id="outlined-required"
                      label="Company Name"
                      value={values.companyName}
                      InputProps={{
                        readOnly: props.update ? false : true,
                      }}
                      disabled={
                        !props.update
                          ? false
                          : !AuthenticationService.isMainAccount
                          ? true
                          : false
                      }
                      name="companyName"
                      helperText={
                        errors.companyName && touched.companyName
                          ? errors.companyName
                          : ""
                      }
                      error={
                        errors.companyName && touched.companyName ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </>
              )}
              {AuthenticationService.role === "Administrator" && (
                <Grid item xs={4} style={{ margin: "15px" }}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    select
                    id="center"
                    label="Center"
                    InputProps={{
                      readOnly: props.update ? false : true,
                    }}
                    name="center"
                    style={{ color: "white" }}
                    value={values.center}
                    helperText={
                      errors.center && touched.center ? errors.center : ""
                    }
                    error={errors.center && touched.center ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem key={""} value={""}></MenuItem>
                    {crna_type.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              <Grid item xs={11}></Grid>
            </Grid>
            {props.update && (
              <Button
                type="submit"
                variant="outlined"
                style={{ marginTop: "15px" }}
              >
                {t("global.confirm")}
              </Button>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserInformationsForm;
