import * as React from "react";
import { useState } from "react";
import { listUnderAccount } from "../../../../Service/ApiService";
import AuthenticationService from "../../../../Service/AuthenticationService";
import { useSnackbar } from "notistack";
import { Button, Grid } from "@mui/material";
import UnderAccountDataGrid from "./SubAccountDataGrid";
import AddUnderAccount from "../../Modal/addSubAccount";
import { useTranslation } from "react-i18next";

const SubAccount = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [addUnderAccount, setAddUnderAccount] = useState(false);
  const [update, setUpdate] = useState(false);

  const closeAddUnderAccount = () => {
    setAddUnderAccount(false);
  };

  const listOfUnderAccount = async () => {
    return listUnderAccount(
      AuthenticationService.email,
      AuthenticationService.token,
      AuthenticationService.company
    )
      .then((newData) => {
        return newData;
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const successfullAdditionUser = () => {
    setUpdate(!update);
    setAddUnderAccount(false);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item xs={12} style={{ textAlign: "left" }}>
        <Button
          style={{
            minHeight: "30px",
            maxHeight: "30px",
            minWidth: "30px",
            maxWidth: "30px",
            margin: "10px",
          }}
          onClick={() => {
            setAddUnderAccount(true);
          }}
          variant="contained"
          color="primary"
        >
          +
        </Button>
        <UnderAccountDataGrid
          listOfRequest={listOfUnderAccount}
          update={update}
        />
      </Grid>
      <AddUnderAccount
        open={addUnderAccount}
        closeModal={closeAddUnderAccount}
        userInformations={props.userInformations}
        successfullAdditionUser={successfullAdditionUser}
      />
    </Grid>
  );
};

export default SubAccount;
