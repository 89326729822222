import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Component/Home";
import { PATHS } from "./Routes";
import Register from "../Component/Registration/Register";
import ConfirmEmail from "../Component/Registration/ConfirmEmail";
import ForgotPassword from "../Component/Registration/ForgotPassword";
import ChangePassword from "../Component/Registration/ChangePassword";
import CreateFirstPassword from "../Component/Registration/CreateFirstPassword";
import PrivateRoute from "./PrivateRoute";
import UserHome from "../Component/User/Page/Home";
import AdminHome from "../Component/Admin/Page/Home";
import PrivateAdminRoad from "./PrivateAdminRoad";
const Content: React.FC<{}> = () => {
  return (
    <Routes>
      <Route path={PATHS.Register} element={<Register />} />
      <Route path={PATHS.ConfirmEmail} element={<ConfirmEmail />} />
      <Route path={PATHS.ForgotPassword} element={<ForgotPassword />} />
      <Route path={PATHS.ChangePassword} element={<ChangePassword />} />
      <Route
        path={PATHS.CreateFirstPassword}
        element={<CreateFirstPassword />}
      />
      <Route element={<PrivateRoute />}>
        <Route path={PATHS.UserHome} element={<UserHome />} />
      </Route>
      <Route element={<PrivateAdminRoad />}>
        <Route path={PATHS.AdminHome} element={<AdminHome />} />
      </Route>
      <Route path={PATHS.Home} element={<Home />} />
      {/* <PrivateRoute exact path={PATHS.Home} component={App} /> */}
      {/* <Route path="*">
        <Redirect to="/" />
      </Route> */}
    </Routes>
  );
};

export default Content;
