import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import AuthenticationService from "../../../Service/AuthenticationService";
import Message from "../Chat/Message";
import TchatForm from "../Chat/TchatForm";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const Tchat = (props: any) => {
  const { t } = useTranslation();

  return (
    <Dialog open={props.open} onClose={props.closeModal} maxWidth="lg">
      <DialogTitle>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5" style={{ marginRight: "15px" }}>
            {t("request.chat") + " " + props.requestData.target}
          </Typography>
          <Grid item style={{ position: "absolute", right: "12px" }}>
            <IconButton
              onClick={() => {
                props.closeModal();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        style={{
          height: "50vh",
          width: "30vw",
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          style={{
            width: "100%",
          }}
        >
          {props.messages.map((message: any) => {
            return (
              <>
                {((AuthenticationService.role === "Administrator" &&
                  message.to !== "user") ||
                  (AuthenticationService.role !== "Administrator" &&
                    message.to === "user")) && (
                  <>
                    <Grid item xs={8} style={{ wordWrap: "break-word" }}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                      >
                        <Grid item xs={11}>
                          <i>{message.sendingTime}</i>
                        </Grid>
                      </Grid>
                      <Message message={message} backgroundColor="#FFCB9A" />
                    </Grid>
                    <Grid item xs={3}></Grid>
                  </>
                )}
                {((AuthenticationService.role === "Administrator" &&
                  message.to === "user") ||
                  (AuthenticationService.role !== "Administrator" &&
                    message.to !== "user")) && (
                  <>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={8} style={{ wordWrap: "break-word" }}>
                      <Grid container>
                        <Grid item xs={12} style={{ textAlign: "right" }}>
                          <i>{message.sendingTime}</i>
                        </Grid>
                      </Grid>
                      <Message message={message} backgroundColor="#D2E8E3" />
                    </Grid>
                  </>
                )}
              </>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <Grid item xs={12} textAlign="center">
            <TchatForm
              center={props.requestData.center}
              requestId={props.requestData.requestId}
              updateTchat={props.updateTchat}
              project={props.requestData.project}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default Tchat;
