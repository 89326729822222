import { DateTime } from "luxon";

export default function getAllDatesBeetweenStartAndEnd(
  difference: number,
  start: DateTime
): any[] {
  const newScheduleDays = [];
  for (let index = 0; index < difference; index++) {
    newScheduleDays.push(start.toFormat("dd/MM"));
    start = start.plus({ days: 1 });
  }

  return newScheduleDays;
}
