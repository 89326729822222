import { listRequest } from "../../../../Service/ApiService";
import AuthenticationService from "../../../../Service/AuthenticationService";
import GlobalDataGrid from "../AllRequestDataGrid";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const RequestsDataGrid = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const listOfRequest = async () => {
    return listRequest(
      AuthenticationService.email,
      AuthenticationService.token,
      true,
      false
    )
      .then((newData) => {
        return newData;
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  return (
    <GlobalDataGrid
      listOfRequest={listOfRequest}
      createNewRequest={props.changeCurrentPage}
      origin="Request"
      originOfUpdate="update"
      updateNotification={props.updateNotification}
    />
  );
};

export default RequestsDataGrid;
