import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  listAllRequestOfCenter,
  updateFavoriteFilter,
} from "../../../../Service/ApiService";
import AuthenticationService from "../../../../Service/AuthenticationService";
import AdminDataGrid from "../AdminDataGrid";
import { useTranslation } from "react-i18next";

const FavoriteDataGrid = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const listOfRequest = async () => {
    return listAllRequestOfCenter(
      AuthenticationService.company,
      AuthenticationService.email,
      AuthenticationService.token
    )
      .then((newData: any) => {
        return newData;
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const [favorite, setFavorite] = useState(AuthenticationService.favorite);
  const [origin, setOrigin] = useState("");

  const selectRequestData = (newRequestData: any) => {
    setOrigin(
      newRequestData.state === "processing" ? "processing" : "stateDefined"
    );
  };

  const updateOrigin = (newOrigin: string) => {
    setOrigin(newOrigin);
  };

  const updateFavorite = (newItems: any) => {
    setFavorite(newItems);
    AuthenticationService.favorite = newItems;
    updateFavoriteFilter(
      AuthenticationService.token,
      AuthenticationService.email,
      JSON.stringify(newItems)
    )
      .then(() => {
        enqueueSnackbar(t("request.favoriteUpdate"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("error.errorHasOccured"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  return (
    <AdminDataGrid
      listOfRequest={listOfRequest}
      selectRequestData={selectRequestData}
      updateFavorite={updateFavorite}
      favorite={favorite}
      origin="Favorite"
      originSummmary={origin}
      updateOrigin={updateOrigin}
    />
  );
};

export default FavoriteDataGrid;
