import * as React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useEffect } from "react";
import { Grid, IconButton, Tooltip } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import { useTranslation } from "react-i18next";
import { Delete } from "@mui/icons-material";
import { deleteSubAccount } from "../../../../Service/ApiService";
import AuthenticationService from "../../../../Service/AuthenticationService";
import { useSnackbar } from "notistack";

function CustomToolbar() {
  return <GridToolbarContainer></GridToolbarContainer>;
}

const SubAccountDataGrid = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    props.listOfRequest().then((newData: any) => {
      setData(newData);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.update]);
  const [data, setData] = React.useState([] as any);
  const [loading, setLoading] = React.useState(true);

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "Email",
      width: 270,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "firstName",
      headerName: t("account.firstName") || "",
      width: 200,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: t("account.name") || "",
      width: 200,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "phoneNumber",
      headerName: t("account.phoneNumber") || "",
      type: "number",
      width: 180,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "action",
      headerName: "action",
      width: 180,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const deleteAccount = (email: string) => {
          deleteSubAccount(
            email,
            AuthenticationService.token,
            AuthenticationService.email
          )
            .then(() => {
              enqueueSnackbar(t("subAccount.successDelete"), {
                variant: "success",
              });
              setLoading(true);
              props.listOfRequest().then((newData: any) => {
                setData(newData);
                setLoading(false);
              });
            })
            .catch((error) => {
              console.error(error);
              enqueueSnackbar(t("error.errorHasOccured"), {
                variant: "error",
              });
            });
        };

        return (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Tooltip title={t("global.delete")}>
              <IconButton
                onClick={() => {
                  deleteAccount(params.id.toString());
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Grid>
        );
      },
    },
  ];

  return (
    <Box sx={{ height: "30vh", width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        loading={loading}
        rowsPerPageOptions={[10, 15, 20]}
        experimentalFeatures={{ newEditingApi: true }}
        onRowDoubleClick={(user) => {
          // alert("ICi");
        }}
        getRowId={(user) => user.email}
        hideFooter
        components={{
          Toolbar: CustomToolbar,
          NoRowsOverlay: () => (
            <GridOverlay style={{ zIndex: 99 }}>
              <Grid
                container
                height="100%"
                alignItems="center"
                alignContent="center"
                justifyContent="center"
                style={{ zIndex: 99, textAlign: "center" }}
              >
                <Grid item xs={12}>
                  <PublicIcon
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      minWidth: "100px",
                      minHeight: "100px",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {t("account.noUser")}
                </Grid>
                <Grid item xs={12}>
                  {t("subAccount.createNewSubAccount")}
                </Grid>
              </Grid>
            </GridOverlay>
          ),
          NoResultsOverlay: () => (
            <Grid
              container
              height="100%"
              alignItems="center"
              alignContent="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <CrisisAlertIcon
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                    minWidth: "100px",
                    minHeight: "100px",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {t("global.noSolution")}
              </Grid>
              <Grid item xs={12}>
                {t("global.tryAgain")}
              </Grid>
            </Grid>
          ),
        }}
      />
    </Box>
  );
};

export default SubAccountDataGrid;
