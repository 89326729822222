import { useCallback, useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import { GeoJSON, FeatureGroup, LayersControl } from "react-leaflet";
import { hashString } from "../../ShareFonction/map";

const LayerControle = (props: any) => {
  const [layers, setLayers] = useState([]);

  useEffect(() => {
    setLayers(props.layers);
  }, [props.layers]);

  const onEachFeature = useCallback((feature: any, layer: any) => {
    if (feature.properties.name) {
      layer.bindPopup(feature.properties.name, {
        autoClose: false,
      });
    }
  }, []);

  return (
    <>
      {layers.map((layer: any, index: number) => {
        return (
          <LayersControl.Overlay name={layer.name} key={index}>
            <FeatureGroup pathOptions={{ color: "purple" }}>
              <GeoJSON
                key={hashString(JSON.stringify(layer))}
                data={layer}
                onEachFeature={onEachFeature}
              />
            </FeatureGroup>
          </LayersControl.Overlay>
        );
      })}
    </>
  );
};

export default LayerControle;
